<template>
    <ModalBasicOverflow
        id="create-org-modal"
        title="Create Organization"
        :modal-open="modals.create_organization.is_open"
        @close-modal="closeModal()"
    >
        <!-- Modal content -->
        <div class="p-6">
            <div class="text-sm">
                <div class="mb-4">
                    <label class="mb-2 block text-sm font-medium" for="default">Organization Name</label>
                    <input
                        id="default"
                        v-model="values.name"
                        type="text"
                        class="form-input w-full"
                        placeholder="Enter the name of the organization"
                    />
                </div>
            </div>
            <div class="col-span-12 md:col-span-12">
                <label class="mb-2 block text-sm font-medium">Organization Type</label>
                <select v-model="values.type" class="form-select w-full">
                    <option
                        v-for="organizationType in organizationTypes"
                        :key="organizationType.value"
                        :value="organizationType.value"
                    >
                        {{ organizationType.label }}
                    </option>
                </select>
            </div>
        </div>

        <!-- Modal Footer -->
        <div class="border-t border-slate-200 px-5 py-4">
            <div class="flex justify-between">
                <div class="ml-auto flex flex-wrap space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click.stop="closeModal()"
                    >
                        Cancel
                    </button>
                    <button
                        :class="
                            !canCreateOrganization
                                ? 'btn-sm cursor-not-allowed bg-indigo-500 text-white opacity-50 '
                                : 'btn-sm bg-indigo-500 text-white hover:bg-indigo-600'
                        "
                        :disabled="!canCreateOrganization"
                        @click.stop="onFormSubmit()"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </ModalBasicOverflow>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import ModalBasicOverflow from '@/components/ModalBasicOverflow.vue'

export default {
    name: 'CreateOrgModal',

    components: {
        ModalBasicOverflow,
    },

    computed: {
        ...mapGetters(['modals', 'isSfStaff', 'selfOrganizations', 'getFromDictionary']),

        organizationTypes() {
            const types = [
                {
                    value: 'marketing-agency',
                },
                {
                    value: 'publisher',
                },
                {
                    value: 'talent-agency',
                },
                {
                    value: 'brand',
                },
                {
                    value: 'other',
                },
            ]

            _.forEach(types, (type) => {
                // eslint-disable-next-line no-param-reassign
                type.label = this.getFromDictionary(`organization.type.${type.value}`, 'label')
            })

            return _.orderBy(types, ['label'], ['asc'])
        },

        canCreateOrganization() {
            return this.isSfStaff && this.values.name !== '' && this.values.type !== ''
        },
    },

    created() {
        this.resetValues()
    },

    data() {
        return {
            state: {
                isSubmitting: false,
            },

            values: {},

            defaultValues: {
                name: '',
                type: 'publisher',
            },
        }
    },

    methods: {
        resetValues() {
            this.values = _.cloneDeep(this.defaultValues)
        },

        onFormSubmit() {
            const body = {
                organization: {
                    name: this.values.name,
                    type: this.values.type,
                },
            }

            this.$axios
                .post(`/organizations`, body)
                .then(({ data }) => {
                    this.$successHandler(`Successfully created organization ${this.values.name}`)
                    this.closeModal()

                    this.$store.commit('self:organizations', {
                        organizations: [...this.selfOrganizations, data.payload.organization],
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        closeModal() {
            this.$store.commit('modal:close', 'create_organization')
            this.resetValues()
        },
    },
}
</script>
