import { createApp } from 'vue'
import _ from 'lodash'

import moment from 'moment'
import numeral from 'numeral'
import voca from 'voca'
import pluralize from 'pluralize'
import humanizeDuration from 'humanize-duration'
import Notifications from '@kyvg/vue3-notification'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { VueQueryPlugin } from '@tanstack/vue-query'
import Tracker from '@openreplay/tracker'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/vue'
import { createIntercom } from '@mathieustan/vue-intercom'
import VueGtag from 'vue-gtag'
import FontAwesome from './plugins/fontawesome'
import FormKit from './plugins/formkit'
import store from './store'
import App from './App.vue'
import router from './router'

import posthogPlugin from './plugins/posthog'

import './css/style.scss'
import { axios } from './utils/axios'
import api from './utils/api/api'

const app = createApp(App)

app.use(VueGtag, {
    config: {
        id: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_KEY,
    },
})

app.use(posthogPlugin)

app.use(VueQueryPlugin, {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    },
})
app.use(Notifications)
app.use(FormKit)
app.use(FontAwesome)

// Page Metadatas
const metaManager = createMetaManager()
app.use(metaManager)
app.use(metaPlugin)



app.config.globalProperties.$logRocket = LogRocket

const tracker = new Tracker({
    projectKey: import.meta.env.VITE_APP_OPENREPLAY_KEY,
})

app.config.globalProperties.$openReplayTracker = tracker

app.config.globalProperties.$errorHandler = (error, options = {}) => {
    // If the request was a result of a route change, do nothing
    if (!error || error.is_cancelled) return

    if (api.isQuotaError(error)) {
        let text = 'Your organization has exceeded its quota for this period'

        if (error?.response?.data?.quoteType) {
            text = `Your organization has exceeded its quota for ${error?.response?.data?.quoteType} this period`
        }

        app.config.globalProperties.$notify({
            type: 'error',
            title: 'Quota exceeded',
            text,
            duration: 10000,
            speed: 1000,
        })
        return
    }

    const axiosResponseMessage = _.get(error, 'response.message')

    let message = `Something failed when talking to our servers. Please reach out to support.`
    let title = `Server Error`

    if (axiosResponseMessage) {
        message = axiosResponseMessage
    } else if (options && options.message) {
        message = options.message
    }

    if (options && options.title) {
        title = options.title
    }

    console.error(`Handled Error - ${message}`, error)

    app.config.globalProperties.$notify({
        type: 'error',
        title: `Error - ${title}`,
        text: message,
        duration: 10000,
        speed: 1000,
    })
}

app.config.globalProperties.$successHandler = (message) => {
    app.config.globalProperties.$notify({
        type: 'success',
        text: message,
        duration: 4000,
        speed: 1000,
    })
}

app.config.globalProperties.scrollToTop = () => {
    document.getElementById('dashboard-content-area')?.scrollTo(0, 0)
}

app.config.globalProperties.filterUrlQuery = (queryParameters, path) => {
    const strippedQueryParams = []

    // We want to reset any settings on the secondary tab's state
    const newQueryParams = _.reduce(
        queryParameters,
        (result, paramValue, paramKey) => {
            if (!paramKey?.startsWith(path)) {
                result[paramKey] = paramValue
            } else {
                strippedQueryParams.push([paramKey, paramValue])
            }

            return result
        },
        {},
    )

    const diff = _.size(queryParameters) - _.size(newQueryParams)

    console.log(`Stripped ${diff} query params`, strippedQueryParams)

    return newQueryParams
}

/*
 * Filters
 */
const shortEnglishHumanizer = humanizeDuration.humanizer({
    spacer: '',
    delimiter: ' ',
    language: 'shortEn',
    languages: {
        shortEn: {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms',
        },
    },
    largest: 2,
})

// Custom filters accessible throughout the application
app.config.globalProperties.$lodash = _
app.config.globalProperties.$moment = moment
app.config.globalProperties.$numeral = numeral
app.config.globalProperties.$voca = voca
app.config.globalProperties.$pluralize = pluralize

app.config.globalProperties.$filters = {
    lodash: _,
    moment,
    numeral,

    humanizeDurationShort(durationAsMilliseconds) {
        return shortEnglishHumanizer(durationAsMilliseconds)
    },

    humanizeDuration(durationAsMilliseconds, excludeSeconds) {
        if (excludeSeconds === true) {
            return humanizeDuration(durationAsMilliseconds, {
                units: ['y', 'mo', 'w', 'd', 'h', 'm'],
                maxDecimalPoints: 0,
            })
        }
        return humanizeDuration(durationAsMilliseconds)
    },

    formatWholeNumber(input) {
        // eslint-disable-next-line no-param-reassign
        input = Number(input)
        if (!input) return '0'
        if (input >= 1000) return numeral(input).format('0,0')
        if (input < 1 && input > 0) return numeral(input).format('0.0')
        return numeral(input).format('0')
    },

    formatWholeNumberWithUnit(input) {
        // eslint-disable-next-line no-param-reassign
        input = Number(input)
        if (!input) return '0'
        if (input >= 1000 && input % 1000 === 0) return numeral(input).format('0a')
        if (input >= 1000) return numeral(input).format('0.0a').replace('.0', '')
        if (input < 1 && input > 0) return numeral(input).format('0.0')
        return numeral(input).format('0')
    },

    formatDate(date) {
        return date ? moment(date).format('MMM Do, YYYY') : ''
    },

    formatDateAndTime(datetime) {
        return datetime ? moment(datetime).format('MMM Do, YYYY h:mm A') : ''
    },

    // 105% -> +5% || 95% -> -5%
    formatPercentToSigned(input) {
        if (!input || !(typeof input === 'number')) return '---'
        return numeral(input - 1).format('+0,0%')
    },

    formatCurrency(value) {
        return `$${numeral(value).format('0,0[.]00')}`
    },
}

app.config.globalProperties.$axios = axios
store.$axios = app.config.globalProperties.$axios
store.$errorHandler = app.config.globalProperties.$errorHandler

/*
 * Vue Libraries
 */
app.use(store)
const routerInstance = router(store)
app.use(routerInstance)

if (import.meta.env.MODE === 'production') {
    Sentry.init({
        app,
        dsn: 'https://890e3923fe1beabc8c5a12db3554057d@o365880.ingest.sentry.io/4506114326134784',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', /^https:\/\/crm-api.streamforge\.com/],
                routingInstrumentation: Sentry.vueRouterInstrumentation(routerInstance),
            }),
            new Sentry.Replay(),
        ],
        environment: import.meta.env.MODE,
        beforeSend(event) {
            return {
                ...event,
                tags: {
                    ...event.tags,
                    context: import.meta.env.VITE_APP_CONTEXT,
                    branch: import.meta.env.VITE_APP_BRANCH,
                    ...(import.meta.env.VITE_APP_REVIEW_ID ? { reviewId: import.meta.env.VITE_APP_REVIEW_ID } : {}),
                },
            }
        },
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

if (import.meta.env.MODE === 'production') {
    if (import.meta.env.VITE_APP_INTERCOM_APP_ID) {
        app.use(createIntercom({ appId: import.meta.env.VITE_APP_INTERCOM_APP_ID }))
    }
}

app.mount('#app')
