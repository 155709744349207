<template>
    <!-- Change from fixed grid to responsive layout that stacks on mobile -->
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-[1fr,285px]">
        <div class="flex-1">
            <!-- Make cards responsive - 1 column on mobile, 2 on larger screens -->
            <div class="mb-8 grid grid-cols-1 gap-4 xl:grid-cols-2">
                <div class="col-span-1 flex flex-col rounded-sm border border-slate-200 bg-white shadow-lg">
                    <div class="px-4 py-5 sm:px-5 sm:py-6">
                        <div class="flex items-center">
                            <!-- Avatar - smaller on mobile -->
                            <div class="mr-3 sm:mr-4">
                                <div class="flex h-12 w-12 rounded-full bg-slate-200 sm:h-16 sm:w-16">
                                    <font-awesome-icon
                                        :icon="['fad', 'dollar-sign']"
                                        class="m-auto h-6 w-6 shrink-0 fill-current sm:h-8 sm:w-8"
                                        :path-classes="['text-slate-400', 'text-slate-600']"
                                    />
                                </div>
                            </div>

                            <!-- User info -->
                            <div>
                                <div class="mb-0 text-sm sm:text-base">Campaign Budget</div>
                                <div class="text-2xl font-bold text-slate-800 sm:text-3xl">
                                    <span v-if="totalBudget && totalBudget > 0"
                                        >{{ $filters.numeral(totalBudget).format('0,0') }} USD</span
                                    >
                                    <span v-else>-</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-1 flex flex-col rounded-sm border border-slate-200 bg-white shadow-lg">
                    <div class="px-4 py-5 sm:px-5 sm:py-6">
                        <div class="flex items-center">
                            <!-- Avatar - smaller on mobile -->
                            <div class="mr-3 sm:mr-4">
                                <div class="flex h-12 w-12 rounded-full bg-slate-200 sm:h-16 sm:w-16">
                                    <font-awesome-icon
                                        :icon="['fad', 'calendar']"
                                        class="m-auto h-6 w-6 shrink-0 fill-current sm:h-8 sm:w-8"
                                        :path-classes="['text-slate-400', 'text-slate-600']"
                                    />
                                </div>
                            </div>

                            <!-- User info -->
                            <div>
                                <div class="mb-0 text-sm sm:text-base">Launch Date</div>
                                <div class="text-2xl font-bold text-slate-800 sm:text-3xl">
                                    <span v-if="dateRange && dateRange.started_at">
                                        {{ $filters.moment(dateRange.started_at).format('MMM DD, YYYY') }}
                                    </span>
                                    <span v-else>No Date Set</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="invalidRules.length > 0" class="col-span-full mb-4 rounded-sm xl:col-span-6">
                <div>
                    <div v-for="campaignResults in invalidRules" :key="campaignResults.id">
                        <h3 class="font-semibold">
                            {{ getFromDictionary(`campaign.type.${campaignResults.type}`, 'labelPlural') }} To Do's
                        </h3>
                        <div class="mt-3 space-y-2">
                            <div
                                v-for="ruleResult in campaignResults.results"
                                :key="ruleResult.rule.id"
                                class="rounded border px-3 py-2 text-sm"
                                :class="
                                    ruleResult.valid === true
                                        ? 'border-green-200 bg-green-100 text-green-600'
                                        : ruleResult.rule.mandatory === true
                                        ? 'border-rose-200 bg-rose-100 text-rose-600'
                                        : 'border-amber-200 bg-amber-100 text-amber-600'
                                "
                            >
                                <div class="flex">
                                    {{
                                        getFromDictionary(
                                            `campaign.validation.${ruleResult.rule.id}`,
                                            `${ruleResult.valid === true ? 'valid' : 'invalid'}`,
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-full rounded-sm xl:col-span-6">
                <header class="border-b border-slate-100 py-3 sm:py-4">
                    <h2 class="text-lg font-semibold text-slate-800">Recent Activity</h2>
                </header>

                <div class="space-y-4">
                    <SkeletonCampaignHome v-if="!state.isLoaded" />

                    <!-- Card content --><!-- &quot;Today&quot; group -->
                    <div v-for="({ date, items }, i) in feed" v-else :key="i">
                        <header class="rounded-sm bg-white px-3 py-2 text-xs font-semibold uppercase text-slate-400">
                            {{ formatDate(date) }}
                        </header>

                        <ul class="my-1">
                            <!-- Item -->
                            <li v-for="(item, j) in items" :key="j" class="flex px-2">
                                <div class="my-2 mr-3 flex h-9 w-9 shrink-0 rounded-full bg-indigo-500">
                                    <font-awesome-icon
                                        :icon="getFromDictionary(`feed.model.${item.model}.${item.type}`, 'icon')"
                                        class="m-auto h-4 w-4 fill-current text-indigo-50"
                                    />
                                </div>

                                <div class="flex grow items-center border-b border-slate-100 py-2 text-xs sm:text-sm">
                                    <div class="flex grow flex-col sm:flex-row sm:justify-between">
                                        <div
                                            v-if="item.model === 'participant' && item.type === 'created'"
                                        >
                                            <span v-if="item.is_grouped">
                                                <span class="font-medium">Streamforge</span> has added
                                                <span class="font-medium">{{ item.subItemsCount }} creators</span> to
                                                your campaign.
                                            </span>
                                            <span v-else>
                                                <span class="font-medium">{{ item.payload.platform_user_name }}</span>
                                                has been added to your campaign.
                                            </span>
                                        </div>

                                        <div
                                            v-if="item.model === 'participant' && item.type === 'approved'"
                                        >
                                            <span v-if="item.is_grouped">
                                                <span class="font-medium">{{ item.subItemsCount }} creators</span> have
                                                been marked as
                                                <span class="font-medium text-emerald-500">approved</span>.
                                            </span>
                                            <span v-else>
                                                <span class="font-medium">{{ item.payload.platform_user_name }}</span>
                                                has been marked as
                                                <span class="font-medium text-emerald-500">approved</span>.
                                            </span>
                                        </div>

                                        <div
                                            v-if="item.model === 'campaign' && item.type === 'created'"
                                        >
                                            <span>
                                                A new
                                                <span class="font-medium">{{
                                                    getFromDictionary(
                                                        `campaign.type.${item.payload.campaign_type}`,
                                                        'label',
                                                    )
                                                }}</span>
                                                module was added to the campaign by
                                                <span class="font-medium">Streamforge</span>.
                                            </span>
                                        </div>

                                        <div
                                            v-if="item.model === 'supercampaign' && item.type === 'created'"
                                        >
                                            <span> Campaign was created. </span>
                                        </div>

                                        <div
                                            v-if="item.is_grouped && item.subItemsCount > 1"
                                            class="z-0 mt-2 flex shrink-0 sm:ml-2 sm:mt-0 sm:self-end"
                                        >
                                            <div class="-ml-0.5 flex -space-x-3">
                                                <div
                                                    v-for="({ payload }, index) in item.subItems.slice(0, 4)"
                                                    :key="payload.platform_user_name"
                                                    :class="`z-${(4 - index) * 10}`"
                                                    @click="onCreatorClick(payload)"
                                                >
                                                    <Tooltip class="box-content">
                                                        <template #trigger>
                                                            <Avatar
                                                                class="bg-slate-100 bg-white font-bold text-slate-700 shadow-[0_0px_0px_1.5px_white]"
                                                                :src="payload.platform_user_logo"
                                                                :username="payload.platform_user_name"
                                                                :size="28"
                                                            />
                                                        </template>
                                                        <template #default>
                                                            <div class="whitespace-nowrap text-xs">
                                                                {{ payload.platform_user_name }}
                                                            </div>
                                                        </template>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-else-if="item.model === 'participant' && item.payload"
                                            class="mt-2 flex shrink-0 sm:ml-2 sm:mt-0 sm:self-end"
                                        >
                                            <div class="-ml-0.5 flex -space-x-3">
                                                <div @click="onCreatorClick(item.payload)">
                                                    <Tooltip class="box-content">
                                                        <template #trigger>
                                                            <Avatar
                                                                class="rounded-full border-2 border-white bg-slate-700 text-slate-50"
                                                                :src="item.payload.platform_user_logo"
                                                                :username="item.payload.platform_user_name"
                                                                :size="28"
                                                            />
                                                        </template>
                                                        <template #default>
                                                            <div class="whitespace-nowrap text-xs">
                                                                {{ item.payload.platform_user_name }}
                                                            </div>
                                                        </template>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div v-if="feed && feed.length === 0" class="">
                        <p>No items to display</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Campaign Status sidebar - full width on mobile, sidebar on large screens -->
        <div class="w-full">
            <div
                class="mb-4 lg:sticky lg:top-16 lg:h-[calc(100vh-64px)] lg:overflow-y-auto lg:overflow-x-hidden"
            >
                <div class="">
                    <!-- Blocks -->
                    <div class="space-y-4">
                        <!-- Block 1 -->
                        <div class="rounded border border-slate-200 bg-slate-50 p-4">
                            <div class="mb-4 text-xs font-semibold uppercase text-slate-400">Campaign Status</div>

                            <ul>
                                <!-- Event 0 -->
                                <li
                                    v-for="(stage, stageIndex) in statuses"
                                    :key="stage.name"
                                    class="relative pb-4 last-of-type:pb-0"
                                >
                                    <div class="pl-6">
                                        <div
                                            class="mb-0.5 text-xs font-medium uppercase"
                                            :class="
                                                stageIndex <= status.stageIndex ? 'text-emerald-500' : 'text-indigo-500'
                                            "
                                        >
                                            {{ getFromDictionary(`campaign.status.${stage.name}`, 'label') }}
                                        </div>

                                        <div class="mb-2 text-sm">
                                            <p class="font-medium text-slate-800">
                                                {{ getFromDictionary(`campaign.status.${stage.name}`, 'description') }}
                                            </p>
                                        </div>
                                    </div>

                                    <!-- Timeline element -->
                                    <div aria-hidden="true">
                                        <div
                                            :class="stageIndex < status.stageIndex ? 'bg-emerald-200' : 'bg-slate-200'"
                                            class="absolute -bottom-1 left-0.5 top-1 ml-px w-0.5 bg-slate-200"
                                        />
                                        <div
                                            :class="stageIndex <= status.stageIndex ? 'bg-emerald-400' : 'bg-slate-400'"
                                            class="ml- absolute left-0 top-1 h-2 w-2 rounded-full"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar.vue'
import Tooltip from '@/components/Tooltip.vue'
import SkeletonCampaignHome from '@/partials/skeletons/SkeletonCampaignHome.vue'
import campaignAPI from '../utils/api/api.campaign'

export default {
    name: 'SupercampaignHomeSubpage',

    components: {
        Avatar,
        Tooltip,
        SkeletonCampaignHome,
    },

    props: ['supercampaign', 'campaignsById'],

    data() {
        return {
            feed: null,

            statuses: [
                {
                    name: 'kickoff',
                    steps: ['unscheduled', 'scheduled', 'completed'],
                },
                {
                    name: 'discovery',
                    steps: ['targeting', 'creators'],
                },
                {
                    name: 'execution',
                    steps: ['collection'],
                },
                {
                    name: 'reporting',
                    steps: ['aggregation', 'delivery'],
                },
            ],

            state: {
                isLoaded: false,
            },

            validation: [],
        }
    },

    created() {
        this.getFeed()
        this.getValidation()
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        totalBudget() {
            return _.sumBy(_.values(this.campaignsById), 'budget_amount')
        },

        status() {
            const currentStatus = this.supercampaign.status
            if (!currentStatus) return false

            // "Stage" is the first word, step is the rest
            const statusParts = currentStatus.split('-')
            const stage = statusParts.shift()
            const step = statusParts.join('-')

            const stageMatchIndex = _.findIndex(this.statuses, (item) => item.name === stage)
            if (stageMatchIndex === -1) return false
            const stageMatch = this.statuses[stageMatchIndex]

            const stepMatchIndex = _.findIndex(stageMatch.steps, (item) => item === step)
            if (stepMatchIndex === -1) return false

            return {
                stageIndex: stageMatchIndex,
                stepIndex: stepMatchIndex,
            }
        },

        dateRange() {
            if (!this.supercampaign || !this.campaignsById || !_.size(this.campaignsById)) {
                return {
                    started_at: null,
                    ended_at: null,
                }
            }

            // Find the earliest, and latest start/end dates amongst all the campaigns within the supercampaign
            return _.reduce(
                _.values(this.campaignsById),
                (result, campaign) => {
                    if (!result.started_at || moment.utc(campaign.started_at).isBefore(moment.utc(result.started_at))) {
                        // eslint-disable-next-line no-param-reassign
                        result.started_at = campaign.started_at
                    }

                    if (!result.ended_at || moment.utc(campaign.ended_at).isAfter(moment.utc(result.ended_at))) {
                        // eslint-disable-next-line no-param-reassign
                        result.ended_at = campaign.ended_at
                    }

                    return result
                },
                {
                    started_at: null,
                    ended_at: null,
                },
            )
        },

        invalidRules() {
            if (!this.validation) {
                return false
            }

            const invalidResults = []

            _.forEach(this.validation, (campaign) => {
                const invalidCampaignResults = []
                _.forEach(
                    campaign.validation.mandatory.results.concat(campaign.validation.suggestions.results),
                    (result) => {
                        if (result.valid === false) {
                            invalidCampaignResults.push(result)
                        }
                    },
                )

                if (invalidCampaignResults.length > 0) {
                    invalidResults.push({
                        id: campaign.id,
                        type: campaign.type,
                        results: invalidCampaignResults,
                    })
                }
            })
            return invalidResults
        },
    },

    methods: {
        removeSpacesFromUsername(username) {
            return username.split(' ').join('')
        },

        onCreatorClick(payload) {
            this.$store.commit('modal:setData', {
                modal_id: 'creator',
                data: {
                    is_open: true,
                    platform_user_id: payload.platform_user_id,
                    platform_id: payload.platform_id,
                },
            })
        },

        getFeed() {
            this.$axios
                .get(`/supercampaigns/${this.$route.params.supercampaign_id}/feed`)
                .then(({ data }) => {
                    this.feed = _(data.payload.feed)
                        .groupBy((item) => moment(item.date).format('YYYY-MM-DD'))
                        .map((items, date) => {
                            const itemsByType = _.groupBy(items, (item) => `${item.model}-${item.type}`)

                            const newItems = []
                            const groupedTypes = ['participant-created', 'participant-approved']

                            _.forEach(itemsByType, (typeItems, type) => {
                                // For some feed items, we want to combine them together for a cleaner view
                                if (groupedTypes.includes(type) && _.size(typeItems) > 1) {
                                    const lastItem = _.last(typeItems)

                                    const subItems = _.map(typeItems, (typeItem) =>
                                        _.pick(typeItem, [
                                            'payload.platform_id',
                                            'payload.platform_user_id',
                                            'payload.platform_user_name',
                                            'payload.platform_user_logo',
                                        ]),
                                    )

                                    const newGroupedItem = _.assign(
                                        _.pick(lastItem, [
                                            'date',
                                            'model',
                                            'type',
                                            'payload.campaign_id',
                                            'payload.campaign_type',
                                        ]),
                                        {
                                            subItems,
                                            subItemsCount: _.size(subItems),
                                            is_grouped: true,
                                        },
                                    )

                                    newItems.push(newGroupedItem)

                                    // For other feed item types, just append as usual, no grouping necessary
                                } else {
                                    newItems.push(...typeItems)
                                }
                            })

                            this.state.isLoaded = true

                            return {
                                date,
                                items: _.orderBy(newItems, ['date'], ['desc']),
                            }
                        })
                        .orderBy(['date'], ['desc'])
                        .value()
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: `Error - Fetching Feed Items`,
                        message: `There was an issue fetching your campaign feed.`,
                    })
                })
        },

        formatDate(input) {
            const inputDate = moment(input)
            const startOfToday = moment().startOf('day')
            const startOfYesterday = moment().subtract(1, 'day').startOf('day')

            const isToday = inputDate.isAfter(startOfToday)
            if (isToday) return 'Today'

            const isYesterday = inputDate.isBetween(startOfYesterday, startOfToday)
            if (isYesterday) return 'Yesterday'

            return inputDate.format('MMM Do, YYYY')
        },

        async getValidation() {
            this.validation = []
            await Promise.all(
                _.map(this.campaignsById, (campaign) =>
                    campaignAPI.validateCampaign(this.$axios, this.supercampaign.id, campaign.id).then((response) => {
                        this.validation.push({
                            id: campaign.id,
                            type: campaign.type,
                            validation: response.value,
                        })
                    }),
                ),
            )
        },
    },
}
</script>