<template>
    <div v-if="activeOrganization && organizations && organizations.length" class="relative px-3">
        <div class="flex justify-between">
            <button
                ref="trigger"
                class="flex grow items-center truncate"
                aria-haspopup="true"
                :aria-expanded="dropdownOpen"
                @click.prevent="dropdownOpen = !dropdownOpen"
            >
                <div class="mr-2 flex h-8 w-8 rounded-full bg-slate-200" width="32" height="32">
                    <font-awesome-icon
                        fixed-width
                        :icon="['fad', 'building']"
                        class="m-auto h-4 w-4 shrink-0"
                        :path-classes="['text-slate-600', 'text-slate-400']"
                    />
                </div>

                <div class="truncate">
                    <span class="text-sm font-medium text-slate-300">{{ activeOrganization.name }}</span>
                </div>
                <svg class="ml-2 h-3 w-3 shrink-0 fill-current text-slate-400" viewBox="0 0 12 12">
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                </svg>
            </button>

            <router-link
                class="my-auto -mr-3 rounded p-3 text-slate-400 transition duration-200 hover:bg-white/5 hover:text-slate-200"
                :to="{
                    name: 'organization-users',
                    params: {
                        organization_id: activeOrganization.id,
                    },
                }"
                @click="dropdownOpen = false"
            >
                <font-awesome-icon :icon="['fas', 'cog']" class="h-4 w-4" />
            </router-link>
        </div>

        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-show="dropdownOpen" class="absolute left-0 top-0 -mt-2 w-full origin-top-right -translate-y-full">
                <div class="relative">
                    <div
                        class="absolute bottom-full left-0 z-10 max-h-[50vh] w-full origin-top-right overflow-y-scroll rounded-b-none rounded-t bg-white shadow-lg md:scrollbar md:scrollbar-thin md:scrollbar-track-transparent md:scrollbar-thumb-slate-200"
                    >
                        <ul ref="dropdown" @focusin="dropdownOpen = true" @focusout="dropdownOpen = false">
                            <li v-if="isSfStaff" class="z-100 sticky top-[-0px] bg-slate-200 pb-2 pl-2 pr-2 pt-2">
                                <div class="">
                                    <input
                                        v-model="searchText"
                                        class="form-input w-full"
                                        type="text"
                                        maxlength="100"
                                        placeholder="Search for organization"
                                    />
                                </div>
                            </li>
                            <template v-for="(organizationsOfType, type) in organizationsByType" :key="type">
                                <li class="[&:not(:first-child)]:mt-3">
                                    <div
                                        class="block flex justify-between bg-slate-200 px-3.5 py-2 text-sm font-medium text-slate-900"
                                    >
                                        <div class="flex grow items-center justify-between">
                                            <div class="flex grow items-center truncate">
                                                <div class="truncate capitalize">{{ type.replace('-', ' ') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li v-for="organization in organizationsOfType" :key="organization.id">
                                    <div
                                        class="block flex cursor-pointer justify-between px-3 py-2 text-sm font-medium text-slate-600 hover:bg-slate-100 hover:text-slate-800"
                                        :class="{
                                            'bg-slate-100':
                                                activeOrganization && activeOrganization.id === organization.id,
                                        }"
                                        @click="onOrganizationClick(organization)"
                                    >
                                        <div class="flex grow items-center justify-between">
                                            <div class="flex grow items-center truncate">
                                                <div
                                                    class="mr-2 flex h-8 w-8 rounded-full bg-slate-200"
                                                    width="32"
                                                    height="32"
                                                >
                                                    <font-awesome-icon
                                                        fixed-width
                                                        :icon="['fad', 'building']"
                                                        class="m-auto h-4 w-4 shrink-0"
                                                        :path-classes="['text-slate-600', 'text-slate-400']"
                                                    />
                                                </div>

                                                <div class="truncate">{{ organization.name }}</div>
                                            </div>
                                            <svg
                                                v-if="activeOrganization && activeOrganization.id === organization.id"
                                                class="mx-1 h-3 w-3 shrink-0 fill-current text-indigo-500"
                                                viewBox="0 0 12 12"
                                            >
                                                <path
                                                    d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>

                <div v-if="isSfStaff">
                    <button
                        class="btn w-full cursor-pointer rounded-b rounded-t-none bg-indigo-500 text-sm text-white hover:bg-indigo-600"
                        target="_blank"
                        @click.prevent="$store.commit('modal:open', 'create_organization')"
                    >
                        <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                            <path
                                d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                            />
                        </svg>
                        <span class="ml-2 hidden xs:block">Create Organization</span>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import { ref, onMounted, onUnmounted, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'OrganizationDropdown',

    setup() {
        const dropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const searchText = ref('')

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) {
                return
            }

            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        const onOrganizationClick = async (organization) => {
            dropdownOpen.value = false

            store.commit('self:activeOrganizationId', {
                id: organization.id,
            })

            nextTick(() => {
                if (route.name === 'no-access') {
                    router.push({
                        name: 'landing',
                    })
                } else {
                    router.push({
                        name: route.name,
                        query: route.query,
                    })
                }
            })
        }

        const onCreateOrganization = () => {
            dropdownOpen.value = false
            store.commit('modal:open', 'create_organization')
        }

        const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
        const sidebarExpanded = ref(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true')

        return {
            dropdownOpen,
            trigger,
            dropdown,
            onOrganizationClick,
            onCreateOrganization,
            organizations: computed(() => store.getters.selfOrganizations),
            organizationsByType: computed(() =>
                _(store.getters.selfOrganizations)
                    .filter((organization) => {
                        return (
                            !searchText.value ||
                            organization.name.toLowerCase().includes(searchText.value.toLowerCase())
                        )
                    })
                    .orderBy(['type'], ['asc'])
                    .groupBy('type')
                    .mapValues((results) => _.orderBy(results, ['name'], ['asc']))
                    .value(),
            ),
            activeOrganization: computed(() => store.getters.selfActiveOrganization),
            sidebarExpanded,
            isSfStaff: computed(() => store.getters.isSfStaff),
            modals: computed(() => store.getters.modals),
            searchText,
        }
    },
}
</script>
