<template>
    <div class="grid xl:grid-cols-2 2xl:grid-cols-3 gap-8 mx-3 mt-4">
        <div v-for="productPrice in tierProducts" :key="productPrice" class="rounded border">
            <div class="pl-3 pr-3 pt-3 text-sm font-bold">
                {{ getFromDictionary(`products.${productPrice.product.productType}`, 'label').toLocaleUpperCase() }}
            </div>
            <div class="border-b px-3 mt-1 pb-3">
                <div class="pb-3 text-sm">
                    {{ getFromDictionary(`products.${productPrice.product.productType}`, 'description') }}
                </div>
                <div v-if="productPrice.product.productType !== 'trial'">
                    <template v-if="productPrice.product.productType === 'enterprise'">
                       Starts at <span class="font-bold">$12,000</span>/year
                        <!-- Starts at <span class="font-bold">{{ $filters.formatCurrency(calculateMonthlyPrice(productPrice.price)) }}</span> -->
                    </template>
                    <template v-else>
                        <span class="font-bold">{{
                            $filters.formatCurrency(calculateMonthlyPrice(productPrice.price))
                        }}</span>/month
                    </template>
                </div>
            </div>

            <div v-if="productPrice.product.productType !== 'trial'" class="border-b p-3">
                <template
                    v-if="
                        !hasScheduledChanges &&
                        productPrice.price.selfManaged &&
                        /*(!activeSubscription.hasSubscription ||
                            activeSubscription.interval === productPrice?.price?.billingInterval ||
                            activeSubscription.interval === 'month') &&*/
                        productPrice?.price?.relatedPriceId &&
                        !activeSubscription.hasEndingSubscription
                    "
                >
                    <!-- <div class="pb-3 text-sm">
                        Additional seat(s)
                        <span class="font-bold">{{
                            $filters.formatCurrency(
                                calculateMonthlyPrice(getSeatPrice(productPrice.price.relatedPriceId)?.price),
                            )
                        }}</span
                        >/month
                        <div class="flex-col xl:flex-row flex pt-3">
                            <input
                                :id="productPrice.price.id"
                                v-model="relatedProductCounts[productPrice.price.relatedPriceId]"
                                class="flex h-8 w-24 rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                                type="number"
                                @input="onRelatedCountChanged(productPrice.price.relatedPriceId)"
                            />
                            <div v-if="relatedProductCounts[productPrice.price.relatedPriceId] > 0" class="ml-3 mt-1">
                                <span class="font-bold"
                                    >({{
                                        $filters.formatCurrency(
                                            calculateMonthlyPrice(
                                                getSeatPrice(productPrice.price.relatedPriceId)?.price,
                                            ) * relatedProductCounts[productPrice.price.relatedPriceId],
                                        )
                                    }}</span
                                >/month)
                            </div>
                        </div>
                    </div> -->

                    <template v-if="!activeSubscription.hasSubscription">
                        <div class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600">
                            <button @click="onPurchase(productPrice)">Subscribe</button>
                        </div>
                    </template>
                    <template
                        v-else-if="
                            activeSubscription.rank <=
                            getFromDictionary(`products.${productPrice.product.productType}`, 'rank')
                        "
                    >
                        <div class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600">
                            <button @click="onChangePlan(productPrice)">Upgrade</button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600">
                            <button @click="onChangePlan(productPrice)">Downgrade</button>
                        </div>
                    </template>
                </template>
                <template v-else-if="productPrice.product.productType !== 'trial'">
                    <div
                        class="cursor-pointer btn-sm border-slate-200 bg-white text-slate-500 hover:border-slate-300 hover:text-slate-600"
                    >
                        <button @click="onContactUs(productPrice)">Contact us</button>
                    </div>
                </template>
            </div>

            <div class="pl-3 pr-3 pt-3">
                <div class="pb-3 text-xs font-bold">WHAT'S INCLUDED</div>
                <div class="pb-4">
                    <ul class="space-y-2.5">
                        <li
                            v-for="includedItem in getIncludedItems(productPrice.product)"
                            :key="includedItem"
                            class="flex text-sm leading-none"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" class="my-auto h-3 w-3 text-emerald-500" />
                            <span class="pl-2">
                                {{ includedItem }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    name: 'PlanProductsTable',
    props: {
        productPrices: {
            type: Object,
        },
        isProductsLoading: {
            type: Boolean,
        },
        activeSubscription: {
            type: Object,
        },
        hasScheduledChanges: {
            type: Boolean,
        },
    },

    emits: ['purchase', 'switch-plan'],

    data() {
        return {
            relatedProductCounts: {},
        }
    },

    created() {
        this.resetPriceCounts()
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
        tierProducts() {
            return _.filter(this.productPrices, (productPrice) => {
                return ['trial', 'starter', 'growth', 'scale', 'enterprise'].includes(productPrice.product.productType)
            })
        },
    },

    watch: {
        productPrices() {
            this.resetPriceCounts()
        },
    },

    methods: {
        resetPriceCounts() {
            this.relatedProductCounts = {}
            _.forEach(this.productPrices, (productPrice) => {
                this.relatedProductCounts[productPrice.price.id] = 0
                if (productPrice.price.relatedPriceId) {
                    this.relatedProductCounts[productPrice.price.relatedPriceId] = 0
                }
            })
        },
        onPurchase(productPrice) {
            const additionalProducts = []
            if (this.relatedProductCounts[productPrice.price.relatedPriceId] > 0) {
                additionalProducts.push({
                    priceId: productPrice.price.relatedPriceId,
                    quantity: this.relatedProductCounts[productPrice.price.relatedPriceId],
                })
            }
            this.$emit('purchase', productPrice, additionalProducts)
        },
        onChangePlan(productPrice) {
            const additionalProducts = []
            if (this.relatedProductCounts[productPrice.price.relatedPriceId] > 0) {
                additionalProducts.push({
                    priceId: productPrice.price.relatedPriceId,
                    quantity: this.relatedProductCounts[productPrice.price.relatedPriceId],
                })
            }
            this.$emit('switch-plan', productPrice, additionalProducts)
        },
        calculateMonthlyPrice(productPrice) {
            if (!productPrice) {
                return 0
            }
            if (productPrice.billingInterval === 'year') {
                return Math.round(productPrice.amount / 12)
            }
            return productPrice.amount
        },
        getSeatPrice(id) {
            const seatPrice = _.find(this.productPrices, (productPrice) => {
                return productPrice.price.id === id
            })
            return seatPrice
        },
        getIncludedItems(product) {
            let includedItems = this.getFromDictionary(`products.${product.productType}`, 'included')
            includedItems = [...includedItems]

            const defaultSeats = this.getFromDictionary(`products.${product.productType}`, 'defaultSeats')

            if (product.productType === 'enterprise') {
                includedItems.unshift(`Custom seats`)
            } else if (defaultSeats > 1) {
                includedItems.unshift(`${defaultSeats} seats`)
            } else {
                includedItems.unshift(`1 seat`)
            }

            return includedItems
        },
        onRelatedCountChanged(relatedPriceId) {
            if (this.relatedProductCounts[relatedPriceId] < 0) {
                this.relatedProductCounts[relatedPriceId] = 0
            } else if (this.relatedProductCounts[relatedPriceId] > 999) {
                this.relatedProductCounts[relatedPriceId] = 999
            }
        },
        onContactUs() {
            this.$intercom.show()
        },
    },
}
</script>
