<template>
    <UIDialog v-if="modals.reveal_emails.is_open" :open="modals.reveal_emails.is_open" @update:open="closeModal">
        <DialogScrollContent :class="'max-w-xl border-none p-0'">
            <DialogHeader class="px-6 pt-8">
                <DialogTitle>Bulk Email Reveal</DialogTitle>
                <p class="text-sm text-muted-foreground">
                    {{ unrevealed }} emails available to reveal
                </p>
            </DialogHeader>

            <div v-if="loading" class="px-6 py-8 text-center">
                <font-awesome-icon :icon="['fas', 'spinner-third']" class="animate-spin w-6 h-6" />
            </div>
            <template v-else>
                <div class="border border-slate-200 bg-slate-100 rounded px-4 py-2 mx-6 text-sm">
                    <p>You will use <span class="font-semibold">{{ unrevealed }} credits</span> to reveal these emails.</p>
                    <p class="mt-1 text-muted-foreground">{{ excluded }} creators were excluded (emails already revealed or not found).</p>
                </div>

                <div class="px-6 py-6">
                    <button
                        class="w-full rounded-md bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90 flex justify-center disabled:opacity-50"
                        :disabled="isRevealingBulk || unrevealed === 0"
                        @click="revealEmails"
                    >
                        <font-awesome-icon v-if="isRevealingBulk" :icon="['fas', 'spinner-third']" class="animate-spin w-4 h-4 mr-2 my-auto" />
                        Reveal {{ unrevealed }} Emails
                    </button>
                </div>
            </template>
        </DialogScrollContent>
    </UIDialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { Dialog as UIDialog, DialogHeader, DialogTitle, DialogScrollContent } from '@/components/ui/dialog'

const store = useStore()
const loading = ref(true)
const unrevealed = ref(0)
const excluded = ref(0)
const creatorsToReveal = ref([])

const modals = computed(() => store.getters.modals)
const isRevealingBulk = computed(() => store.getters.isRevealingBulk)

watch(() => modals.value.reveal_emails.is_open, async (isOpen) => {
    if (isOpen && modals.value.reveal_emails.creators?.length) {
        loading.value = true
        
        try {
            const creators = modals.value.reveal_emails.creators
            const statusChecks = await Promise.all(
                creators.map(creator => 
                    store.dispatch('fetchEmailStatus', {
                        platformId: creator.platform_id,
                        platformUserId: creator.platform_user_id
                    })
                )
            )

            creatorsToReveal.value = creators.filter((creator, index) => {
                const status = statusChecks[index]
                return status?.is_found && !status?.is_previously_unlocked && status?.state !== 'revealed'
            })

            unrevealed.value = creatorsToReveal.value.length
            excluded.value = creators.length - creatorsToReveal.value.length
        } catch (error) {
            console.error('Error checking email statuses:', error)
        }
        
        loading.value = false
    }
}, { immediate: true })

const closeModal = () => {
    store.commit('modal:close', 'reveal_emails')
}

const revealEmails = async () => {
    try {
        await store.dispatch('bulkRevealEmails', creatorsToReveal.value)
        closeModal()
    } catch (error) {
        console.error('Error revealing emails:', error)
    }
}
</script>