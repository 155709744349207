<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Tooltip from '@/components/Tooltip.vue'

const props = defineProps({
    platformId: { type: String, required: true },
    platformUserId: { type: String, required: true },
})

const store = useStore()

const unlockState = computed(() => store.getters.unlocks.email[`${props.platformId}:${props.platformUserId}`])

const initialize = async () => {
    await store.dispatch('fetchEmailStatus', {
        platformId: props.platformId,
        platformUserId: props.platformUserId,
    })
}

initialize()

const hasPermission = computed(() => store.getters.checkPermission('feature.global.emails'))
const isLegacyBilling = computed(() => store.getters.isLegacyBilling)
const canClick = computed(() => unlockState.value?.is_revealable || unlockState.value?.email)

const tooltipVisible = computed(
    () =>
        !hasPermission.value ||
        (unlockState.value?.is_found && !unlockState.value?.email) ||
        (unlockState.value?.unlock && unlockState.value?.is_previously_unlocked),
)

const copyToClipboard = async (event, textToCopy) => {
    if (!unlockState.value?.email) return

    try {
        await navigator.clipboard.writeText(textToCopy)

        const overlayMessage = event.target

        if (overlayMessage) {
            overlayMessage.classList.add('opacity-100')

            setTimeout(() => {
                overlayMessage.classList.remove('opacity-100')
            }, 2500)
        } else {
            console.error('Overlay message element not found.')
        }
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
}

const handleClick = async () => {
    // if (unlockState.value?.email) return
    if (!unlockState.value?.is_revealable) return

    await store.dispatch('revealEmail', {
        platformId: props.platformId,
        platformUserId: props.platformUserId,
    })
}
</script>

<template>
    <div v-if="unlockState" class="flex">
        <Tooltip :placement="'top'" :show-tooltip="tooltipVisible">
            <template #trigger>
                <div
                    class="relative my-1 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5 text-xs transition duration-200"
                    :class="{ 'cursor-pointer hover:bg-slate-200 hover:text-slate-700': canClick }"
                    @click="handleClick"
                >
                    <div v-if="unlockState.loading" class="relative mr-0.5">
                        <font-awesome-icon
                            :icon="['fas', 'spinner-third']"
                            class="h-4 w-4 shrink-0 animate-spin !text-slate-500 text-white"
                        />
                    </div>
                    <div v-else class="relative mr-0.5">
                        <font-awesome-icon :icon="['far', 'envelope']" class="h-5 w-5 shrink-0" />
                        <div
                            v-if="unlockState.is_found && unlockState.email"
                            class="absolute -right-[2.5px] -top-[2.5px] flex rounded-full bg-emerald-400 p-0.5"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" class="h-2 w-2 shrink-0 text-white" />
                        </div>
                        <div
                            v-else-if="unlockState.is_found === false"
                            class="absolute -right-[2.5px] -top-[2.5px] flex rounded-full bg-rose-400 p-0.5"
                        >
                            <font-awesome-icon :icon="['fas', 'xmark']" class="h-2 w-2 shrink-0 text-white" />
                        </div>
                    </div>
                    <div
                        v-if="!hasPermission"
                        class="ml-2 h-3.5 w-28 rounded bg-slate-200 transition duration-200 hover:bg-slate-300"
                    ></div>
                    <span
                        v-else-if="unlockState.email"
                        class="ml-1.5 whitespace-nowrap font-medium lowercase"
                        @click.stop="copyToClipboard($event, unlockState?.email)"
                    >
                        <span
                            class="overlay-message absolute inset-0 flex items-center justify-center rounded bg-black bg-opacity-80 text-white opacity-0 transition-opacity duration-300"
                        >
                            <span class="pointer-events-none">Copied to clipboard!</span>
                        </span>
                        {{ unlockState?.email }}
                    </span>
                    <span
                        v-else-if="unlockState.is_found && unlockState.email !== ''"
                        class="ml-1.5 whitespace-nowrap font-medium"
                        >Access Email</span
                    >
                    <span v-else class="ml-1.5 whitespace-nowrap font-medium">No Email Found</span>
                </div>
            </template>
            <template v-if="!hasPermission" #default>
                <div class="whitespace-normal text-xs">
                    Feature Unavailable - Account upgrade required for access to creator emails.
                </div>
            </template>
            <template v-else-if="unlockState.is_found && !unlockState.email && unlockState.email !== ''" #default>
                <div class="whitespace-normal text-xs">
                    <template v-if="isLegacyBilling"> Click to access this email </template>
                    <template v-else> Use <span class="font-semibold">1 credit</span> to access this email. </template>
                </div>
            </template>
            <template v-else-if="unlockState.is_found === false || unlockState.email === ''" #default>
                <div class="whitespace-normal text-xs">
                    We weren't able to find an email for that creator. If you do, click "Edit Profile" on the creator's
                    profile and add it to your database for future reference.
                </div>
            </template>
            <template v-else-if="!isLegacyBilling && unlockState.unlock" #default>
                <div class="whitespace-normal text-xs">
                    {{
                        unlockState.unlock.operator.id === store.getters.selfOperator?.id
                            ? 'You'
                            : `Your teammate ${unlockState.unlock.operator.first_name} ${unlockState.unlock.operator.last_name}`
                    }}
                    unlocked this email on
                    {{ $moment(unlockState.unlock.created_at).format('LL') }}
                </div>
            </template>
            <template v-else #default>
                <div class="whitespace-normal text-xs">Click to copy email</div>
            </template>
        </Tooltip>
    </div>

    <div v-else class="flex">
        <div class="relative my-1 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5">
            <div class="relative mr-0.5">
                <font-awesome-icon :icon="['far', 'envelope']" class="h-5 w-5 shrink-0" />
            </div>
            <div class="ml-1.5 h-3.5 w-28 animate-pulse rounded bg-slate-200" />
        </div>
    </div>
</template>
