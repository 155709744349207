/**
 * A token with null, is a OAuth that is yet to be linked.
 * A token with false, is a OAuth token that has become invalid and required relink.
 */
export default {
    state: {
        tokens: {
            core: null,
        },
        is_authenticated: false,
        redirect: false,
    },

    /* eslint-disable no-param-reassign */
    mutations: {
        'authentication:login': function (state, token) {
            state.tokens.core = token
            state.is_authenticated = true
        },
        'authentication:token': function (state, tokens) {
            Object.keys(tokens).forEach((token) => {
                if (token) state.tokens[token] = tokens[token]
            })
        },
        'authentication:logout': function (state) {
            state.is_authenticated = false
            state.tokens.core = null
        },
        'authentication:clear': function (state, token) {
            state.tokens[token] = null
        },
        'authentication:redirect': function (state, path) {
            state.redirect = path
        },
    },
    /* eslint-enable no-param-reassign */

    getters: {
        tokens(state) {
            return state.tokens
        },
        getToken: (state) => (type) => {
            return state.tokens[type]
        },
        isAuthenticated(state) {
            return state.is_authenticated
        },
        redirect(state) {
            return state.redirect
        },
    },
}
