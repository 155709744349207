import _ from 'lodash'
import moment from 'moment'

export default {
    state: {
        operator: null,
        organizations: null,
        activeOrganizationId: null,

        isDevModeEnabled: false,
        isSfStaffDisabled: false,
    },

    /* eslint-disable no-param-reassign */
    mutations: {
        'self:operator': function (state, { operator }) {
            state.operator = operator
        },
        'self:organizations': function (state, { organizations }) {
            state.organizations = _.orderBy(organizations, ['name'], ['asc'])
        },
        'self:activeOrganizationId': function (state, { id }) {
            state.activeOrganizationId = id
        },
        'self:clear': function (state) {
            state.operator = null
            state.organizations = null
            state.activeOrganizationId = null
        },
        'self:toggleDevMode': function (state) {
            state.isDevModeEnabled = !state.isDevModeEnabled
        },
        'self:toggleStaffMode': function (state) {
            state.isSfStaffDisabled = !state.isSfStaffDisabled
        },
        /* eslint-enable no-param-reassign */
    },

    getters: {
        self(state) {
            return state
        },

        selfOperator(state) {
            return state.operator
        },

        selfOrganizations(state) {
            return state.organizations
        },

        selfActiveOrganizationId(state) {
            return state.activeOrganizationId
        },

        selfActiveOrganization(state) {
            return _.find(state.organizations, (item) => item.id === state.activeOrganizationId)
        },

        isLegacyBilling(state) {
            const activeOrg = _.find(state.organizations, (item) => item.id === state.activeOrganizationId)
            return activeOrg?.is_legacy_billing === true
        },

        isSfStaff(state) {
            return (
                state.isSfStaffDisabled === false &&
                !!_.find(state.organizations, (organization) => organization.type === 'admin')
            )

            // const activeOrganization = _.find(state.organizations, (item) => item.id === state.activeOrganizationId)
            // return activeOrganization && activeOrganization.type === 'admin'
        },

        isSfStaffDefinitive(state) {
            return !!_.find(state.organizations, (organization) => organization.type === 'admin')
        },

        isDevModeEnabled(state) {
            return state.isDevModeEnabled
        },

        isSfStaffDisabled(state) {
            return state.isSfStaffDisabled
        },
    },
}
