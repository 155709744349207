<template>
    <template v-if="!state.uploading">
        <div class="h-12">
            <UIInput class="px-2" type="file" multiple :accept="types" @change="onUploadFile($event)" />
        </div>
    </template>
    <template v-if="state.uploading">
        <div class="flex h-12 items-center font-bold">
            <p>Uploading...</p>
        </div>
    </template>

    <div class="mt-2 flex flex-col space-y-2">
        <div
            v-for="document in documents"
            :key="document"
            class="flex h-9 justify-between overflow-hidden rounded border border-slate-100 hover:border-slate-200"
        >
            <div class="flex px-3 py-1.5">
                <a :href="document" target="_blank" class="my-auto line-clamp-1 text-sm">{{
                    friendlyFileName(document)
                }}</a>
            </div>
            <div
                class="ml-2 flex h-full w-9 cursor-pointer bg-slate-100 hover:bg-rose-500 hover:text-white"
                @click="onDeleteDocument(document)"
            >
                <font-awesome-icon :icon="['fas', 'xmark']" class="m-auto h-3.5 w-3.5" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import documentAPI from '@/utils/api/api.documents'
import UIInput from './ui/input/Input.vue'

export default {
    name: 'DocumentUpload',
    components: {
        UIInput,
    },
    props: {
        organizationId: {
            type: String,
        },
        entity: {
            type: String,
        },
        entityId: {
            type: String,
        },
        documentType: {
            type: String,
        },
    },
    data() {
        return {
            documents: [],
            maxSize: 0,
            types: '',
            state: {
                uploading: false,
            },
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getDocuments()
    },
    methods: {
        friendlyFileName(filepath) {
            return filepath.split('/').pop()
        },
        async onUploadFile(evt) {
            this.state.uploading = true
            const target = evt.target
            if (target && target.files) {
                /* eslint-disable no-await-in-loop */
                for (let i = 0; i < target.files.length; i += 1) {
                    if (target.files[i].size < this.maxSize) {
                        await this.uploadDocument(target.files[i])
                    }
                }
                /* eslint-enable no-await-in-loop */
            }
            target.value = ''
            this.state.uploading = false
        },
        async onDeleteDocument(document) {
            this.documents = _.filter(this.documents, (doc) => doc !== document)
            await documentAPI.deleteDocument(
                this.$axios,
                this.organizationId,
                this.entity,
                this.entityId,
                this.documentType,
                document.split('/').pop(),
            )
        },
        async getDocuments() {
            const resp = await documentAPI.getDocuments(
                this.$axios,
                this.organizationId,
                this.entity,
                this.entityId,
                this.documentType,
            )
            this.documents = resp.value.documents
            this.maxSize = resp.value.limits.size
            this.types = resp.value.limits.types
        },
        async uploadDocument(file) {
            await documentAPI.uploadDocument(
                this.$axios,
                this.organizationId,
                this.entity,
                this.entityId,
                this.documentType,
                file,
                file.name,
            )
            await this.getDocuments()
        },
    },
}
</script>
