<template>
    <div class="flex h-screen w-full flex-col items-center justify-center">
        <div class="mb-4 flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8">
            <router-link :to="{ path: '/' }">
                <Logo variant="dark" class="w-44" :has-text="true" />
            </router-link>
        </div>
        <div class="max-w-2xl rounded-lg border border-solid border-slate-200 bg-white pb-12 pt-8">
            <div class="pl-16 pr-16">
                <h2 class="mb-4 text-xl font-bold">Edit Profile</h2>
                <form @submit.prevent="submitProfile">
                    <div class="mb-3">
                        <label class="mb-1 block text-sm font-medium" for="firstName">First name:</label>
                        <input
                            id="firstName"
                            v-model="profile.firstname"
                            type="text"
                            class="form-input w-full"
                            placeholder="Enter your first name"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="mb-1 block text-sm font-medium" for="lastName">Last name:</label>
                        <input
                            id="lastName"
                            v-model="profile.lastname"
                            type="text"
                            class="form-input w-full"
                            placeholder="Enter your last name"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="mb-1 block text-sm font-medium" for="email">Email address:</label>
                        <input
                            id="email"
                            v-model="profile.email"
                            type="email"
                            class="form-input w-full"
                            placeholder="Enter your email address"
                            @blur="checkEmail"
                        />
                        <p v-if="emailError" class="mt-1 text-xs text-red-500">{{ emailError }}</p>
                    </div>
                    <div class="mb-3">
                        <label class="mb-1 block text-sm font-medium" for="avatar">Avatar:</label>
                        <div class="flex items-center space-x-4">
                            <img
                                v-if="avatarPreview"
                                :src="avatarPreview"
                                alt="Avatar preview"
                                class="h-16 w-16 rounded-full object-cover"
                            />
                            <img
                                v-else
                                :src="profile.avatar"
                                alt="Current Avatar"
                                class="h-16 w-16 rounded-full object-cover"
                            />
                            <input
                                id="avatar"
                                type="file"
                                accept="image/*"
                                class="form-input"
                                @change="onAvatarChange"
                            />
                        </div>
                        <p v-if="avatarError" class="mt-1 text-xs text-red-500">{{ avatarError }}</p>
                    </div>
                    <div class="mt-6">
                        <button
                            type="submit"
                            class="btn w-full bg-indigo-500 text-white hover:bg-indigo-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            :disabled="isLoading || !isFormValid"
                        >
                            <svg
                                v-if="isLoading"
                                class="mr-2 h-4 w-4 shrink-0 animate-spin fill-current"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"
                                />
                            </svg>
                            <span>Save Changes</span>
                        </button>
                    </div>
                    <div
                        v-if="message"
                        class="mt-4 text-center text-sm"
                        :class="{ 'text-green-600': success, 'text-red-600': !success }"
                    >
                        {{ message }}
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logo.vue'
// import profileAPI from '@/utils/api/api.profile'
import onboardAPI from '@/utils/api/api.onboard'

export default {
    name: 'EditProfilePage',
    components: {
        Logo,
    },
    data() {
        return {
            profile: {
                firstname: '',
                lastname: '',
                email: '',
                avatar: '',
            },
            newAvatar: null,
            avatarPreview: '',
            emailError: '',
            avatarError: '',
            isLoading: false,
            message: '',
            success: false,
        }
    },
    computed: {
        ...mapGetters(['currentUser']),
        isFormValid() {
            return (
                this.profile.firstname.trim() !== '' &&
                this.profile.lastname.trim() !== '' &&
                this.validateEmailFormat(this.profile.email) &&
                !this.avatarError
            )
        },
    },
    created() {
        if (this.currentUser) {
            this.profile.firstname = this.currentUser.firstname || ''
            this.profile.lastname = this.currentUser.lastname || ''
            this.profile.email = this.currentUser.email || ''
            this.profile.avatar = this.currentUser.avatar || ''
        }
    },
    methods: {
        validateEmailFormat(email) {
            const re = /\S+@\S+\.\S+/
            return re.test(email)
        },
        async checkEmail() {
            if (!this.validateEmailFormat(this.profile.email)) {
                this.emailError = 'Please enter a valid email address'
                return
            }
            if (this.profile.email === this.currentUser.email) {
                this.emailError = ''
                return
            }
            this.isLoading = true
            try {
                const result = await onboardAPI.validateEmail(this.$axios, this.profile.email)
                if (!result || !result.success || !result?.value?.is_success) {
                    if (result?.value?.status === 'INTERNAL_ERROR') {
                        this.emailError = 'Please enter a valid email'
                    }
                    else {
                        this.emailError = 'Something went wrong validating the email'
                    }
                } 
                else {
                    this.emailError = ''
                }
            } catch (error) {
                this.emailError = 'Error validating email'
            } finally {
                this.isLoading = false
            }
        },
        onAvatarChange(event) {
            const file = event.target.files[0]
            if (file) {
                if (!file.type.startsWith('image/')) {
                    this.avatarError = 'Selected file must be an image'
                    return
                }
                if (file.size > 2 * 1024 * 1024) {
                    this.avatarError = 'Image must be smaller than 2MB'
                    return
                }
                this.avatarError = ''
                this.newAvatar = file
                this.avatarPreview = URL.createObjectURL(file)
            }
        },
        async submitProfile() {
            if (!this.isFormValid) {
                this.message = 'Please fix the errors before submitting'
                this.success = false
                return
            }
            this.isLoading = true
            this.message = ''
            const formData = new FormData()
            formData.append('firstname', this.profile.firstname)
            formData.append('lastname', this.profile.lastname)
            formData.append('email', this.profile.email)
            if (this.newAvatar) {
                formData.append('avatar', this.newAvatar)
            }

            try {
                // const response = await profileAPI.updateProfile(this.$axios, formData)
                // if (response && response.success) {
                //     this.message = 'Profile updated successfully'
                //     this.success = true
                //     this.$store.commit('setCurrentUser', response.data)
                // } else {
                //     this.message = 'Failed to update profile'
                //     this.success = false
                // }
            } catch (error) {
                this.message = 'An error occurred while updating profile'
                this.success = false
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>

<style scoped>
/* add any necessary styling here */
</style>
