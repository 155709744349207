<template>
    <div class="flex flex-col bg-white h-full w-full overflow-auto">
        <div class="flex w-full items-center justify-between px-4 py-6 sm:px-6 lg:px-8">
            <router-link :to="{ path: '/' }" class="mx-auto">
                <Logo variant="dark" class="!w-52" :has-text="true" />
            </router-link>
        </div>

        <div class="flex grow mx-auto mb-12">
            <div class="flex w-auto h-auto xl:py-24 xl:mx-auto">
                <div class="mx-auto w-full md:w-auto flex rounded-lg md:border border-solid border-slate-200 bg-white">

                    <div class="flex flex-col xl:flex-row">

                        <!-- Left Side -->
                        <div class="flex h-full w-full flex-col">
                            <div class="px-4 py-6 md:px-24 md:py-14">
                                <div class="flex h-full flex-col justify-start gap-8">
                                    <div class="relative flex flex-col gap-1">
                                        <div class="text-xs font-medium tabular-nums leading-4 tracking-tight text-gray-500">
                                            {{ stepIndex + 1 }}/{{ steps.length }}
                                        </div>
                                        <div class="text-[24px] font-semibold leading-7 tracking-tight text-gray-900">
                                            {{ currentStep.header }}
                                        </div>

                                        <div class="mt-4 flex gap-2">
                                            <div
                                                v-for="(step, stepItemIndex) in steps"
                                                :key="step"
                                                class="h-1 grow rounded"
                                                :class="stepItemIndex <= stepIndex ? 'bg-slate-900' : 'bg-slate-100'"
                                            />
                                        </div>

                                        <button
                                            v-if="stepIndex > 0 && stepIndex !== (steps.length - 1)"
                                            class="absolute -left-[40px] top-[16px]"
                                            @click="stepIndex -= 1"
                                        >
                                            <div class="rounded p-2 transition duration-200 hover:bg-slate-100">
                                                <font-awesome-icon :icon="['fas', 'chevron-left']" class="h-3 w-3" />
                                            </div>
                                        </button>
                                    </div>

                                    <template v-if="currentStep.key === 'personal-details'">
                                        <form class="flex flex-col gap-6" autocomplete="off">
                                            <div class="animate-in-up flex items-center gap-6">
                                                <div
                                                    class="relative flex h-[64px] w-[64px] rounded-full"
                                                    :style="`background-color: ${stringToColor(
                                                        form_data.first_name + form_data.last_name,
                                                    )}`"
                                                >
                                                    <img
                                                        v-if="state.operator.avatar_url"
                                                        :key="`${state.operator.avatar_url}-${state.operator.avatar_updated_at}`"
                                                        :src="`${state.operator.avatar_url}?t=${state.operator.avatar_updated_at}`"
                                                        class="h-[64px] w-[64px] rounded-full object-cover"
                                                        style="overflow-clip-margin: unset"
                                                    />
                                                    <span v-if="!state.operator.avatar_url" class="m-auto text-3xl text-white">{{
                                                        $voca.upperCase($lodash.first(form_data.first_name))
                                                    }}</span>

                                                    <div
                                                        v-if="uploads['personal-details'].is_uploading"
                                                        class="absolute left-0 top-0 flex h-full w-full rounded-full bg-slate-100/75"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="['fas', 'spinner-third']"
                                                            class="m-auto h-6 w-6 animate-spin"
                                                        />
                                                    </div>
                                                </div>

                                                <div class="flex grow flex-col gap-3">
                                                    <div class="text-sm font-semibold leading-5 text-gray-900">Profile picture</div>
                                                    <div class="flex gap-3">
                                                        <input
                                                            ref="personalDetailsfileInput"
                                                            type="file"
                                                            class="hidden"
                                                            accept="image/png,image/jpeg"
                                                            @change="(event) => handleFileSelect(event, 'personal-details')"
                                                        />
                                                        <button
                                                            class="btn border-slate-200 hover:bg-slate-50"
                                                            :disabled="uploads['personal-details'].is_uploading"
                                                            @click.prevent="$refs.personalDetailsfileInput.click()"
                                                        >
                                                            <font-awesome-icon :icon="['fas', 'upload']" class="mr-2 h-3 w-3" />
                                                            {{ state.operator.avatar_url ? 'Replace image' : 'Upload image' }}
                                                        </button>
                                                        <button
                                                            class="btn border-slate-200 hover:bg-slate-50 disabled:opacity-50"
                                                            :disabled="!state.operator.avatar_url"
                                                            @click.prevent="removeAvatar('personal-details')"
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                    <div
                                                        class="text-[11px] font-medium tracking-tight"
                                                        :class="[
                                                            uploads['personal-details'].error ? 'text-red-500' : 'text-gray-500',
                                                        ]"
                                                    >
                                                        {{
                                                            uploads['personal-details'].error ||
                                                            '*.png, *.jpeg files up to 5MB, at least 64px by 64px'
                                                        }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="flex flex-col gap-5">
                                                <div class="animate-in-up animation-delay-200">
                                                    <label
                                                        class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                        for="first_name"
                                                        >First name</label
                                                    >
                                                    <input
                                                        id="first_name"
                                                        v-model="form_data.first_name"
                                                        class="form-input w-full shadow-none"
                                                        type="text"
                                                        placeholder="Enter your first name..."
                                                    />
                                                </div>
                                                <div class="animate-in-up animation-delay-400">
                                                    <label
                                                        class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                        for="last_name"
                                                        >Last name</label
                                                    >
                                                    <input
                                                        id="last_name"
                                                        v-model="form_data.last_name"
                                                        class="form-input w-full shadow-none"
                                                        type="text"
                                                        placeholder="Enter your last name..."
                                                    />
                                                </div>
                                                <div class="animate-in-up animation-delay-600 pointer-events-none">
                                                    <label
                                                        class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                        for="email"
                                                        >Email</label
                                                    >
                                                    <input
                                                        id="email"
                                                        v-model="form_data.email"
                                                        class="form-input w-full opacity-50 shadow-none"
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </form>

                                        <div class="animate-in-up animation-delay-800 flex flex-col gap-6">
                                            <hr />

                                            <div class="flex w-full flex-row items-center justify-start gap-1">
                                                <div class="flex grow flex-col gap-1">
                                                    <div class="text-xs font-medium leading-4 tracking-tight text-gray-900">
                                                        Subscribe to product updates
                                                    </div>
                                                    <div class="text-[11px] font-medium leading-4 tracking-tight text-gray-500">
                                                        Stay updated on new features and platform improvements.
                                                    </div>
                                                </div>

                                                <InputSwitch
                                                    :checked="form_data.is_product_updates_enabled"
                                                    @update:checked="form_data.is_product_updates_enabled = !form_data.is_product_updates_enabled"
                                                />
                                            </div>
                                        </div>
                                    </template>

                                    <template v-else-if="currentStep.key === 'organization-details'">
                                        <form class="flex flex-col gap-4" autocomplete="off">
                                            <div class="animate-in-up animation-delay-200">
                                                <div class="mb-1 flex">
                                                    <label
                                                        class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                        for="organization_name"
                                                        >Company Name</label
                                                    >
                                                </div>
                                                <div class="relative">
                                                    <input
                                                        id="organization_name"
                                                        v-model="form_data.organization_name"
                                                        class="form-input w-full shadow-none"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Enter your company name..."
                                                        :class="{
                                                            '!hover:border-rose-600 !border-rose-500':
                                                                !state.organization.is_name_valid &&
                                                                !state.organization.is_validation_loading &&
                                                                state.organization.is_form_touched,
                                                        }"
                                                        @input="validateOrganization"
                                                    />
                                                    <div class="absolute right-0 top-0 flex h-full px-3">
                                                        <font-awesome-icon
                                                            v-if="state.organization.is_validation_loading"
                                                            :icon="['fas', 'spinner-third']"
                                                            class="m-auto h-4 w-4 animate-spin"
                                                        />
                                                    </div>
                                                </div>

                                                <label
                                                    v-if="state.organization.invalid_name_reason"
                                                    class="mt-1 block text-xs leading-4 text-red-500"
                                                    for="organization_name"
                                                    >{{ state.organization.invalid_name_reason }}</label
                                                >
                                            </div>

                                            <div class="animate-in-up animation-delay-400">
                                                <label
                                                    class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                    for="organizationCountry"
                                                    >Billing Country</label
                                                >
                                                <select
                                                    id="organizationCountry"
                                                    v-model="form_data.organization_country"
                                                    name="organizationCountry"
                                                    class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-slate-200 focus:ring-indigo-300 sm:text-sm sm:leading-6"
                                                    :class="[
                                                        form_data.organization_country === '' ? 'text-slate-400' : 'text-slate-800',
                                                    ]"
                                                    placeholder="Select your billing location"
                                                >
                                                    <option class="text-slate-400" value="" disabled selected>
                                                        Select your billing location
                                                    </option>
                                                    <option
                                                        v-for="countryCode in countryCodes"
                                                        :key="countryCode"
                                                        class="text-slate-800"
                                                        :value="countryCode"
                                                    >
                                                        {{ new Intl.DisplayNames(undefined, { type: 'region' }).of(countryCode) }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="animate-in-up animation-delay-600">
                                                <label
                                                    class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                    for="organization_type"
                                                    >Which best describes your company?</label
                                                >
                                                <select
                                                    id="organizationType"
                                                    v-model="form_data.organization_type"
                                                    name="organizationType"
                                                    class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-slate-200 focus:ring-indigo-300 sm:text-sm sm:leading-6"
                                                    :class="[state.organizationType === '' ? 'text-slate-400' : 'text-slate-800']"
                                                >
                                                    <option class="text-slate-400" value="" disabled selected>Please select</option>
                                                    <option
                                                        v-for="type in options.organizationTypes"
                                                        :key="type.id"
                                                        class="text-slate-800"
                                                        :value="type.id"
                                                    >
                                                        {{ type.title }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="animate-in-up animation-delay-600">
                                                <label
                                                    class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                    for="organization_type"
                                                    >Which industry is your company in?</label
                                                >
                                                <select
                                                    id="organizationIndustry"
                                                    v-model="form_data.organization_industry"
                                                    name="organizationIndustry"
                                                    class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-slate-200 focus:ring-indigo-300 sm:text-sm sm:leading-6"
                                                    :class="[state.organizationType === '' ? 'text-slate-400' : 'text-slate-800']"
                                                >
                                                    <option class="text-slate-400" value="" disabled selected>Please select</option>
                                                    <option
                                                        v-for="industry in options.industries"
                                                        :key="industry.code"
                                                        class="text-slate-800"
                                                        :value="industry.code"
                                                    >
                                                        {{ industry.emoji }} {{ industry.name }}
                                                    </option>
                                                </select>
                                            </div>

                                            <!-- <div>
                                                <label
                                                    class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                    for="organization_platforms"
                                                    >Which platforms are you interested in?</label
                                                >
                                                <div class="flex flex-col text-sm">
                                                    <div v-for="platform in options.platforms" :key="platform.id" class="ml-2 flex">
                                                        <input
                                                            :id="platform.id"
                                                            :key="platform.id"
                                                            v-model="state.organizationPlatforms"
                                                            :value="platform"
                                                            name="product"
                                                            type="checkbox"
                                                            class="mt-1 rounded"
                                                        />
                                                        <label :for="platform.title" class="pl-2">{{ platform.title }}</label>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="animate-in-up animation-delay-800">
                                                <label
                                                    class="mb-1 block whitespace-pre-wrap break-words text-xs font-medium leading-4 text-gray-500"
                                                    for="attribution"
                                                    >How did you hear about us?</label
                                                >
                                                <textarea
                                                    v-model="form_data.organization_referral_source"
                                                    class="form-input w-full shadow-none"
                                                    placeholder="Share how you heard about Streamforge..."
                                                ></textarea>
                                            </div>
                                        </form>
                                    </template>

                                    <template v-else-if="currentStep.key === 'organization-preferences'">
                                        <form class="flex flex-col gap-8" autocomplete="off">
                                            <div class="animate-in-up animation-delay-200 space-y-4">
                                                <h3 class="text-base font-medium text-gray-900">
                                                    What will you be using Streamforge for?
                                                </h3>
                                                <div class="flex max-w-[380px] flex-wrap gap-2">
                                                    <div
                                                        v-for="option in options.usage"
                                                        :key="option"
                                                        :class="[
                                                            'cursor-pointer rounded-full px-4 py-2 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                                                            form_data.preferences_usage.includes(option)
                                                                ? 'bg-indigo-100 text-indigo-700'
                                                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200',
                                                        ]"
                                                        @click="toggleOption('preferences_usage', option)"
                                                    >
                                                        {{ option }}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Current Work Section -->
                                            <div class="animate-in-up animation-delay-400 space-y-4">
                                                <h3 class="text-base font-medium text-gray-900">
                                                    What are you working on at the moment?
                                                </h3>
                                                <div class="flex max-w-[380px] flex-wrap gap-2">
                                                    <div
                                                        v-for="option in options.work"
                                                        :key="option"
                                                        :class="[
                                                            'cursor-pointer rounded-full px-4 py-2 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                                                            form_data.preferences_work.includes(option)
                                                                ? 'bg-indigo-100 text-indigo-700'
                                                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200',
                                                        ]"
                                                        @click="toggleOption('preferences_work', option)"
                                                    >
                                                        {{ option }}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </template>

                                    <template v-else-if="currentStep.key === 'organization-teammates'">
                                        <div class="flex flex-col gap-4 text-sm">
                                            <p>Streamforge works best when your whole team uses it.</p>
                                            <p class="max-w-96">Built for seamless teamwork. Find influencers, analyze audiences, and track results like never before.</p>
                                        </div>

                                        <form class="flex flex-col gap-8" autocomplete="off">
                                            <div class="animate-in-up animation-delay-200 space-y-4">
                                                <label
                                                    class="mb-1 block whitespace-pre-wrap break-words text-sm font-medium leading-4 text-gray-500"
                                                    for="attribution"
                                                    >Invite up to 4 teammates to collaborate in Streamforge</label
                                                >
                                                <div class="flex flex-col gap-2">
                                                    <div v-for="(teammate, teammateIndex) in form_data.teammates" :key="teammateIndex" class="w-full relative">
                                                        <input
                                                            v-model="form_data.teammates[teammateIndex].email"
                                                            class="form-input w-full shadow-none"
                                                            :class="{
                                                                'border-emerald-300 hover:border-emerald-400 focus:border-emerald-400': form_data.teammates[teammateIndex].is_valid === true,
                                                                'border-rose-300 hover:border-rose-400 focus:border-rose-400': form_data.teammates[teammateIndex].is_valid === false,
                                                            }"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="example@email.com"
                                                            @input="(event) => onTeammateInput(event, teammateIndex)"
                                                        />

                                                        <div class="absolute top-[1px] right-[1px]">
                                                            <SelectRoot v-model="form_data.teammates[teammateIndex].role">
                                                                <SelectTrigger class="border-transparent h-[36px] font-medium">
                                                                    <SelectValue placeholder="Select role" class="pr-1">
                                                                        {{ options.roles[form_data.teammates[teammateIndex].role]?.title }}
                                                                    </SelectValue>
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectItem v-for="role in options.roles" :key="role.id" :value="role.id">
                                                                        <p class="font-semibold"> {{ role.title }}</p>
                                                                        <p class="max-w-48 text-xs">{{ role.description }}</p>
                                                                    </SelectItem>
                                                                </SelectContent>
                                                            </SelectRoot>
                                                        </div>

                                                        <!-- Add this error message display -->
                                                        <div
                                                            v-if="form_data.teammates[teammateIndex].is_valid === false && form_data.teammates[teammateIndex].error"
                                                            class="mt-0.5 text-[11px] text-rose-500"
                                                        >
                                                            {{ form_data.teammates[teammateIndex].error }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </template>

                                    <template v-else-if="currentStep.key === 'finish-line'">
                                        <div class="flex flex-col gap-4 text-sm">
                                            <p class="max-w-96">Everything is set up and you're ready to start discovering creators, managing campaigns, and growing your influencer marketing program.</p>

                                            <a
                                                class="btn bg-indigo-500 text-white shadow-none hover:bg-indigo-600 mt-3"
                                                href="/"
                                            >Let's get to work</a>
                                        </div>
                                    </template>

                                    <div v-if="currentStep.key !== 'finish-line'" :key="currentStep.key" class="animate-in-up animation-delay-1000 flex flex-col gap-2">
                                        <button
                                            class="btn w-full bg-indigo-500 text-white shadow-none hover:bg-indigo-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                                            :disabled="state.isNextStepLoading === true || formState[currentStep.key].is_continue_enabled === false"
                                            @click.prevent="goToNextStep"
                                        >
                                            <font-awesome-icon
                                                v-if="state.isNextStepLoading"
                                                :icon="['fas', 'spinner-third']"
                                                class="mr-2 h-3 w-3 animate-spin"
                                            />
                                            <span>{{ currentStep.continue_label }}</span>
                                        </button>

                                        <button
                                            v-if="stepIndex === 3"
                                            class="btn w-full text-slate-500 shadow-none hover:text-slate-600"
                                            @click.prevent="goToNextStep"
                                        >
                                            <font-awesome-icon
                                                v-if="state.isNextStepLoading"
                                                :icon="['fas', 'spinner-third']"
                                                class="mr-2 h-3 w-3 animate-spin"
                                            />
                                            <span>Skip for now</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Right Side -->
                        <div class="hidden xl:flex min-w-[564px] max-w-[564px] flex-col">
                            <div
                                class="position relative flex h-full w-full overflow-hidden border-l border-slate-200 bg-slate-100 p-24 px-16"
                            >
                                <div
                                    v-if="currentStep.key === 'organization-teammates'"
                                    class="animate-in-up relative my-auto aspect-square w-full max-w-xl rounded-lg p-8"
                                >
                                    <!-- Pulsing waves -->
                                    <div class="absolute inset-0 m-8">
                                        <div
                                            class="absolute inset-0 animate-ping rounded-full bg-white opacity-75"
                                            style="animation-duration: 5s"
                                        ></div>
                                        <div
                                            class="absolute inset-0 animate-ping rounded-full bg-white opacity-50"
                                            style="animation-duration: 5s; animation-delay: 500ms"
                                        ></div>
                                        <div
                                            class="absolute inset-0 animate-ping rounded-full bg-white opacity-25"
                                            style="animation-duration: 5s; animation-delay: 1000ms"
                                        ></div>
                                    </div>

                                    <!-- Background circle -->
                                    <div class="absolute inset-0 m-8 p-7">
                                        <div class="flex h-full w-full rounded-full bg-white shadow-sm">
                                            <div
                                                class="animate-in-up animation-delay-200 relative m-auto flex h-[84px] w-[84px] rounded-full"
                                                :style="`background-color: ${stringToColor(
                                                    form_data.first_name + form_data.last_name,
                                                )}`"
                                            >
                                                <img
                                                    v-if="state.operator.avatar_url"
                                                    :src="`${state.operator.avatar_url}?t=${state.operator.avatar_updated_at}`"
                                                    class="h-[84px] w-[84px] rounded-full object-cover"
                                                    style="overflow-clip-margin: unset"
                                                />
                                                <span v-if="!state.operator.avatar_url" class="m-auto text-3xl text-white">{{
                                                    $voca.upperCase($lodash.first(form_data.first_name))
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Avatar circles -->
                                    <div class="animate-in-up animation-delay-400 relative h-full w-full">
                                        <div
                                            v-for="(teammate, index) in $lodash.filter(form_data.teammates, (item) => item.email)"
                                            :key="teammate"
                                            :style="getPosition(index)"
                                            class="duration-400 absolute -translate-x-1/2 -translate-y-1/2 transform transition"
                                        >
                                            <div
                                                class="flex h-12 w-12 items-center justify-center rounded-full bg-slate-300 shadow-sm"
                                                :style="`background-color: ${stringToColor(teammate.email)}`"
                                            >
                                                <span class="m-auto text-3xl text-white">{{
                                                    $voca.upperCase($lodash.first(teammate.email))
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-else
                                    class="absolute h-[900px] w-[1440px] scale-100 overflow-hidden rounded-lg border border-slate-200 bg-white shadow-lg transition duration-500"
                                    :class="
                                        formState[currentStep.key].is_touched
                                            ? currentStep.diagram.touched.classes
                                            : currentStep.diagram.initial.classes
                                    "
                                >
                                    <div class="flex h-full bg-gray-50">
                                        <!-- Sidebar -->
                                        <div class="flex h-full w-64 flex-col border-r border-gray-200 bg-gray-50">
                                            <!-- Logo/Title -->
                                            <div class="mb-4 flex h-16 items-center space-x-3 border-b border-slate-200 p-4">
                                                <div
                                                    class="flex h-8 w-8 rounded-lg"
                                                    :style="`background-color: ${stringToColor(form_data.organization_name)}`"
                                                >
                                                    <span class="m-auto font-semibold text-white">{{
                                                        $voca.upperCase($lodash.first(form_data.organization_name))
                                                    }}</span>
                                                </div>
                                                <span class="font-semibold leading-none text-gray-900">{{
                                                    form_data.organization_name || 'Workspace title'
                                                }}</span>
                                                <font-awesome-icon :icon="['fas', 'chevron-down']" class="h-3 w-3 text-slate-400" />
                                            </div>

                                            <!-- Navigation -->
                                            <nav class="space-y-1 px-4">
                                                <template v-for="(item, index) in navigationItems" :key="index">
                                                    <div class="flex w-full items-center space-x-2 py-2">
                                                        <div class="w-4">
                                                            <font-awesome-icon
                                                                fixed-width
                                                                :class="item.isActive ? 'text-slate-500' : 'text-slate-400'"
                                                                class="h-3 w-3 transition duration-200"
                                                                :icon="['fas', item.icon]"
                                                            />
                                                        </div>
                                                        <div
                                                            class="h-4 rounded transition duration-200"
                                                            :class="[
                                                                item.isActive ? 'bg-slate-400' : 'bg-slate-200',
                                                                `w-${item.width}`,
                                                            ]"
                                                        ></div>
                                                    </div>
                                                </template>
                                            </nav>

                                            <div class="mt-auto">
                                                <div class="flex space-x-3 border-t border-slate-200 px-4 py-4">
                                                    <div class="h-5 grow rounded bg-slate-200"></div>
                                                    <font-awesome-icon :icon="['fas', 'chevron-down']" class="my-auto h-3 w-3" />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Main content area -->
                                        <div class="flex-1">
                                            <div>
                                                <!-- Header area -->
                                                <div
                                                    class="flex h-16 items-center justify-between border-b border-gray-200 bg-white"
                                                >
                                                    <div class="flex space-x-5 p-6">
                                                        <div class="h-4 w-20 rounded bg-slate-200"></div>
                                                        <div class="h-4 w-32 rounded bg-slate-200"></div>
                                                    </div>

                                                    <div class="flex p-6">
                                                        <div
                                                            v-if="form_data.first_name.length + form_data.last_name.length > 0"
                                                            class="inline-flex"
                                                        >
                                                            <div
                                                                class="relative flex h-7 w-7 rounded-full"
                                                                :style="`background-color: ${stringToColor(
                                                                    form_data.first_name + form_data.last_name,
                                                                )}`"
                                                            >
                                                                <img
                                                                    v-if="state.operator.avatar_url"
                                                                    :src="`${state.operator.avatar_url}?t=${state.operator.avatar_updated_at}`"
                                                                    class="h-7 w-7 rounded-full object-cover"
                                                                    style="overflow-clip-margin: unset"
                                                                />
                                                                <span
                                                                    v-if="!state.operator.avatar_url"
                                                                    class="text-md m-auto text-white"
                                                                    >{{
                                                                        $voca.upperCase($lodash.first(form_data.first_name))
                                                                    }}</span
                                                                >

                                                                <div
                                                                    v-if="uploads['personal-details'].is_uploading"
                                                                    class="absolute left-0 top-0 flex h-full w-full rounded-full bg-slate-100/75"
                                                                >
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'spinner-third']"
                                                                        class="m-auto h-6 w-6 animate-spin"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="my-auto ml-1.5 flex space-x-1 text-sm leading-none">
                                                                <span>{{
                                                                    form_data.first_name || form_data.last_name
                                                                        ? form_data.first_name
                                                                        : 'Your'
                                                                }}</span>
                                                                <span>{{
                                                                    form_data.first_name || form_data.last_name
                                                                        ? form_data.last_name
                                                                        : 'Name'
                                                                }}</span>
                                                            </div>
                                                            <div class="ml-1.5 flex items-center">
                                                                <font-awesome-icon
                                                                    :icon="['fas', 'chevron-down']"
                                                                    class="h-3 w-3"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Content rows -->
                                                <div class="space-y-4 bg-white px-6 py-6">
                                                    <div class="flex flex-col divide-y divide-slate-200 border-t border-slate-200">
                                                        <div class="flex">
                                                            <div class="flex w-64 items-center space-x-1 px-2 py-2 pl-3">
                                                                <div class="w-6">
                                                                    <div class="h-4 w-4 rounded border border-slate-200"></div>
                                                                </div>

                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="!ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex w-48 items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex w-20 items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex w-12 items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex w-16 items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex w-10 items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex w-20 items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex w-16 items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex grow items-center border-l border-slate-200 px-2 py-2">
                                                                <div class="ml-auto h-4 w-32 rounded bg-slate-300"></div>

                                                                <div class="ml-3 flex items-center">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'chevron-down']"
                                                                        class="h-3 w-3"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-for="index in 25" :key="index" class="flex items-center">
                                                            <div class="flex w-64 items-center space-x-2 py-2 pl-3 pr-32">
                                                                <div class="w-6">
                                                                    <div class="h-4 w-4 rounded border border-slate-200"></div>
                                                                </div>

                                                                <div class="inline-flex h-5 rounded-full bg-slate-100">
                                                                    <div class="h-5 w-5 rounded-full bg-slate-300"></div>
                                                                    <div class="my-auto w-24 text-xs leading-none"></div>
                                                                </div>
                                                            </div>
                                                            <div class="w-48 border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-200"></div>
                                                            </div>
                                                            <div class="w-20 border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-200"></div>
                                                            </div>
                                                            <div class="w-12 border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-200"></div>
                                                            </div>
                                                            <div class="w-16 border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-200"></div>
                                                            </div>
                                                            <div class="w-10 border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-200"></div>
                                                            </div>
                                                            <div class="w-20 border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-200"></div>
                                                            </div>
                                                            <div class="w-16 border-l border-slate-200 px-2 py-2">
                                                                <div class="h-4 w-full rounded bg-slate-200"></div>
                                                            </div>
                                                            <div class="grow border-l border-slate-200 px-2 py-2">
                                                                <div class="ml-auto h-4 w-32 rounded bg-slate-200"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="
                                        formState[currentStep.key].is_touched
                                            ? currentStep.diagram.touched.has_gradient
                                            : currentStep.diagram.initial.has_gradient
                                    "
                                    class="absolute bottom-0 left-0 h-[300px] w-full bg-gradient-to-b from-gray-100/0 to-gray-100"
                                ></div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { countries } from '@streamforgegg/utils'
import onboardAPI from '@/utils/api/api.onboard'
import Logo from '@/components/Logo.vue'
import { Switch } from '@/components/ui/switch'

import codeAPI from '../utils/api/api.codes'

import {
    Select as SelectRoot,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

export default {
    name: 'OnboardPage',

    metaInfo: {
        title: 'Onboarding',
    },

    components: {
        Logo,
        InputSwitch: Switch,
        SelectRoot,
        SelectContent,
        SelectItem,
        SelectTrigger,
        SelectValue,
    },

    data() {
        return {
            type: 'partial-setup',

            uploads: {
                'personal-details': {
                    is_uploading: false,
                    preview: null,
                    error: null,
                },
                'organization-details': {
                    is_uploading: false,
                    preview: null,
                    error: null,
                },
            },

            state: {
                organization: {
                    is_name_valid: false,
                    invalid_name_reason: null,
                    is_validation_loading: false,
                    is_form_touched: false,
                },
                operator: {
                    avatar_url: null,
                    avatar_updated_at: moment().unix(),
                },

                isNextStepLoading: false,
            },

            form_data: {
                first_name: '',
                last_name: '',
                email: '',
                is_product_updates_enabled: false,

                organization_name: '',
                organization_type: null,
                organization_country: null,
                organization_referral_source: '',
                organization_industry: null,

                preferences_usage: [],
                preferences_work: [],

                teammates: [{ email: '', role: 'member', is_valid: null }],
            },

            options: {
                industries: null,

                usage: [
                    'Influencer discovery',
                    'Campaign management',
                    'Reporting',
                    'Talent management',
                    'Competitor analysis',
                ],
                work: [
                    'Finding new influencers',
                    'Managing campaigns',
                    'Managing influencer relationships',
                    'Content approval',
                    'Planning campaigns',
                    'Contract management',
                ],

                organizationTypes: [
                    { id: 'brand', title: 'Brand/Product' },
                    { id: 'marketing-agency', title: 'Marketing Agency' },
                    { id: 'publisher', title: 'Game Publisher/Developer' },
                    { id: 'talent-agency', title: 'Talent Agency' },
                    { id: 'other', title: 'Other' },
                ],

                platforms: [
                    { id: 'twitch', title: 'Twitch' },
                    { id: 'youtube', title: 'YouTube' },
                    { id: 'twitter', title: 'Twitter/X' },
                    { id: 'instagram', title: 'Instagram' },
                    { id: 'tiktok', title: 'TikTok' },
                    { id: 'other', title: 'Other' },
                ],

                roles: {
                    owner: {
                        id: 'owner',
                        title: 'Owner',
                        description: 'Have full control over your organization.',
                    },
                    manager: {
                        id: 'manager',
                        title: 'Manager',
                        description: 'Can invite, and remove users from your organization.',
                    },
                    member: {
                        id: 'member',
                        title: 'Member',
                        description: 'Will have access to all functionality of the platform.',
                    },
                },
            },

            steps: [
                {
                    key: 'personal-details',
                    header: `Let's get to know you`,
                    required_fields: ['first_name', 'last_name'],
                    diagram: {
                        initial: {
                            classes: ['scale-[0.6]', '!translate-x-[-20%]', '!translate-y-[-22%]'],
                            has_gradient: false,
                        },
                        touched: {
                            classes: ['scale-100', '!translate-x-[-75%]', '!translate-y-[15%]'],
                            has_gradient: true,
                        },
                    },
                    is_continue_enabled: () => {
                        return Boolean(this.form_data.first_name && this.form_data.last_name)
                    },
                    continue_label: 'Continue',
                },
                {
                    key: 'organization-details',
                    header: `Set up your organization`,
                    required_fields: ['organization_name', 'organization_type', 'organization_industry', 'organization_country'],
                    diagram: {
                        initial: {
                            classes: ['!translate-x-[0%]', '!translate-y-[0%]'],
                            has_gradient: true,
                        },
                        touched: {
                            classes: ['!translate-x-[0%]', '!translate-y-[0%]'],
                            has_gradient: true,
                        },
                    },
                    is_continue_enabled: () => {
                        return Boolean(
                            this.form_data.organization_name &&
                                this.state.organization.is_name_valid &&
                                !this.state.organization.is_validation_loading &&
                                this.form_data.organization_country &&
                                this.form_data.organization_type &&
                                this.form_data.organization_industry &&
                                this.form_data.organization_referral_source,
                        )
                    },
                    continue_label: 'Continue',
                },
                {
                    key: 'organization-preferences',
                    header: `Help us customize your experience`,
                    required_fields: [],
                    diagram: {
                        initial: {
                            classes: ['scale-[1.25]', '!translate-x-[15%]', '!translate-y-[10%]'],
                            has_gradient: true,
                        },
                        touched: {
                            classes: ['scale-[1.25]', '!translate-x-[15%]', '!translate-y-[10%]'],
                            has_gradient: true,
                        },
                    },
                    is_continue_enabled: () => {
                        return Boolean(true)
                    },
                    continue_label: 'Continue',
                },
                // {
                //     key: 'organization-teammates',
                //     header: `Collaborate with your team`,
                //     required_fields: [],
                //     diagram: {
                //         initial: {
                //             classes: ['!translate-x-[0%]', '!translate-y-[0%]'],
                //             has_gradient: false,
                //         },
                //         touched: {
                //             classes: ['!translate-x-[0%]', '!translate-y-[0%]'],
                //             has_gradient: false,
                //         },
                //     },
                //     is_continue_enabled: () => {
                //         const filledEntries = _.filter(this.form_data.teammates, ({ email }) => email !== '')
                //         return filledEntries.length > 0
                //             && _.every(filledEntries, ({ is_valid }) => is_valid === true)
                //     },
                //     continue_label: 'Invite Teammates',
                // },
                {
                    key: 'finish-line',
                    header: `You are all set up!`,
                    required_fields: [],
                    diagram: {
                        initial: {
                            classes: ['!translate-x-[0%]', '!translate-y-[0%]'],
                            has_gradient: false,
                        },
                        touched: {
                            classes: ['!translate-x-[0%]', '!translate-y-[0%]'],
                            has_gradient: false,
                        },
                    },
                    is_continue_enabled: () => {
                        return Boolean(true)
                    },
                },
            ],

            stepIndex: 0,
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated', 'selfOperator', 'selfOrganizations']),

        countryCodes() {
            return countries
                .map((country) => country.code)
                .sort()
        },

        formState() {
            return _.reduce(
                this.steps,
                (results, step) => {
                    const fieldStates = _.map(this.currentStep.required_fields, (requiredFieldKey) => {
                        const fieldValue = _.get(this.form_data, requiredFieldKey)

                        return {
                            key: requiredFieldKey,
                            is_touched: !['', null, undefined].includes(fieldValue),
                        }
                    })

                    const isFieldTouched = _.some(fieldStates, (item) => item.is_touched === true)

                    results[step.key] = {
                        is_touched: isFieldTouched,
                        fieldStates,
                        is_continue_enabled: step.is_continue_enabled(),
                    }

                    return results
                },
                {},
            )
        },

        currentStep() {
            return this.steps[this.stepIndex]
        },

        navigationItems() {
            return [
                {
                    icon: 'magnifying-glass',
                    isActive:
                        this.form_data.preferences_usage.includes('Influencer discovery') ||
                        this.form_data.preferences_work.includes('Finding new influencers'),
                    width: '24',
                },
                {
                    icon: 'sparkles',
                    isActive:
                        this.form_data.preferences_usage.includes('Campaign management') ||
                        this.form_data.preferences_work.includes('Managing campaigns'),
                    width: '28',
                },
                {
                    icon: 'list',
                    isActive: this.form_data.preferences_usage.includes('Reporting'),
                    width: '20',
                },
                {
                    icon: 'chart-line',
                    isActive: this.form_data.preferences_usage.includes('Competitor analysis'),
                    width: '24',
                },
                {
                    icon: 'envelope',
                    isActive: this.form_data.preferences_work.includes('Content approval'),
                    width: '24',
                },
                {
                    icon: 'plus',
                    isActive: this.form_data.preferences_work.includes('Planning campaigns'),
                    width: '24',
                },
                {
                    icon: 'pen-to-square',
                    isActive: this.form_data.preferences_work.includes('Contract management'),
                    width: '28',
                },
                {
                    icon: 'bullhorn',
                    isActive:
                        this.form_data.preferences_usage.includes('Talent management') ||
                        this.form_data.preferences_work.includes('Managing influencer relationships'),
                    width: '20',
                },
            ]
        },
    },

    watch: {
        // Sometimes this comes in really late
        selfOperator(newValue) {
            this.state.operator.avatar_url = newValue.avatar_url
            this.form_data.email = newValue.email
            this.form_data.first_name = newValue.first_name
            this.form_data.last_name = newValue.last_name
        },
    },

    async created() {
        if (this.selfOperator) {
            if (this.selfOrganizations?.length > 0) {
                this.$notify({
                    type: 'error',
                    title: `Error - Invalid State`,
                    text: `We're not sure how you got here, but you already have an organization. Redirecting to home.`,
                    duration: 10000,
                    speed: 1000,
                })

                this.$router.push({
                    name: 'landing'
                })

                return
            }

            this.state.operator.avatar_url = this.selfOperator.avatar_url
            this.form_data.email = this.selfOperator.email
            this.form_data.first_name = this.selfOperator.first_name
            this.form_data.last_name = this.selfOperator.last_name

            // If the user has already been added to an organization, then only do the first step.
            if (this.$route.query.type === 'admin') {
                this.type = 'full-setup'
            } else {
                this.type = 'partial-setup'
                this.steps = [_.first(this.steps), _.last(this.steps)]
            }
        }

        const codesResponse = await codeAPI.findCodes(
            this.$axios,
            'industry',
            '',
            'youtube',
            'platforms.youtube.metrics.video.channels',
        )

        this.options.industries = codesResponse.value
        this.options.industries.push({
            code: 'other',
            name: 'Other',
            emoji: '',
        })
    },

    methods: {
        async goToNextStep() {
            this.state.isNextStepLoading = true

            if (this.currentStep.key === 'personal-details') {
                await this.saveOperator()
            }

            const nextStep = this.steps[this.stepIndex + 1]

            if (nextStep.key === 'finish-line') {
                const isSuccess = await this.completeOnboarding()
                this.state.isNextStepLoading = false
                if (isSuccess === false) return
            }

            this.state.isNextStepLoading = false
            this.stepIndex += 1
        },

        getPosition(index) {
            const totalAvatars = _.filter(this.form_data.teammates, (item) => item.email).length

            // Calculate the angle for this avatar (in radians)
            const angle = (index * 2 * Math.PI) / totalAvatars - Math.PI / 3 // Start from top

            // Calculate radius (slightly smaller than container to account for avatar size)
            const radius = 42 // percentage of container width/height

            // Convert polar coordinates to Cartesian
            const x = 50 + radius * Math.cos(angle)
            const y = 50 + radius * Math.sin(angle)

            return {
                left: `${x}%`,
                top: `${y}%`,
            }
        },

        // Handle array manipulation immediately
        onTeammateInput(event, teammateIndex) {
            // Update the email value immediately
            const email = event.target.value
            this.form_data.teammates[teammateIndex].email = email

            // Add new row if needed (do this immediately, not in debounced function)
            if ((teammateIndex + 1) === this.form_data.teammates.length && this.form_data.teammates.length < 4) {
                if (email !== '') {
                    this.form_data.teammates.push({ email: '', role: 'member', is_valid: null })
                }
            }

            // Then call debounced validation
            this.debouncedValidateTeammate(teammateIndex)
        },

        // Separate debounced validation logic
        debouncedValidateTeammate: _.debounce(async function(teammateIndex) {
            const teammate = this.form_data.teammates[teammateIndex]
            if (!teammate) return

            // Reset error message first
            teammate.error = null

            if (teammate.email === '') {
                teammate.is_valid = null
                return
            }

            // Basic validation first
            const isEmailInputValid = this.validateEmailFormat(teammate.email)
            if (!isEmailInputValid) {
                teammate.is_valid = false
                teammate.error = "Please enter a valid email address"
                return
            }

            if (teammate.email.toLowerCase() === this.form_data.email.toLowerCase()) {
                teammate.is_valid = false
                teammate.error = "You cannot invite yourself"
                return
            }

            // Don't validate if email hasn't changed
            if (teammate.last_value === teammate.email) return

            // Show loading state
            teammate.is_loading = true
            teammate.is_valid = null
            teammate.error = null

            try {
                const result = await onboardAPI.validateEmail(this.$axios, teammate.email)
                teammate.is_valid = result.value.is_success
                if (!result.value.is_success) {
                    teammate.error = result.value.reason || "This email address is not valid"
                }
            } catch (error) {
                console.error('Email validation error:', error)
                teammate.is_valid = false
                teammate.error = "An error occurred while validating the email"
            } finally {
                teammate.is_loading = false
                teammate.last_value = teammate.email
            }
        }, 800),

        // Separate email format validation
        validateEmailFormat(email) {
            return true
            // if (!email) return false
            // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            // return email.length > 3 && emailRegex.test(email)
        },

        toggleOption(type, option) {
            const index = this.form_data[type].indexOf(option)

            if (index === -1) {
                this.form_data[type].push(option)
            } else {
                this.form_data[type].splice(index, 1)
            }
        },

        async handleFileSelect(event, type) {
            const file = event.target.files[0]
            if (!file) return

            // Reset state
            this.uploads[type].error = null

            // Validate file size (5MB)
            if (file.size > 5 * 1024 * 1024) {
                this.uploads[type].error = 'File must be less than 5MB'
                return
            }

            // Validate file type
            if (!['image/jpeg', 'image/png'].includes(file.type)) {
                this.uploads[type].error = 'Only JPG and PNG files are allowed'
                return
            }

            // Validate dimensions
            const img = new Image()
            const imgPromise = new Promise((resolve) => {
                img.onload = () =>
                    resolve({
                        width: img.width,
                        height: img.height,
                    })
            })

            img.src = URL.createObjectURL(file)
            const dimensions = await imgPromise

            if (dimensions.width < 64 || dimensions.height < 64) {
                this.uploads[type].error = 'Image must be at least 64x64 pixels'
                return
            }

            // Show preview
            this.uploads[type].preview = URL.createObjectURL(file)

            // Upload
            this.uploads[type].is_uploading = true

            try {
                const formData = new FormData()
                formData.append('avatar', file)

                const filledFormData = {}

                _.forEach(this.form_data, (value, key) => {
                    if (value) {
                        filledFormData[key] = value
                    }
                })

                formData.append('operator', JSON.stringify(filledFormData))

                const response = await this.$axios.put('/operators/self', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })

                this.state.operator.avatar_url = response.data.payload.operator.avatar_url
                this.state.operator.avatar_updated_at = moment().unix()
            } catch (error) {
                this.uploads[type].error = 'Upload failed. Please try again.'
                console.error('Upload error:', error)
            } finally {
                this.uploads[type].is_uploading = false
            }
        },

        async removeAvatar(type) {
            if (type === 'personal-details') {
                await this.$axios.put('/operators/self', {
                    operator: {
                        avatar_url: null,
                    },
                })

                this.state.operator.avatar_url = null
            }
        },

        stringToColor: (str) => {
            let hash = 0
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash)
            }
            const hue = 215 + (hash % 360)
            return `hsl(${hue}, 97%, 61%)`
        },

        debouncedValidateOrganization: _.debounce(async function () {
            this.state.organization.is_form_touched = true

            if (!this.form_data.organization_name) {
                this.state.organization.is_name_valid = false
                this.state.organization.invalid_name_reason = 'No organization name provided.'
                this.state.organization.is_validation_loading = false
                return
            }

            const result = await onboardAPI.validateOrganization(this.$axios, this.form_data.organization_name?.trim())

            this.state.organization.is_validation_loading = false

            if (result && result.success) {
                this.state.organization.is_name_valid = result.value.is_valid
                this.state.organization.invalid_name_reason = result.value.reason
            }
        }, 500),

        validateOrganization() {
            this.state.organization.is_validation_loading = true
            this.debouncedValidateOrganization()
        },

        saveOperator() {
            return this.$axios.put('/operators/self', {
                operator: _.pick(this.form_data, [
                    'first_name',
                    'last_name'
                ]),
            }).then(({ data }) => {
                return data
            }).catch((error) => {
                this.$notify({
                    type: 'error',
                    title: `Error - Saving Data`,
                    text: `We were unable to save your user info. Please contact support.`,
                    duration: 10000,
                    speed: 1000,
                })
            })
        },

        async completeOnboarding() {
            const formData = _.cloneDeep(this.form_data)
            formData.teammates = _.filter(formData.teammates, item => item.email && item.is_valid)

            return this.$axios.post(`/onboarding`, formData)
                .then(({ data }) => {
                    return data.is_success
                })
                .catch((error) => {
                    this.$notify({
                        type: 'error',
                        title: `Error - Creating Organization`,
                        text: `We were unable to create your organization. Please contact support.`,
                        duration: 10000,
                        speed: 1000,
                    })

                    return false
                })
        },
    },
}
</script>

<style scoped>
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-in-up {
    opacity: 0;
    transform: translateY(10px);
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

@keyframes custom-ping {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  90% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-ping {
  animation: custom-ping 4s cubic-bezier(0.1, 0, 0.1, 1) infinite;
}
</style>
