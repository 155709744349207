import api from './api'

const apisubscriptions = {
    async getOrganizationAvailable(axios) {
        return axios
            .get(`/subscriptions/available`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getOrganizationSubscriptions(axios) {
        return axios
            .get(`/subscriptions`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getManageUrl(axios, provider) {
        return axios
            .get(`/subscriptions/manage/${provider}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getPurchaseUrl(axios, priceId, additionalProducts) {
        return axios
            .post(`/subscriptions/purchase/${priceId}`, additionalProducts)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async changeSubscription(axios, subscriptionId, products) {
        return axios
            .post(`/subscriptions/${subscriptionId}`, products)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async cancelSubscriptionChanges(axios, subscriptionId) {
        return axios
            .delete(`/subscriptions/${subscriptionId}/schedule`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async syncSubscription(axios, provider, id) {
        const body = {
            provider,
            id,
            syncMethod: 'session',
        }
        return axios
            .post(`/subscriptions/webhooks/stripe`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apisubscriptions
