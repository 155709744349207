<template>
    <div class="flex flex-col bg-white h-full w-full overflow-auto">
        <div class="flex w-full items-center justify-between px-4 py-6 sm:px-6 lg:px-8">
            <router-link :to="{ path: '/' }" class="mx-auto">
                <Logo variant="dark" class="!w-52" :has-text="true" />
            </router-link>
        </div>

        <div class="flex grow mx-auto">
            <div class="flex w-auto h-auto lg:py-44 lg:mx-auto">
                <div class="mx-auto w-full md:w-auto flex rounded-lg md:border border-solid border-slate-200">


                    <div v-if="state.isPendingVerification">
                        <div class="z-0 absolute top-0 left-0 h-full w-full striped-bg" />
                        <div class="z-0 absolute top-0 left-0 h-full w-full bg-gradient-to-r from-sky-200 to-transparent opacity-25" />
                        <div class="z-0 absolute top-0 left-0 h-full w-full fade-overlay" />

                        <div class="z-10 flex items-center justify-center text-center px-16 pt-24 mb-20 relative">
                            <div class="text-center max-w-lg">
                                <!-- Icon container -->
                                <div class="mb-5 text-6xl">
                                    📧
                                </div>

                                <!-- Welcome text -->
                                <h1 class="text-2xl font-semibold mb-2">
                                    Check your email
                                </h1>

                                <!-- Subtitle -->
                                <p class="text-gray-600">
                                    We've sent a verification link to <span class="font-medium">{{ form_data.email || 'your email' }}</span>.<br />
                                    Click the link in the email to continue setting up your account.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-else class="flex flex-col lg:flex-row py-6">

                        <!-- Email Input Section -->
                        <div class="flex h-full w-full flex-col order-2 lg:order-1 mx-auto">
                            <div class="lg:px-20 flex px-6 lg:px-12 box-content lg:w-[392px] lg:my-auto">
                                <!-- Show email form if not yet submitted -->
                                <div class="my-auto w-full">
                                    <div class="mb-1">
                                        <label class="mb-1 block text-xs font-medium" for="email">Email Address</label>

                                        <input
                                            id="email"
                                            v-model="form_data.email"
                                            class="form-input w-full shadow-none"
                                            type="email"
                                            autoComplete="off"
                                            placeholder="Enter your work email address"
                                            @input="onEmailInput"
                                            @keyup.enter="sendEmailVerification"
                                        />

                                        <div class="text-xs mt-1">
                                            <template v-if="['INTERNAL_ERROR', 'EMAIL_INVALID'].includes(state.status)">
                                                {{ state.message }}
                                            </template>

                                            <template v-else-if="state.status === 'EMAIL_FREE'">
                                                {{ state.message }} Contact our team by clicking here.
                                            </template>

                                            <template v-if="state.status === 'EMAIL_TAKEN'">
                                                <div>{{ state.message }} Would you like to <router-link class="text-indigo-500 font-medium" :to="{ name: 'login' }">login</router-link>?</div>
                                            </template>
                                        </div>
                                    </div>

                                    <div class="mt-3 w-full">
                                        <button
                                            class="btn w-full bg-indigo-500 text-white hover:bg-indigo-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 shadow-none"
                                            title="Please enter a valid business email address"
                                            :disabled="state.isLoading || !isEmailInputValid"
                                            @click="sendEmailVerification"
                                        >
                                            <font-awesome-icon v-if="state.isLoading" :icon="['fas', 'spinner-third']" class="w-4 h-4 mr-2 animate-spin" />
                                            <span>Continue</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col w-full order-1 lg:order-2 mx-auto mb-8 lg:mb-0">
                            <div class="flex justify-center box-content lg:w-[440px] flex-col items-start justify-start gap-4 lg:px-20 my-auto px-6 lg:px-12">
                                <div class="font-inter text-[24px] font-semibold leading-7 tracking-tight text-gray-900">
                                    Welcome to Streamforge.
                                </div>
                                <p class="font-inter text-sm font-medium leading-5 tracking-tight text-gray-500">
                                    Instantly discover the influencers your customers trust. Sign up in seconds to unlock audience insights and build partnerships that drive real impact.<br /><br />You'll be able to customize your workspace <i>exactly</i> how your team needs it.<br /><br />Let's get started.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import onboardAPI from '@/utils/api/api.onboard'
import Logo from '@/components/Logo.vue'

export default {
    name: 'RegisterPage',

    metaInfo: {
        title: 'Email Verification',
    },

    components: {
        Logo,
    },

    data() {
        return {
            form_data: {
                email: '',
            },

            state: {
                isLoading: false,
                isPendingVerification: false,
                status: null,
                message: null,
                lastCheckedEmail: '',
            },
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated']),

        isEmailInputValid() {
            return Boolean(
                this.form_data.email &&
                this.form_data.email.length > 3 &&
                this.form_data.email.includes('@') &&
                this.form_data.email.includes('.')
            )
        },
    },

    created() {
        this.$store.commit('authentication:logout')
        this.$store.commit('self:clear')
    },

    methods: {
        onEmailInput() {
            if (this.form_data.email.toLowerCase() === this.state.lastCheckedEmail.toLowerCase()) return

            this.state.status = null
            this.state.message = null
        },

        async sendEmailVerification() {
            if (this.form_data.email.toLowerCase() === this.state.lastCheckedEmail.toLowerCase()) return

            this.state.status = null
            this.state.message = null
            this.state.isLoading = true
            this.state.lastCheckedEmail = this.form_data.email

            try {
                const { value: data } = await onboardAPI.sendEmailVerification(this.$axios, this.form_data.email)

                if (data.is_success) {
                    this.state.isPendingVerification = true
                } else {
                    this.state.status = data.status
                    this.state.message = data.message
                }
            } catch (error) {
                console.error(`Error with sending verification email`, error)
            }

            setTimeout(() => {
                this.state.lastCheckedEmail = ''
            }, 5000)

            this.state.isLoading = false
        },
    },
}
</script>

<style scoped>
.striped-bg {
    background-image: linear-gradient(
        -45deg,
        transparent 0%,
        transparent 46%,
        #EEF2FF 46%,
        #EEF2FF 56%,
        transparent 56%,
        transparent 100%
    );
    background-size: 20px 20px;
    background-color: white;
}

.fade-overlay {
    background-image: linear-gradient(
        to bottom,
        transparent 0%,
        white 50%
    );
}
</style>