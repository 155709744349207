<template>
    <div class="relative">
        <div :id="tooltipId" class="transition duration-200 hover:text-slate-500">
            <slot name="trigger">
                <svg class="h-4 w-4 fill-current text-slate-400" viewBox="0 0 16 16">
                    <path
                        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
                    />
                </svg>
            </slot>
        </div>
        <div :id="`${tooltipId}Content`" class="hidden">
            <div class="whitespace-wrap p-1.5 text-slate-200">
                <div>
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let tooltipCounter = 0

export default {
    props: {
        maxWidth: {
            type: String,
            required: false,
        },
        bg: {
            type: String,
            required: false,
        },
        placement: {
            type: String,
            required: false,
            default: () => 'bottom',
        },
        showTooltip: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        tooltipCounter += 1

        return {
            tooltipId: `tooltip-${tooltipCounter}`,
            tippyInstance: null,
        }
    },

    watch: {
        showTooltip(newValue) {
            if (this.tippyInstance?.[0]) {
                if (!newValue) {
                    this.tippyInstance[0].hide()
                }
                this.tippyInstance[0].setProps({
                    onShow: () => newValue,
                })
            }
        },
        placement(newValue) {
            if (this.tippyInstance?.[0]) {
                this.tippyInstance[0].setProps({
                    placement: newValue,
                })
            }
        },
        maxWidth(newValue) {
            if (this.tippyInstance?.[0]) {
                this.tippyInstance[0].setProps({
                    maxWidth: Number(newValue) || null,
                })
            }
        },
    },

    mounted() {
        this.initializeTippyInstance()
    },

    beforeUnmount() {
        this.destroyTippyInstance()
    },

    methods: {
        initializeTippyInstance() {
            const { tooltipId } = this
            // eslint-disable-next-line no-undef
            const instance = tippy(`#${tooltipId}`, {
                content: () => {
                    const template = document.getElementById(`${tooltipId}Content`)
                    return template.innerHTML
                },
                placement: this.placement,
                allowHTML: true,
                ...(this.maxWidth && { maxWidth: Number(this.maxWidth) }),
                theme: 'global',
                onShow: () => this.showTooltip,
            })
            this.tippyInstance = instance

            // Add MutationObserver to watch for content changes
            const observer = new MutationObserver(() => {
                if (this.tippyInstance?.[0]) {
                    this.tippyInstance[0].setContent(document.getElementById(`${tooltipId}Content`).innerHTML)
                }
            })

            try {
                observer.observe(document.getElementById(`${tooltipId}Content`), {
                    subtree: true,
                    characterData: true,
                    childList: true,
                })
            } catch (error) {
                console.error(`Failed to setup tooltip observer`, error)
            }
        },

        destroyTippyInstance() {
            if (this?.tippyInstance?.length > 0) {
                this.tippyInstance.forEach((tippy) => {
                    tippy.destroy()
                })
            }
            this.tippyInstance = null
        },
    },
}
</script>
