import _ from 'lodash'

const defaultStates = {
    create_campaign: {
        is_open: false,
    },
    send_messages: {
        is_open: false,
        options: {},
    },
    messages_view: {
        is_open: false,
        message: null,
        options: {},
    },
    inquiry_preview: {
        is_open: false,
        inquiry: null,
        preview: null,
        options: {},
    },
    create_campaign_module: {
        supercampaign_id: null,
        is_open: false,
        selected_campaign_type: null,
    },
    create_organization: {
        is_open: false,
    },
    confirm_pending_modal: {
        is_open: false,
    },
    participant: {
        is_open: false,
        creator: null,
        campaign: null,
    },
    creator: {
        is_open: false,
        platform_id: null,
        platform_user_id: null,
        supercampaign_id: null,
        campaign_id: null,
        participant_id: null,
        participant: null,
        campaign: null,
        is_limited_mode: false,
        is_in_workflow: false,
    },
    creator_ask_ai: {
        is_open: false,
        campaign_id: null,
        supercampaign_id: null,
        creator: null,
    },
    add_creator_to_modal: {
        is_open: false,
        superviews: null,
        platform_id: null,
    },
    export_table: {
        is_open: false,
        columns: {
            all: null,
            enabled: null,
        },
        rows: null,
        file_name: null,
        providerFn: null,
        checkerFn: null,
        rowCount: null,
    },
    reveal_emails: {
        is_open: false,
        creators: null,
    }
}

export default {
    state: _.cloneDeep(defaultStates),

    /* eslint-disable no-param-reassign */
    mutations: {
        'modal:open': function (state, modalKey) {
            if (!Object.hasOwn(state, modalKey)) {
                console.error(`Tried to open modal that does not exist`, modalKey)
                return
            }

            state[modalKey].is_open = true
        },

        'modal:close': function (state, modalKey) {
            if (!Object.hasOwn(state, modalKey)) {
                console.error(`Tried to open modal that does not exist`, modalKey)
                return
            }

            state[modalKey].is_open = false
            state[modalKey] = _.cloneDeep(defaultStates[modalKey])
        },

        'modal:toggleVisible': function (state, modalKey) {
            if (!Object.hasOwn(state, modalKey)) {
                console.error(`Tried to open modal that does not exist`, modalKey)
                return
            }

            state[modalKey].is_open = !state[modalKey].is_open
        },

        'modal:setData': function (state, { modal_id: modalId, data }) {
            if (!Object.hasOwn(state, modalId)) {
                console.error(`Tried to open modal that does not exist`, modalId)
                return
            }
            _.forEach(data, (value, fieldKey) => {
                if (Object.hasOwn(state[modalId], fieldKey)) {
                    state[modalId][fieldKey] = value
                }
            })
        },
    },
    /* eslint-enable no-param-reassign */
    getters: {
        getModal: (state) => (modalKey) => {
            if (!Object.hasOwn(state, modalKey)) {
                console.error(`Tried to open modal that does not exist`, modalKey)
                return undefined
            }

            return state[modalKey]
        },

        modals: (state) => state,
    },
}
