<script setup>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { h, ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import DataTable from '@/components/ui/data-table/DataTableServerSide.vue'
import DataTableSortableColumnHeader from '@/components/ui/data-table/DataTableSortableColumnHeader.vue'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { TableRow, TableCell } from '@/components/ui/table'
import { Badge } from '@/components/ui/badge'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { axios } from '@/utils/axios'
import { useMeta } from 'vue-meta'

// State for organizations data and table configuration
useMeta({
    title: `Admin`,
})
const store = useStore()
const organizations = ref([])
const isLoading = ref(true)
const pagination = ref({
    pageIndex: 0,
    pageSize: 25,
})
const sorting = ref([
    {
        id: 'created_at',
        desc: true,
    },
])
const filtering = ref([])
const textFilter = ref('')
const rowCount = ref(0)

const totalOrganizationsCount = ref(0)
const totalOperatorsCount = ref(0)

// Modal state
const isOrganizationModalOpen = ref(false)
const selectedOrganization = ref(null)
const activeTab = ref('overview')

// Filter fields for the toolbar
const filterFields = [
    { label: 'Organization', value: 'name' },
    { label: 'Type', value: 'type' },
    { label: 'Status', value: 'status' },
]

const getProgressColor = (percentage) => {
    if (percentage >= 90) return 'bg-red-500';
    if (percentage >= 70) return 'bg-amber-500';
    return 'bg-emerald-500';
};

// Column definitions for the data table
const columns = [
    {
        id: 'name',
        accessorKey: 'name',
        meta: {
            title: 'Organization',
        },
        header: ({ column }) =>
            h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: true,
        cell: ({ row }) => row.original.name,
    },
    {
        id: 'type',
        accessorKey: 'type',
        meta: {
            title: 'Type',
        },
        header: ({ column }) =>
            h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: true,
        cell: ({ row }) => h(Badge, {
            variant: getTypeBadgeVariant(row.original.type)
        }, {
            default: () => _.startCase(row.original.type)
        }),
    },
    {
        id: 'users_count',
        accessorKey: 'operators',
        meta: {
            title: 'Users',
        },
        header: ({ column }) =>
            h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: true,
        cell: ({ row }) => row.original.operators.length,
    },
    {
        id: 'subscription_status',
        accessorKey: 'subscriptions',
        meta: {
            title: 'Status',
        },
        header: ({ column }) =>
            h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: true,
        cell: ({ row }) => {
            const activeSubscription = row.original.subscriptions.find((sub) =>
                ['active', 'trialing'].includes(sub.status),
            )

            if (!activeSubscription) return 'Inactive'
            return activeSubscription.status === 'trialing' ? 'Trial' : 'Active'
        },
    },
    {
        id: 'subscription_plan',
        accessorKey: 'subscriptions',
        meta: {
            title: 'Plan',
        },
        header: ({ column }) =>
            h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: true,
        cell: ({ row }) => {
            const activeSubscription = row.original.subscriptions.find((sub) =>
                ['active', 'trialing'].includes(sub.status),
            )

            if (row.original.is_legacy_billing) return `Legacy Plan`

            if (!activeSubscription) return 'Free Tier'

            const mainProduct = activeSubscription.subscription_products.find((p) => p.product.product_type !== 'seat')

            return mainProduct ? mainProduct.product.name : 'Unknown'
        },
    },
    // {
    //     id: 'seats',
    //     accessorKey: 'subscriptions',
    //     meta: {
    //         title: 'Seats',
    //     },
    //     header: ({ column }) =>
    //         h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
    //     enableSorting: true,
    //     cell: ({ row }) => {
    //         const activeSubscription = row.original.subscriptions.find((sub) =>
    //             ['active', 'trialing'].includes(sub.status),
    //         )

    //         if (!activeSubscription) return 0
    //         const seatProduct = activeSubscription.subscription_products.find((p) => p.product.product_type === 'seat')
    //         return seatProduct ? seatProduct.quantity : 0
    //     },
    // },
    {
        id: 'discovery_usage',
        accessorKey: 'quotas',
        meta: {
            title: 'Discovery Usage',
        },
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: false,
        cell: ({ row }) => {
            const quotas = row.original.quotas || [];
            const discoveryQuota = quotas.find(q => q.type === 'discovery-creators');

            // Default values if quota not found
            const used = discoveryQuota ? discoveryQuota.used_quota : 0;
            const total = discoveryQuota ? discoveryQuota.allocated_quota : 0;
            const percentage = total > 0 ? Math.round((used / total) * 100) : 0;


            if (row.original.is_legacy_billing === true) {
                return h('div', { class: 'w-full space-y-1' }, '');
            }

            return h('div', { class: 'w-full space-y-1' }, [
                h('div', { class: 'flex justify-between text-xs' }, [
                    h('span', {}, `${numeral(used).format('0,0')}/${numeral(total).format('0,0')}`),
                    h('span', {}, `${percentage}%`)
                ]),
                h('div', { class: 'h-2 w-full rounded-full bg-gray-200' }, [
                    h('div', {
                        class: `h-full rounded-full ${getProgressColor(percentage)}`,
                        style: `width: ${percentage}%`
                    })
                ])
            ]);
        },
    },
    {
        id: 'created_at',
        accessorKey: 'created_at',
        meta: {
            title: 'Joined At',
        },
        header: ({ column }) =>
            h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: true,
        cell: ({ row }) => {
            return h('span', {
                class: 'font-family-inter-mono',
                title: moment(row.original.created_at).format('YYYY-MM-DD HH:mm')
            }, moment(row.original.created_at).fromNow())
        },
    },
    {
        id: 'last_active_at',
        accessorKey: 'last_active_at',
        meta: {
            title: 'Last Active At',
        },
        header: ({ column }) =>
            h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        enableSorting: true,
        cell: ({ row }) => {
            return h('span', {
                class: 'font-family-inter-mono',
                title: moment(row.original.last_active_at).format('YYYY-MM-DD HH:mm')
            }, moment(row.original.last_active_at).fromNow())
        },
    },
    {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
            return h(Button, {
                variant: "ghost",
                size: "sm",
                onClick: () => viewOrganization(row.original)
            }, {
                default: () => 'View'
            })
        },
    },
]

// API fetching function
const fetchOrganizations = async () => {
    isLoading.value = true
    try {
        const response = await axios.get('/organizations/all', {
            params: {
                page: pagination.value.pageIndex + 1, // API typically expects 1-indexed pages
                limit: pagination.value.pageSize,
                sort:
                    sorting.value.length > 0
                        ? `${sorting.value[0].id}:${sorting.value[0].desc ? 'desc' : 'asc'}`
                        : undefined,
                search: textFilter.value || undefined,
            },
        })

        totalOrganizationsCount.value = response.data.payload.meta.organizations_count
        totalOperatorsCount.value = response.data.payload.meta.operators_count

        organizations.value = response.data.payload.organizations
        rowCount.value = response.data.payload.meta?.total_records || organizations.value.length
    } catch (error) {
        console.error('Error fetching organizations:', error)
    } finally {
        isLoading.value = false
    }
}

// Action handlers
const viewOrganization = (organization) => {
    selectedOrganization.value = organization
    isOrganizationModalOpen.value = true
    activeTab.value = 'overview' // Reset to default tab when opening modal
}

const handleAddOrganization = () => {
    store.commit('modal:open', 'create_organization')
}

// Helper functions for display
const formatDate = (date) => {
    return date ? moment(date).format('MMM Do, YYYY h:mm A') : 'N/A'
}

const getSubscriptionStatus = (organization) => {
    const activeSubscription = organization.subscriptions.find((sub) => ['active', 'trialing'].includes(sub.status))

    if (!activeSubscription) return 'Inactive'
    return activeSubscription.status === 'trialing' ? 'Trial' : 'Active'
}

const getSubscriptionPlan = (organization) => {
    if (organization.is_legacy_billing) return 'Legacy Plan'

    const activeSubscription = organization.subscriptions.find((sub) => ['active', 'trialing'].includes(sub.status))

    if (!activeSubscription) return 'No active plan'

    const mainProduct = activeSubscription.subscription_products?.find((p) => p.product?.product_type !== 'seat')

    return mainProduct ? mainProduct.product.name : 'Unknown'
}

const getStatusBadgeVariant = (status) => {
    switch (status) {
        case 'Active':
            return 'default'
        case 'Trial':
            return 'secondary'
        default:
            return 'outline'
    }
}

const getTypeBadgeVariant = (type) => {
    switch (type) {
        case 'publisher':
            return 'outline'
        case 'brand':
            return 'outline'
        case 'marketing-agency':
            return 'outline'
        default:
            return 'outline'
    }
}

const formatOnboardData = (onboard) => {
    if (!onboard) return []

    const formattedData = []

    // Add fields to display in a structured format
    if (onboard.organization_industry) {
        formattedData.push({
            label: 'Industry',
            value: onboard.organization_industry,
            displayValue: _.startCase(onboard.organization_industry),
        })
    }

    if (onboard.organization_country) {
        formattedData.push({ label: 'Country', value: onboard.organization_country })
    }

    if (onboard.organization_referral_source) {
        formattedData.push({ label: 'Referral Source', value: onboard.organization_referral_source })
    }

    if (onboard.preferences_work && onboard.preferences_work.length) {
        formattedData.push({
            label: 'Work Preferences',
            value: onboard.preferences_work.join(', '),
            type: 'list',
            items: onboard.preferences_work,
        })
    }

    if (onboard.preferences_usage && onboard.preferences_usage.length) {
        formattedData.push({
            label: 'Usage Preferences',
            value: onboard.preferences_usage.join(', '),
            type: 'list',
            items: onboard.preferences_usage,
        })
    }

    if (onboard.is_product_updates_enabled !== undefined) {
        formattedData.push({
            label: 'Product Updates',
            value: onboard.is_product_updates_enabled ? 'Enabled' : 'Disabled',
        })
    }

    return formattedData
}

// Event handlers for table interactions
const onPaginationChange = (newPagination) => {
    pagination.value = newPagination
    fetchOrganizations()
}

const onSortingChange = (newSorting) => {
    sorting.value = newSorting
    fetchOrganizations()
}

const onFilteringChange = (newFiltering) => {
    filtering.value = newFiltering
    fetchOrganizations()
}

const onTextFilterChange = (newTextFilter) => {
    textFilter.value = newTextFilter
    pagination.value.pageIndex = 0 // Reset to first page when filtering
    fetchOrganizations()
}

// Initialize data on component mount
onMounted(() => {
    fetchOrganizations()
})
</script>

<template>
    <div class="container mx-auto p-6">
        <div class="mb-8 flex items-center justify-between">
            <div>
                <h1 class="text-3xl font-bold">Organizations</h1>
                <p class="text-gray-500">Manage customer organizations and their subscriptions</p>
            </div>
            <Button @click="handleAddOrganization">Add Organization</Button>
        </div>

        <!-- Dashboard Summary Cards -->
        <div class="mb-6 grid grid-cols-1 gap-4 md:grid-cols-3">
            <Card>
                <CardHeader class="pb-2">
                    <CardTitle class="text-sm font-medium">Total Organizations</CardTitle>
                </CardHeader>
                <CardContent>
                    <p class="text-2xl font-bold">{{ totalOrganizationsCount }}</p>
                </CardContent>
            </Card>

            <Card>
                <CardHeader class="pb-2">
                    <CardTitle class="text-sm font-medium">Total Users</CardTitle>
                </CardHeader>
                <CardContent>
                    <p class="text-2xl font-bold">{{ totalOperatorsCount }}</p>
                </CardContent>
            </Card>

            <!-- <Card>
                <CardHeader class="pb-2">
                    <CardTitle class="text-sm font-medium">Active Subscriptions</CardTitle>
                </CardHeader>
                <CardContent>
                    <p class="text-2xl font-bold">{{ activeOrgsCount }}</p>
                </CardContent>
            </Card> -->
        </div>

        <!-- Organizations Data Table -->
        <div class="rounded-md bg-white px-3 py-2 shadow">
            <DataTable
                :columns="columns"
                :data="organizations"
                :isLoaded="!isLoading"
                :isServerSide="true"
                :rowCount="rowCount"
                :pagination="pagination"
                :sorting="sorting"
                :filtering="filtering"
                :filterFields="filterFields"
                :toolbar="{
                    placeholder: 'Search organizations...',
                    columnToFilter: 'name',
                }"
                tableHeaderCellClass="font-medium"
                tableRowClass="hover:bg-gray-50"
                tableCellClass="px-3 py-0.5 text-sm"
                preferencesId="admin-organizations-table"
                @update:pagination="onPaginationChange"
                @update:sorting="onSortingChange"
                @update:filtering="onFilteringChange"
                @update:text-filter="onTextFilterChange"
            >
                <template #loading-skeleton>
                    <TableRow v-for="i in pagination?.pageSize" :key="i">
                        <TableCell v-for="j in columns.length" :key="j" class="p-2">
                            <div class="h-4 animate-pulse rounded bg-gray-200"></div>
                        </TableCell>
                    </TableRow>
                </template>
            </DataTable>
        </div>

        <!-- Organization Details Modal -->
        <Dialog v-model:open="isOrganizationModalOpen">
            <DialogContent class="max-h-[85vh] max-w-4xl overflow-y-auto">
                <DialogHeader>
                    <DialogTitle v-if="selectedOrganization" class="flex items-center">
                        {{ selectedOrganization.name }}
                        <Badge class="ml-2" :variant="getTypeBadgeVariant(selectedOrganization.type)">
                            {{ _.startCase(selectedOrganization.type) }}
                        </Badge>
                    </DialogTitle>
                    <DialogDescription v-if="selectedOrganization" class="text-xs text-gray-500">
                        ID: {{ selectedOrganization.id }}
                    </DialogDescription>
                </DialogHeader>

                <Tabs v-if="selectedOrganization" v-model="activeTab" class="w-full">
                    <TabsList class="mb-4 grid grid-cols-2">
                        <TabsTrigger value="overview">Overview</TabsTrigger>
                        <TabsTrigger value="onboarding">Onboarding Data</TabsTrigger>
                    </TabsList>

                    <!-- Overview Tab -->
                    <TabsContent value="overview" class="space-y-4">
                        <Card>
                            <CardHeader class="pb-2">
                                <CardTitle class="text-base">Organization Details</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div class="grid grid-cols-2 gap-4 text-sm">
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Name</h3>
                                        <p>{{ selectedOrganization.name }}</p>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Type</h3>
                                        <p class="capitalize">{{ selectedOrganization.type }}</p>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Created At</h3>
                                        <p>{{ formatDate(selectedOrganization.created_at) }}</p>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Updated At</h3>
                                        <p>{{ formatDate(selectedOrganization.updated_at) }}</p>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Subscription Status</h3>
                                        <Badge
                                            :variant="
                                                getStatusBadgeVariant(getSubscriptionStatus(selectedOrganization))
                                            "
                                        >
                                            {{ getSubscriptionStatus(selectedOrganization) }}
                                        </Badge>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Subscription Plan</h3>
                                        <p>{{ getSubscriptionPlan(selectedOrganization) }}</p>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Seats</h3>
                                        <p>{{ selectedOrganization.seats }}</p>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Legacy Billing</h3>
                                        <p>{{ selectedOrganization.is_legacy_billing ? 'Yes' : 'No' }}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <!-- Limitations Section (if present) -->
                        <Card v-if="selectedOrganization.limitations && selectedOrganization.limitations.length">
                            <CardHeader class="pb-2">
                                <CardTitle class="text-base">Limitations</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div class="grid grid-cols-2 gap-4 text-sm">
                                    <div v-for="limit in selectedOrganization.limitations" :key="limit.key">
                                        <h3 class="text-xs font-medium text-gray-500">{{ _.startCase(limit.key) }}</h3>
                                        <p>{{ limit.value }}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <!-- Users Section -->
                        <Card>
                            <CardHeader class="pb-2">
                                <CardTitle class="text-base"
                                    >Users ({{ selectedOrganization.operators?.length || 0 }})</CardTitle
                                >
                            </CardHeader>
                            <CardContent>
                                <div v-if="selectedOrganization.operators && selectedOrganization.operators.length > 0">
                                    <div class="overflow-hidden rounded-md border">
                                        <div class="max-h-64 overflow-y-auto">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="sticky top-0 z-10 bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                        >
                                                            Name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                        >
                                                            Role
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                        >
                                                            Avatar
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="divide-y divide-gray-200 bg-white">
                                                    <tr
                                                        v-for="operator in selectedOrganization.operators"
                                                        :key="operator.id"
                                                    >
                                                        <td class="whitespace-nowrap px-4 py-2">
                                                            <div class="flex items-center">
                                                                <div>
                                                                    <div class="text-sm font-medium text-gray-900">
                                                                        {{ operator.first_name }}
                                                                        {{ operator.last_name }}
                                                                    </div>
                                                                    <div class="text-xs text-gray-500">
                                                                        {{ operator.email }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">
                                                            <Badge variant="outline" class="capitalize">
                                                                {{ operator.organization_operator?.role || 'N/A' }}
                                                            </Badge>
                                                        </td>
                                                        <td class="whitespace-nowrap px-4 py-2">
                                                            <div
                                                                v-if="operator.avatar_url"
                                                                class="h-8 w-8 overflow-hidden rounded-full bg-gray-200"
                                                            >
                                                                <img
                                                                    :src="operator.avatar_url"
                                                                    alt="Avatar"
                                                                    class="h-full w-full object-cover"
                                                                />
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200"
                                                            >
                                                                <span class="text-xs text-gray-500">
                                                                    {{ operator.first_name.charAt(0)
                                                                    }}{{ operator.last_name.charAt(0) }}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="py-4 text-center">
                                    <p class="text-gray-500">No users found for this organization.</p>
                                </div>
                            </CardContent>
                        </Card>
                    </TabsContent>

                    <!-- Onboarding Data Tab -->
                    <TabsContent value="onboarding" class="space-y-4">
                        <Card v-if="selectedOrganization.onboard">
                            <CardHeader class="pb-2">
                                <CardTitle class="text-base">Onboarding Information</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div class="grid grid-cols-2 gap-4 text-sm">
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Admin Email</h3>
                                        <p>{{ selectedOrganization.onboard.email || 'N/A' }}</p>
                                    </div>
                                    <div>
                                        <h3 class="text-xs font-medium text-gray-500">Admin</h3>
                                        <p>
                                            {{ selectedOrganization.onboard.first_name || '' }}
                                            {{ selectedOrganization.onboard.last_name || '' }}
                                        </p>
                                    </div>

                                    <template
                                        v-for="field in formatOnboardData(selectedOrganization.onboard)"
                                        :key="field.label"
                                    >
                                        <div class="col-span-2">
                                            <h3 class="text-xs font-medium text-gray-500">{{ field.label }}</h3>

                                            <!-- Display as list if it's a list type -->
                                            <div v-if="field.type === 'list'" class="mt-1">
                                                <ul class="list-disc pl-5">
                                                    <li v-for="item in field.items" :key="item" class="text-sm">
                                                        {{ item }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <!-- Display as normal text otherwise -->
                                            <p v-else>{{ field.displayValue || field.value }}</p>
                                        </div>
                                    </template>
                                </div>
                            </CardContent>
                        </Card>
                        <div v-else class="py-8 text-center">
                            <p class="text-gray-500">No onboarding data available for this organization.</p>
                        </div>
                    </TabsContent>
                </Tabs>
            </DialogContent>
        </Dialog>
    </div>
</template>
