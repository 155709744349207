<template>
    <div>
        <div class="relative flex justify-end">
            <div class="relative">
                <input
                    v-model="state.search.searchText"
                    class="form-input pl-9 focus:border-slate-300"
                    type="search"
                    placeholder="Filter by creator name"
                    @keyup="debounceSearch"
                />
                <div class="group absolute inset-0 right-auto" aria-label="Search">
                    <svg
                        class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                        />
                        <path
                            d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                        />
                    </svg>
                </div>
            </div>
        </div>

        <div class="mt-4 flex w-full flex-col xl:flex-row">
            <!-- Everyone tab -->
            <div>
                <div
                    class="flex h-full w-full cursor-pointer select-none rounded-bl rounded-tl border-b border-l border-t bg-white px-4 py-3 text-left text-xs font-medium uppercase text-slate-500"
                    :class="{
                        'border-slate-200': !isTabSelected(tabs.everyone.key),
                        '!cursor-default !bg-indigo-50': isTabSelected(tabs.everyone.key),
                    }"
                    @click="setActiveTab(tabs.everyone.key)"
                >
                    <div class="my-auto flex grow flex-row justify-between xl:flex-col">
                        <div class="flex">
                            <span>{{ tabs.everyone.label }}</span>
                        </div>
                        <div class="mt-1">
                            {{ getTabRecordCount(tabs.everyone.summaryPath) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="grow">
                <div v-if="summary !== null" class="flex flex-row">
                    <div
                        v-for="tab in filteredTabsWithoutEveryone"
                        :key="tab.key"
                        class="group flex w-full cursor-pointer select-none border-b border-l border-t bg-white px-4 py-3 text-left text-xs font-medium uppercase text-slate-500 last:rounded-tr last:border-r"
                        :class="{
                            'border-slate-200 hover:bg-slate-50': !isTabSelected(tab.key),
                            '!cursor-default border-b-slate-200 !bg-indigo-50': isTabSelected(tab.key),
                        }"
                        @click="setActiveTab(tab.key)"
                    >
                        <div class="flex grow justify-between">
                            <div class="my-auto flex">
                                <span
                                    class="my-auto block h-2 w-2 rounded-full"
                                    :class="getFromDictionary(`campaign.stages.${tab.key}.bgClass`)"
                                />
                                <span class="ml-1.5 leading-none">{{ tab.label }}</span>
                            </div>
                            <div class="my-auto pl-2">
                                {{ getTabRecordCount(tab.summaryPath) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="getParentTab(activeTab.key) !== null && getParentTab(activeTab.key).tabs.length > 0"
                    class="mr-[1px] flex flex-wrap overflow-hidden border-b border-r xl:h-12 xl:flex-nowrap"
                >
                    <!-- All Statuses Option -->
                    <div
                        class="group relative flex w-full select-none border-l border-r-slate-400 bg-white py-3 pl-2 text-left text-xs font-medium uppercase text-slate-500 last:rounded-br last:border-r"
                        :class="{
                            'cursor-pointer transition duration-200 hover:bg-slate-50':
                                activeParentTab.key !== activeTab.key,
                            '!cursor-default !bg-indigo-50 bg-white': activeParentTab.key === activeTab.key,
                        }"
                        @click="setActiveTab(activeParentTab.key)"
                    >
                        <div class="flex grow justify-between">
                            <div class="z-20 flex">
                                <span
                                    class="m-auto block flex h-5 w-5 rounded text-center text-white"
                                    :class="getFromDictionary(`campaign.stages.${activeParentTab.key}.bgClass`)"
                                >
                                    <span class="m-auto text-xs"></span>
                                </span>
                                <span class="my-auto ml-2 leading-none">All Statuses</span>
                            </div>
                            <div class="my-auto pr-2 xl:last:pr-0">
                                {{ getTabRecordCount(activeParentTab.summaryPath) }}
                            </div>
                        </div>

                        <div
                            class="invisible absolute -right-4 top-0 z-10 h-12 w-12 rotate-45 border-r border-t bg-white xl:visible"
                            :class="{
                                'cursor-pointer transition duration-200 group-hover:bg-slate-50':
                                    activeParentTab.key !== activeTab.key,
                                '!cursor-default !bg-indigo-50 bg-white': activeParentTab.key === activeTab.key,
                            }"
                        />
                    </div>

                    <div
                        v-for="(tab, tabIndex) in activeChildTabs"
                        :key="tab.key"
                        class="group relative flex w-full select-none bg-white py-3 pr-1 pr-2 text-left text-xs font-medium uppercase text-slate-500 last:rounded-br last:pr-2 xl:last:pr-0 xl:last:pr-4"
                        :class="{
                            'cursor-pointer': !isTabSelected(tab.key),
                            '!cursor-default': isTabSelected(tab.key),
                            'xl:pl-2': tab.index != 0,
                            'xl:pl-6': tab.index == 0,
                        }"
                        @click="setActiveTab(tab.key)"
                    >
                        <div class="z-20 flex grow justify-between">
                            <div class="flex pl-2">
                                <span
                                    class="m-auto block flex h-5 w-5 rounded text-center text-white"
                                    :class="getFromDictionary(`campaign.stages.${activeParentTab.key}.bgClass`)"
                                >
                                    <span v-if="tab.index === 'X'" class="m-auto text-xs">X</span>
                                    <span v-else class="m-auto text-xs">{{ tabIndex + 1 }}</span>
                                </span>
                                <span class="my-auto ml-2 leading-none">{{ tab.label }}</span>
                            </div>
                            <div class="my-auto">
                                {{ getTabRecordCount(tab.summaryPath) }}
                            </div>
                        </div>
                        <div
                            class="absolute -left-4 bottom-0 right-4 top-0 last:right-0"
                            :class="{
                                'transition duration-200 group-hover:bg-slate-50': !isTabSelected(tab.key),
                                '!cursor-default !bg-indigo-50 bg-white': isTabSelected(tab.key),
                            }"
                        ></div>
                        <div
                            v-if="tab.index != 'X'"
                            class="invisible absolute -right-0 top-0 z-10 h-12 w-12 rotate-45 border-r border-t border-b-slate-400 bg-white xl:visible"
                            :class="{
                                ' transition duration-200 group-hover:bg-slate-50': !isTabSelected(tab.key),
                                '!cursor-default !bg-indigo-50 bg-white': isTabSelected(tab.key),
                            }"
                        />
                    </div>
                </div>

                <div v-else class="flex h-12 w-full">
                    <div class="mr-[1px] grow rounded-br border-b border-l border-r bg-slate-50" />
                </div>
            </div>
        </div>

        <div class="mt-5">
            <WorkflowTable
                :actions="actions"
                :creators="creators"
                :paging="paging"
                :campaign="getCampaign"
                :active-parent-tab="activeParentTab"
                :active-child-tab="activeChildTab"
                :active-tab="activeTab"
                :tabs="filteredTabsWithoutEveryone"
                @paging-change="onPagingChange"
                @reload="onReload"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { nextTick } from 'vue'
import WorkflowTable from '@/partials/tables/WorkflowTable.vue'
import campaignAPI from '../utils/api/api.campaign'

export default {
    name: 'CampaignWorkflowSubpage',

    components: {
        WorkflowTable,
    },

    props: {
        supercampaign: Object,

        campaignsById: Object,

        column: {
            type: Object,
            default: () => ({}),
        },

        row: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            creators: null,
            summary: null,
            stageSettings: null,

            paging: {
                totalPages: 0,
                perPage: 25,
                currentPage: 1,
                sortby: 'creator',
                sortdir: 'asc',
            },

            state: {
                isLoaded: false,
                search: {
                    lastText: '',
                    searchText: '',
                },
            },

            activeChildTab: null,
            activeParentTab: null,
            activeTab: null,

            actions: [
                // Prospect
                {
                    id: 'prospect-approved',
                    label: 'Approved',
                    iconType: 'forward',
                    visibilityId: 'prospect-approved',
                    stages: [],
                    status: [], // Set via rules
                    instructions: null,
                    options: [
                        { id: 'approved-date', type: 'datetime', label: 'Date the creator was approved', value: null },
                    ],
                },
                {
                    id: 'prospect-send-outreach',
                    label: 'Send outreach',
                    iconType: 'forward',
                    visibilityId: 'prospect-send-outreach',
                    stages: [],
                    status: [], // Set via rules
                    instructions: `Are you sure you want to send outreach? The creator will receive an email regarding the opportunity.`,
                    options: [],
                    validation: {
                        rules: [
                            { type: 'remote', value: 'prospect-send-outreach' },
                            { type: 'ensure-prop-has-value', value: 'superview.normalized.email' },
                        ],
                        messages: {
                            'invalid-participants': {
                                title: 'Email address missing',
                                message: 'The following creators do not currently have an email address set:',
                            },
                            'outreach-already-sent': {
                                title: 'Outreach has already been sent',
                                message:
                                    'The following creators have already recieved outreach, please set their status to outreached:',
                            },
                        },
                    },
                },
                {
                    id: 'prospect-approved',
                    label: 'Update approved',
                    iconType: 'static',
                    stages: [],
                    status: ['prospect.approved'],
                    instructions: null,
                    options: [
                        { id: 'approved-date', type: 'datetime', label: 'Date the creator was approved', value: null },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'prospect-last-contacted',
                    label: 'Mark as outreached',
                    iconType: 'check',
                    visibilityId: 'prospect-last-contacted',
                    stages: [],
                    status: [], // Set via rules
                    instructions: null,
                    options: [
                        {
                            id: 'last-contacted-date',
                            type: 'datetime',
                            label: 'Date the outreach was performed',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'prospect-last-contacted',
                    label: 'Update date of outreach',
                    iconType: 'static',
                    visibilityId: 'prospect-last-contacted-update',
                    stages: [],
                    status: [], // Set via rules
                    instructions: null,
                    options: [
                        {
                            id: 'last-contacted-date',
                            type: 'datetime',
                            label: 'Date the outreach was performed',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'prospect-outreach-response',
                    label: 'Mark as interested',
                    iconType: 'forward',
                    visibilityId: 'prospect-outreach-response',
                    stages: [],
                    status: [], // Set via rules
                    instructions: null,
                    options: [],
                    validation: {
                        rules: [{ type: 'remote', value: 'prospect-outreach-response' }],
                        messages: {
                            'invalid-participants': {
                                title: 'Creators must have a bid',
                                message: 'The following creators do not currently have a bid:',
                            },
                        },
                    },
                },

                // Pricing
                /* {
                    id: 'pricing-price-received',
                    label: 'Price received',
                    iconType: 'forward',
                    stages: [],
                    status: ['pricing.started'],
                    instructions: null,
                    options: [
                        {
                            id: 'pricing-received-date',
                            type: 'datetime',
                            label: 'Date the price was received',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'pricing-price-received',
                    label: 'Update price received',
                    iconType: 'static',
                    stages: [],
                    status: ['pricing.price-received'],
                    instructions: null,
                    options: [
                        {
                            id: 'pricing-received-date',
                            type: 'datetime',
                            label: 'Date the price was received',
                            value: null,
                        },
                    ],
                }, */
                {
                    id: 'pricing-counter-offer',
                    label: 'Counter-offer',
                    iconType: 'repeat',
                    stages: [],
                    status: ['pricing.price-received'],
                    instructions: null,
                    options: [
                        {
                            id: 'pricing-counter-offer-date',
                            type: 'datetime',
                            label: 'Date a counter-offer was received',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'pricing-counter-offer',
                    label: 'Update counter-offer',
                    iconType: 'static',
                    stages: [],
                    status: ['pricing.counter-offer'],
                    instructions: null,
                    options: [
                        {
                            id: 'pricing-counter-offer-date',
                            type: 'datetime',
                            label: 'Date a counter-offer was received',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'pricing-counter-counter-offer',
                    label: 'Counter counter-offer',
                    iconType: 'repeat',
                    stages: [],
                    status: ['pricing.counter-offer'],
                    instructions: null,
                    options: [
                        {
                            id: 'pricing-counter-counter-offer-date',
                            type: 'datetime',
                            label: 'Date the counter counter-offer was sent',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'pricing-counter-counter-offer',
                    label: 'Update counter counter-offer',
                    iconType: 'static',
                    stages: [],
                    status: ['pricing.creator-counter-offer'],
                    instructions: null,
                    options: [
                        {
                            id: 'pricing-counter-counter-offer-date',
                            type: 'datetime',
                            label: 'Date the counter counter-offer was sent',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'pricing-price-accepted',
                    label: 'Price accepted',
                    iconType: 'forward',
                    stages: [],
                    status: ['pricing.price-received', 'pricing.counter-offer', 'pricing.creator-counter-offer'],
                    instructions: null,
                    options: [
                        {
                            id: 'pricing-accepted-date',
                            type: 'datetime',
                            label: 'Date the price was accepted',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'pricing-complete',
                    label: 'Creator notified',
                    iconType: 'forward',
                    stages: [],
                    status: ['pricing.price-accepted'],
                    instructions: null,
                    options: [],
                },

                // Contracting
                {
                    id: 'contracting-contact-generated',
                    label: 'Contract generated',
                    iconType: 'forward',
                    stages: [],
                    status: ['contracting.started'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-generated-date',
                            type: 'datetime',
                            label: 'Date the contract was generated',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'contracting-contact-generated',
                    label: 'Update contract generated',
                    iconType: 'static',
                    stages: [],
                    status: ['contracting.contract-generated'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-generated-date',
                            type: 'datetime',
                            label: 'Date the contract was generated',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'contracting-contact-sent',
                    label: 'Contract sent',
                    iconType: 'forward',
                    stages: [],
                    status: ['contracting.contract-generated'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-sent-date',
                            type: 'datetime',
                            label: 'Date the contract was sent',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'contracting-contact-sent',
                    label: 'Update contract sent',
                    iconType: 'static',
                    stages: [],
                    status: ['contracting.contract-sent'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-sent-date',
                            type: 'datetime',
                            label: 'Date the contract was sent',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'contracting-contact-changes-requested',
                    label: 'Changes requested',
                    iconType: 'repeat',
                    stages: [],
                    status: ['contracting.contract-sent'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-changes-requested-date',
                            type: 'datetime',
                            label: 'Date changes were requested',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'contracting-contact-changes-requested',
                    label: 'Update changes requested',
                    iconType: 'static',
                    stages: [],
                    status: ['contracting.changes-requested'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-changes-requested-date',
                            type: 'datetime',
                            label: 'Date changes were requested',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'contracting-contact-changes-completed',
                    label: 'Changes completed',
                    iconType: 'forward',
                    stages: [],
                    status: ['contracting.changes-requested'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-changes-completed-date',
                            type: 'datetime',
                            label: 'Date changes were completed',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'contracting-contact-changes-completed',
                    label: 'Update changes completed',
                    iconType: 'static',
                    stages: [],
                    status: ['contracting.changes-completed'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-changes-completed-date',
                            type: 'datetime',
                            label: 'Date changes were completed',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'contracting-contact-signed',
                    label: 'Contract signed',
                    iconType: 'forward',
                    stages: [],
                    status: ['contracting.changes-completed', 'contracting.contract-sent'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-signed-date',
                            type: 'datetime',
                            label: 'Date the contract was signed',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'contracting-contact-signed',
                    label: 'Update contract signed',
                    iconType: 'static',
                    stages: [],
                    status: ['contracting.contract-signed'],
                    instructions: null,
                    options: [
                        {
                            id: 'contract-signed-date',
                            type: 'datetime',
                            label: 'Date the contract was signed',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'contracting-contact-counter-signed',
                    label: 'Contract counter-signed',
                    iconType: 'forward',
                    stages: [],
                    status: ['contracting.contract-signed'],
                    instructions: null,
                    options: [
                        {
                            id: 'counter-signed-date',
                            type: 'datetime',
                            label: 'Date the contract was counter-signed',
                            value: null,
                        },
                    ],
                },

                // Preparation
                {
                    id: 'preparation-assets-sent',
                    label: 'Assets sent',
                    iconType: 'forward',
                    stages: [],
                    status: ['preparation.started'],
                    instructions: null,
                    options: [
                        { id: 'assets-sent-date', type: 'datetime', label: 'Date the assets were sent', value: null },
                    ],
                },
                {
                    id: 'preparation-assets-sent',
                    label: 'Update assets sent',
                    iconType: 'static',
                    stages: [],
                    status: ['preparation.assets-sent'],
                    instructions: null,
                    options: [
                        { id: 'assets-sent-date', type: 'datetime', label: 'Date the assets were sent', value: null },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'preparation-complete',
                    label: 'Move to delivery',
                    iconType: 'forward',
                    stages: [],
                    status: ['preparation.assets-sent', 'preparation.assets-verified'],
                    instructions: null,
                    options: null,
                },

                // Delivery
                {
                    id: 'delivery-content-approved',
                    label: 'Content approved',
                    iconType: 'forward',
                    visibilityId: 'delivery-content-approved',
                    stages: [],
                    status: [], //
                    instructions: null,
                    options: [
                        {
                            id: 'content-approved-date',
                            type: 'datetime',
                            label: 'Date the content was approved',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'delivery-content-approved',
                    label: 'Update Content approved',
                    iconType: 'static',
                    stages: [],
                    status: ['delivery.content-approved'],
                    instructions: null,
                    options: [
                        {
                            id: 'content-approved-date',
                            type: 'datetime',
                            label: 'Date the content was approved',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'delivery-content-associated',
                    label: 'Content associated',
                    iconType: 'forward',
                    visibilityId: 'delivery-content-associated',
                    stages: [],
                    status: [], // Set via rules
                    instructions: null,
                    options: [
                        {
                            id: 'content-associated-date',
                            type: 'datetime',
                            label: 'Date the content was associated',
                            value: null,
                        },
                    ],
                },
                {
                    id: 'delivery-content-associated',
                    label: 'Update content associated',
                    iconType: 'static',
                    stages: [],
                    status: ['delivery.content-associated'],
                    instructions: null,
                    options: [
                        {
                            id: 'content-associated-date',
                            type: 'datetime',
                            label: 'Date the content was associated',
                            value: null,
                        },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'delivery-paid',
                    visibilityId: 'delivery-paid',
                    label: 'Paid',
                    iconType: 'forward',
                    stages: [],
                    status: ['delivery.content-associated'],
                    instructions: null,
                    options: [
                        { id: 'creator-paid-date', type: 'datetime', label: 'Date the creator was paid', value: null },
                    ],
                },
                {
                    id: 'delivery-paid',
                    label: 'Update paid',
                    iconType: 'static',
                    stages: [],
                    status: ['delivery.paid'],
                    instructions: null,
                    options: [
                        { id: 'creator-paid-date', type: 'datetime', label: 'Date the creator was paid', value: null },
                    ],
                    isUpdate: true,
                },
                {
                    id: 'delivery-complete',
                    visibilityId: 'delivery-complete',
                    label: 'Complete',
                    iconType: 'forward',
                    stages: [],
                    status: ['delivery.paid'],
                    instructions: 'Confirm all activities completed',
                    options: [],
                },
                {
                    id: 'delivery-reopen',
                    label: 'Reopen',
                    iconType: 'backward',
                    stages: [],
                    status: ['delivery.complete'],
                    instructions: 'Reopen this participant',
                    options: [],
                    showWarning: true,
                },

                // Other
                {
                    id: 'rollback',
                    label: 'Revert to previous stage',
                    iconType: 'backward',
                    stages: [],
                    status: [
                        'pricing.started',
                        'pricing.price-received',
                        'contracting.started',
                        'preparation.started',
                        'delivery.started',
                    ],
                    instructions: 'Moves back to the previous stage',
                    options: [],
                    showWarning: true,
                },

                {
                    id: 'remove',
                    label: 'Remove from campaign',
                    iconType: 'exit',
                    stages: [],
                    // show removed in all stage-statuses except when removed, probably a better way to do this
                    status: [
                        'prospect.started',
                        'prospect.inquiry-sent',
                        'prospect.approved',
                        'pricing.started',
                        'pricing.price-received',
                        'pricing.counter-offer',
                        'pricing.creator-counter-offer',
                        'pricing.price-accepted',
                        'contracting.started',
                        'contracting.contract-generated',
                        'contracting.contract-sent',
                        'contracting.changes-requested',
                        'contracting.changes-completed',
                        'contracting.contract-signed',
                        'preparation.started',
                        'preparation.assets-sent',
                        'delivery.started',
                        'delivery.content-approved',
                        'delivery.content-associated',
                        'delivery.paid',
                        'delivery.complete',
                    ],
                    instructions: 'Removes the participant from the campaign',
                    options: [],
                    showWarning: true,
                },

                {
                    id: 'unremove',
                    label: 'Add removed back to campaign',
                    iconType: 'repeat',
                    stages: [],
                    status: [
                        'prospect.removed',
                        'pricing.removed',
                        'contracting.removed',
                        'preparation.removed',
                        'delivery.removed',
                    ],
                    instructions: 'Add this removed participant back to the campaign',
                    options: [],
                    showWarning: true,
                },
            ],

            tabs: {
                everyone: {
                    key: 'everyone',
                    summaryPath: 'total',
                    index: 0,
                    label: 'All Stages',
                    bgClass: 'bg-gray-500',
                    tabs: [],
                    filters: [],
                },
                prospect: {
                    key: 'prospect',
                    summaryPath: 'prospect.total',
                    index: 1,
                    label: 'Prospecting',
                    bgClass: 'bg-sky-500',
                    isDisabled: this.stageSettings?.prospect.is_disabled,
                    tabs: [
                        {
                            index: 0,
                            key: 'prospect.started',
                            summaryPath: 'prospect.started',
                            label: 'Added',
                            filters: [
                                { id: 'stage', value: 'prospect' },
                                { id: 'stage-status', value: 'started' },
                            ],
                        },
                        {
                            index: 1,
                            key: 'prospect.approved',
                            summaryPath: 'prospect.approved',
                            label: 'Pre-Approved',
                            filters: [
                                { id: 'stage', value: 'prospect' },
                                { id: 'stage-status', value: 'approved' },
                            ],
                            isDisabled: () => this.stageSettings?.prospect.is_approval_disabled,
                        },
                        {
                            index: 2,
                            key: 'prospect.inquiry-sent',
                            summaryPath: 'prospect.inquiry-sent',
                            label: 'Outreach Sent',
                            filters: [
                                {
                                    id: 'stage',
                                    value: 'prospect',
                                },
                                {
                                    id: 'stage-status',
                                    value: 'inquiry-sent',
                                },
                            ],
                        },
                        {
                            index: 'X',
                            key: 'prospect.removed',
                            summaryPath: 'prospect.removed',
                            label: 'Removed',
                            filters: [
                                { id: 'stage', value: 'prospect' },
                                { id: 'stage-status', value: 'removed' },
                            ],
                        },
                    ],
                    filters: [{ id: 'stage', value: 'prospect' }],
                },
                pricing: {
                    key: 'pricing',
                    summaryPath: 'pricing.total',
                    index: 2,
                    label: 'Pricing',
                    bgClass: 'bg-emerald-500',
                    isDisabled: this.stageSettings?.pricing.is_disabled,
                    tabs: [
                        /* {
                            index: 0,
                            key: 'pricing.started',
                            summaryPath: 'pricing.started',
                            label: 'Pricing started',
                            filters: [
                                { id: 'stage', value: 'pricing' },
                                { id: 'stage-status', value: 'started' },
                            ],
                        }, */
                        {
                            index: 0,
                            key: 'pricing.price-received',
                            summaryPath: 'pricing.price-received',
                            label: 'Price Received',
                            filters: [
                                { id: 'stage', value: 'pricing' },
                                { id: 'stage-status', value: 'price-received' },
                            ],
                        },
                        {
                            index: 1,
                            key: 'pricing.counter-offer',
                            summaryPath: 'pricing.counter-offer',
                            label: 'Counter Offer',
                            filters: [
                                { id: 'stage', value: 'pricing' },
                                { id: 'stage-status', value: 'counter-offer' },
                            ],
                        },
                        {
                            index: 2,
                            key: 'pricing.creator-counter-offer',
                            summaryPath: 'pricing.creator-counter-offer',
                            label: 'Creator Counter Offer',
                            filters: [
                                { id: 'stage', value: 'pricing' },
                                { id: 'stage-status', value: 'creator-counter-offer' },
                            ],
                        },
                        {
                            index: 3,
                            key: 'pricing.price-accepted',
                            summaryPath: 'pricing.price-accepted',
                            label: 'Price Accepted',
                            filters: [
                                { id: 'stage', value: 'pricing' },
                                { id: 'stage-status', value: 'price-accepted' },
                            ],
                        },
                        {
                            index: 'X',
                            key: 'pricing.removed',
                            summaryPath: 'pricing.removed',
                            label: 'Removed',
                            filters: [
                                { id: 'stage', value: 'pricing' },
                                { id: 'stage-status', value: 'removed' },
                            ],
                        },
                    ],
                    filters: [{ id: 'stage', value: 'pricing' }],
                },
                contracting: {
                    key: 'contracting',
                    summaryPath: 'contracting.total',
                    index: 3,
                    label: 'Contracting',
                    bgClass: 'bg-indigo-500',
                    isDisabled: this.stageSettings?.contracting.is_disabled,
                    tabs: [
                        {
                            index: 0,
                            key: 'contracting.started',
                            summaryPath: 'contracting.started',
                            label: 'Started',
                            filters: [
                                { id: 'stage', value: 'contracting' },
                                { id: 'stage-status', value: 'started' },
                            ],
                        },
                        {
                            index: 1,
                            key: 'contracting.contract-generated',
                            summaryPath: 'contracting.contract-generated',
                            label: 'Contract Generated',
                            filters: [
                                { id: 'stage', value: 'contracting' },
                                { id: 'stage-status', value: 'contract-generated' },
                            ],
                        },
                        {
                            index: 2,
                            key: 'contracting.contract-sent',
                            summaryPath: 'contracting.contract-sent',
                            label: 'Contract Sent',
                            filters: [
                                { id: 'stage', value: 'contracting' },
                                { id: 'stage-status', value: 'contract-sent' },
                            ],
                        },
                        {
                            index: 3,
                            key: 'contracting.changes-requested',
                            summaryPath: 'contracting.changes-requested',
                            label: 'Changes Requested',
                            filters: [
                                { id: 'stage', value: 'contracting' },
                                { id: 'stage-status', value: 'changes-requested' },
                            ],
                        },
                        {
                            index: 4,
                            key: 'contracting.changes-completed',
                            summaryPath: 'contracting.changes-completed',
                            label: 'Changes Completed',
                            filters: [
                                { id: 'stage', value: 'contracting' },
                                { id: 'stage-status', value: 'changes-completed' },
                            ],
                        },
                        {
                            index: 5,
                            key: 'contracting.contract-signed',
                            summaryPath: 'contracting.contract-signed',
                            label: 'Contract Signed',
                            filters: [
                                { id: 'stage', value: 'contracting' },
                                { id: 'stage-status', value: 'contract-signed' },
                            ],
                        },
                        // {
                        //     index: 6,
                        //     key: 'contracting.contract-countersigned',
                        //     summaryPath: 'contracting.contract-countersigned',
                        //     label: 'Contract Countersigned',
                        //     filters: [{ "id": "stage", "value": "contracting" }, {  "id": "stage-status", "value": "contract-countersigned" }],
                        // },
                        {
                            index: 'X',
                            key: 'contracting.removed',
                            summaryPath: 'contracting.removed',
                            label: 'Removed',
                            filters: [
                                { id: 'stage', value: 'contracting' },
                                { id: 'stage-status', value: 'removed' },
                            ],
                        },
                    ],
                    filters: [{ id: 'stage', value: 'contracting' }],
                },
                preparation: {
                    key: 'preparation',
                    summaryPath: 'preparation.total',
                    index: 4,
                    label: 'Preparation',
                    bgClass: 'bg-rose-400',
                    isDisabled: this.stageSettings?.preparation.is_disabled,
                    tabs: [
                        {
                            index: 0,
                            key: 'preparation.started',
                            summaryPath: 'preparation.started',
                            label: 'Started',
                            filters: [
                                { id: 'stage', value: 'preparation' },
                                { id: 'stage-status', value: 'started' },
                            ],
                        },
                        {
                            index: 1,
                            key: 'preparation.assets-sent',
                            summaryPath: 'preparation.assets-sent',
                            label: 'Assets Sent',
                            filters: [
                                { id: 'stage', value: 'preparation' },
                                { id: 'stage-status', value: 'assets-sent' },
                            ],
                        },
                        {
                            index: 'X',
                            key: 'preparation.removed',
                            summaryPath: 'preparation.removed',
                            label: 'Removed',
                            filters: [
                                { id: 'stage', value: 'preparation' },
                                { id: 'stage-status', value: 'removed' },
                            ],
                        },
                    ],
                    filters: [{ id: 'stage', value: 'preparation' }],
                },
                delivery: {
                    key: 'delivery',
                    summaryPath: 'delivery.total',
                    index: 5,
                    label: 'Delivery',
                    bgClass: 'bg-amber-500',
                    isDisabled: this.stageSettings?.delivery.is_disabled,
                    tabs: [
                        {
                            index: 0,
                            key: 'delivery.started',
                            summaryPath: 'delivery.started',
                            label: 'Awaiting Delivery',
                            filters: [
                                { id: 'stage', value: 'delivery' },
                                { id: 'stage-status', value: 'started' },
                            ],
                        },
                        {
                            index: 1,
                            key: 'delivery.content-approved',
                            summaryPath: 'delivery.content-approved',
                            label: 'Content Approved',
                            filters: [
                                { id: 'stage', value: 'delivery' },
                                { id: 'stage-status', value: 'content-approved' },
                            ],
                            isDisabled: () => this.stageSettings?.delivery.is_approval_disabled,
                        },
                        {
                            index: 2,
                            key: 'delivery.content-associated',
                            summaryPath: 'delivery.content-associated',
                            label: 'Content Associated',
                            filters: [
                                { id: 'stage', value: 'delivery' },
                                { id: 'stage-status', value: 'content-associated' },
                            ],
                        },
                        {
                            index: 3,
                            key: 'delivery.paid',
                            summaryPath: 'delivery.paid',
                            label: 'Paid',
                            filters: [
                                { id: 'stage', value: 'delivery' },
                                { id: 'stage-status', value: 'paid' },
                            ],
                            isDisabled: () => this.stageSettings?.pricing.is_disabled,
                        },
                        {
                            index: 4,
                            key: 'delivery.complete',
                            summaryPath: 'delivery.complete',
                            label: 'Complete',
                            filters: [
                                { id: 'stage', value: 'delivery' },
                                { id: 'stage-status', value: 'complete' },
                            ],
                        },
                        {
                            index: 'X',
                            key: 'delivery.removed',
                            summaryPath: 'delivery.removed',
                            label: 'Removed',
                            filters: [
                                { id: 'stage', value: 'delivery' },
                                { id: 'stage-status', value: 'removed' },
                            ],
                        },
                    ],
                    filters: [{ id: 'stage', value: 'delivery' }],
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getLanguageFromCode', 'getFromDictionary']),

        getCampaign() {
            return this.campaignsById[this.$route.params.campaign_id]
        },

        filteredTabsWithoutEveryone() {
            const tabsWithoutEveryone = _.reduce(
                this.tabs,
                (result, tab, tabKey) => {
                    // eslint-disable-next-line no-param-reassign
                    if (tabKey !== 'everyone') {
                        result[tabKey] = tab

                        if (
                            this.stageSettings &&
                            this.stageSettings[tabKey] &&
                            this.stageSettings[tabKey].is_disabled
                        ) {
                            result[tabKey].isDisabled = true
                        }
                    }
                    return result
                },
                {},
            )

            return _.pickBy(tabsWithoutEveryone, (tab) => !tab.isDisabled)
        },

        activeChildTabs() {
            return _.filter(this.activeParentTab.tabs, (tab) => !tab.isDisabled || !tab.isDisabled())
        },

        // used for getting all stage-statuses without .removed
        allKeysWithoutRemoved() {
            const removeArray = []

            _.forEach(this.tabs, (tab) => {
                if (tab.tabs && _.isArray(tab.tabs)) {
                    _.forEach(tab.tabs, (subTab) => {
                        if (!subTab.key.endsWith('.removed')) {
                            removeArray.push(subTab.key)
                        }
                    })
                }
            })

            return removeArray
        },
    },

    created() {
        this.fetchCampaignWorkflowData(this.$route.params.campaign_id)
        this.setActiveTab('everyone')
    },

    methods: {
        setFilterSearchText(text) {
            // if this.activeTab.filters contains search-text, set search-text value, else push new search-text object
            const searchFilter = _.find(this.activeTab.filters, { id: 'search-text' })
            if (searchFilter) {
                searchFilter.value = text
            } else {
                this.activeTab.filters.push({
                    id: 'search-text',
                    value: text,
                })
            }
        },

        debounceSearch: _.debounce(function () {
            // Prevent execution if the text box hasn't changed
            if (this.state.search.lastText === this.state.search.searchText) return

            nextTick(() => {
                this.state.search.lastText = this.state.search.searchText

                // no isInputValid check as we want empty strings to filter nothing
                this.setFilterSearchText(this.state.search.searchText.trim())
                this.onFilterChange()
            })
        }, 500),

        getTabRecordCount(key) {
            const recordCount = _.get(this.summary, key)
            if (!recordCount) {
                return 0
            }
            return recordCount
        },

        getTab(key) {
            return _.get(this.tabs, key)
        },

        getTabIndex(tabIndex) {
            if (tabIndex === 'X') {
                return 'X'
            }

            return tabIndex + 1
        },

        isTabSelected(key) {
            return this.activeTab.key === key || this.activeTab.key.split('.')[0] === key
        },

        getParentTab(key) {
            return this.tabs[key.split('.')[0]]
        },

        setActiveTab(key) {
            this.paging.currentPage = 1

            const keyParts = key.split('.')

            if (keyParts.length === 1) {
                this.activeTab = _.get(this.tabs, key)
            } else {
                this.activeTab = _.find(this.tabs[keyParts[0]].tabs, (item) => item.key === key)
            }

            this.state.search.searchText = ''
            this.setFilterSearchText('')

            this.activeParentTab = _.get(this.tabs, keyParts[0])
            if (keyParts[1]) this.activeChildTab = this.activeTab
            else this.activeChildTab = null

            this.onFilterChange()
        },

        getSummary(campaignId) {
            return new Promise((resolve, reject) => {
                this.$axios
                    .get(`/supercampaigns/${this.supercampaign.id}/campaigns/${campaignId}/participants/summary`)
                    .then(({ data }) => {
                        resolve(data.payload.summary)
                    })
                    .catch((error) => {
                        console.error(`Error fetching campaign summary`, error)
                        reject(error)
                    })
            })
        },

        getFilteredParticipants(campaignId) {
            const body = {
                paging: {
                    perPage: this.paging.perPage,
                    currentPage: this.paging.currentPage,
                    sort: {
                        sortBy: this.paging.sortby,
                        sortDir: this.paging.sortdir,
                    },
                    filters: this.activeTab.filters,
                },
            }

            const endpoint = `/supercampaigns/${this.supercampaign.id}/campaigns/${campaignId}/participants/find`

            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', endpoint)

            this.$axios
                .post(endpoint, body)
                .then(({ data }) => {
                    const disabledStages = Object.keys(this.stageSettings).filter(
                        (key) => this.stageSettings[key].is_disabled,
                    )

                    this.creators = data.payload.creators.filter(
                        (creator) => !disabledStages.includes(creator.participant.stage),
                    )

                    this.paging.perPage = data.payload.pager.perPage
                    this.paging.totalRecords = data.payload.pager.totalRecords
                    this.paging.totalPages = Math.ceil(this.paging.totalRecords / this.paging.perPage)
                })
                .catch((error) => {
                    console.error(`Error fetching campaign participants`, error)
                })
        },

        onPagingChange(updatedPaging) {
            _.assign(this.paging, updatedPaging)
            this.onFilterChange()
        },

        onFilterChange() {
            this.getFilteredParticipants(this.$route.params.campaign_id)
        },

        onReload() {
            this.getFilteredParticipants(this.$route.params.campaign_id)
            this.fetchCampaignWorkflowData(this.$route.params.campaign_id)
        },

        getCampaignStageSettings(campaignId) {
            return new Promise((resolve, reject) => {
                this.$axios
                    .get(`/campaigns/${campaignId}/settings/all`)
                    .then(({ data }) => resolve(data.payload))
                    .catch((error) => {
                        console.error(`Error fetching campaign settings`, error)
                        reject(error)
                    })
            })
        },

        async getValidation(campaignId) {
            this.validation = null
            const validationResult = await campaignAPI.validateCampaign(
                this.$axios,
                this.supercampaign.id,
                campaignId,
                ['has-task-constraint'],
            )
            if (validationResult.success) {
                this.validation = validationResult.value
            }
        },

        async fetchCampaignWorkflowData(campaignId) {
            try {
                const [summary, allSettings] = await Promise.all([
                    this.getSummary(campaignId),
                    this.getCampaignStageSettings(campaignId),
                    this.getValidation(campaignId, campaignId),
                ])
                this.summary = summary
                this.stageSettings = allSettings.stage_settings
                this.settings = allSettings.settings
                this.applyStageSettings()
                this.state.isLoaded = true
            } catch (error) {
                console.error(`Error fetching campaign data`, error)
            }
        },

        applyStageSettings() {
            const actionProspectApproved = _.find(this.actions, (action) => action.visibilityId === 'prospect-approved')

            const actionProspectOutreachSend = _.find(
                this.actions,
                (action) => action.visibilityId === 'prospect-send-outreach',
            )

            const actionProspectOutreachSent = _.find(
                this.actions,
                (action) => action.visibilityId === 'prospect-last-contacted',
            )

            const actionProspectOutreachSentUpdate = _.find(
                this.actions,
                (action) => action.visibilityId === 'prospect-last-contacted-update',
            )

            const actionProspectOutreachReceived = _.find(
                this.actions,
                (action) => action.visibilityId === 'prospect-outreach-response',
            )

            const actionDeliveryPaid = _.find(this.actions, (action) => action.visibilityId === 'delivery-paid')
            const actionDeliveryComplete = _.find(this.actions, (action) => action.visibilityId === 'delivery-complete')

            if (this.stageSettings?.prospect.is_approval_disabled === true) {
                actionProspectApproved.status = []
                actionProspectOutreachSend.status = ['prospect.started']
                actionProspectOutreachSent.status = ['prospect.started']
                actionProspectOutreachSentUpdate.status = ['prospect.inquiry-sent']
                actionProspectOutreachReceived.status = ['prospect.inquiry-sent']
            } else {
                actionProspectApproved.status = ['prospect.started']
                actionProspectOutreachSend.status = ['prospect.approved']
                actionProspectOutreachSent.status = ['prospect.approved']
                actionProspectOutreachSentUpdate.status = ['prospect.inquiry-sent']
                actionProspectOutreachReceived.status = ['prospect.inquiry-sent']
            }

            const actionDeliveryContentApproval = _.find(
                this.actions,
                (action) => action.visibilityId === 'delivery-content-approved',
            )

            const actionDeliveryContentAssociated = _.find(
                this.actions,
                (action) => action.visibilityId === 'delivery-content-associated',
            )

            if (this.stageSettings?.delivery.is_approval_disabled === true) {
                actionDeliveryContentApproval.status = []
                actionDeliveryContentAssociated.status = ['delivery.started']
            } else {
                actionDeliveryContentApproval.status = ['delivery.started']
                actionDeliveryContentAssociated.status = ['delivery.content-approved']
            }

            if (this.stageSettings.pricing.is_disabled === true) {
                actionDeliveryPaid.status = []
                actionDeliveryComplete.status = ['delivery.paid', 'delivery.content-associated']
            } else {
                actionDeliveryPaid.status = ['delivery.content-associated']
                actionDeliveryComplete.status = ['delivery.paid']
            }

            const sentOutreachValidation = _.find(this.validation?.mandatory?.results, (result) => {
                return result.rule.id === 'has-task-constraint'
            })

            if (sentOutreachValidation?.valid === false) {
                actionProspectOutreachSend.disabled = true
                actionProspectOutreachSend.disabledReason = this.getFromDictionary(
                    `campaign.validation.${sentOutreachValidation.rule.id}`,
                    'invalid',
                )
                console.log(actionProspectOutreachSend.disabledReason)
            } else {
                actionProspectOutreachSend.disabled = false
            }
        },
    },
}
</script>
