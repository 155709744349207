<template>
    <ModalBasic
        title="Subscription update"
        :modal-open="state.modals.confirm.isOpen"
        width="medium"
        @close-modal="onCancelSwitchPlan"
    >
        <template v-if="!state.modals.confirm.isUpdating && state.modals.confirm.isOpen">
            <div class="p-6">
                <div>You are about to update your subscription to the following:</div>
                <div class="pb-3 pt-3">
                    <div v-for="change in state.modals.confirm.changes.summary" :key="change" class="font-bold">
                        {{ change.quantity == 1 ? '' : `${change.quantity} units, ` }}
                        {{ getFromDictionary(`products.${change.product.productType}`, 'label') }}
                        {{ $filters.formatCurrency(change.price.amount * change.quantity) }} billed
                        {{ change.price.billingInterval === 'month' ? 'monthly' : 'annually' }}
                    </div>
                </div>
                <div>Are you sure you want to change?</div>
                <div class="pt-3 text-sm italic">
                    Please note, upgrades will take effect immediately and downgrades will occur on your next billing
                    date.
                </div>
            </div>
            <div class="border-t border-slate-200 px-5 py-4">
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="onCancelSwitchPlan()"
                    >
                        Close
                    </button>
                    <button
                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="onConfirmChangeSubscription"
                    >
                        Update subscription
                    </button>
                </div>
            </div>
        </template>

        <template v-if="state.modals.confirm.isUpdating && state.modals.confirm.isOpen">
            <div class="p-6">Please wait a few moments while we make the changes to your subscription...</div>
        </template>
    </ModalBasic>

    <div>
        <div
            v-if="isSfStaff || ['admin', 'superadmin'].includes(selfActiveOrganization?.organization_operator?.role)"
            class="flex-col divide-y rounded-sm border border-slate-100 border-slate-200 bg-white px-6 py-6"
        >
            <!-- Page Header -->
            <div class="flex justify-between pb-12 pt-6">
                <div>
                    <h2 class="text-1xl font-bold text-slate-800 md:text-2xl">Subscription Management</h2>
                    <div class="text-sm">
                        <p>Manage your organization's subscription.</p>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    !state.isSubscriptionsLoading &&
                    subscriptionsWithScheduledChanges &&
                    subscriptionsWithScheduledChanges.length > 0
                "
                class="py-6"
            >
                <div class="text p-3 font-bold">
                    {{ getFromDictionary(`subscriptions.schedule`, 'label') }}
                </div>
                <div class="ml-3 flex">
                    <div
                        v-for="subscription in subscriptionsWithScheduledChanges"
                        :key="subscription"
                        class="border p-3"
                    >
                        <template v-if="subscription.schedule.nextPhase.products.length === 0">
                            Your subscription is scheduled to cancel on
                            <span class="font-bold">{{
                                $filters.formatDateAndTime(subscription.schedule.nextPhase.startDate)
                            }}</span>
                        </template>
                        <template v-else>
                            Your subscription is scheduled to change on
                            <span class="font-bold">{{
                                $filters.formatDateAndTime(subscription.schedule.nextPhase.startDate)
                            }}</span>
                            to the following (billed <b>monthly</b>):
                            <ul class="ml-1 mt-1">
                                <li
                                    v-for="product in subscription.schedule.nextPhase.products"
                                    :key="product"
                                    class="font-bold"
                                >
                                    ({{ product.quantity }})
                                    {{ getFromDictionary(`products.${product.product.productType}`, 'label') }}
                                </li>
                            </ul>
                        </template>
                        <div class="flex justify-end">
                            <div class="btn-sm ml bg-indigo-500 text-white hover:bg-indigo-600">
                                <button @click="onCancelChanges(subscription.id)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!state.isSubscriptionsLoading && subscriptions && subscriptions.length > 0" class="py-6">
                <div class="text p-3 font-bold">
                    {{ getFromDictionary(`subscriptions.subscribed`, 'label') }}
                </div>

                <div class="">
                    <SubscriptionsTable
                        :is-subscriptions-loading="state.isSubscriptionsLoading"
                        :subscriptions="subscriptions"
                        :product-prices="getAvailableProducts"
                        :has-scheduled-changes="subscriptionsWithScheduledChanges.length > 0"
                        @manage-subscription="onManageSubscription"
                        @change-subscription="onChangeSeats"
                    />
                </div>
            </div>

            <div v-if="!state.isProductsLoading && products && products.length > 0" class="py-6">
                <div class="text p-3 font-bold">
                    {{ getFromDictionary(`subscriptions.products`, 'label') }}
                </div>
                <div class="mb-3 ml-6 flex">
                    <div class="form-switch">
                        <input
                            id="billingToggle"
                            v-model="billingToggle.value"
                            type="checkbox"
                            class="sr-only"
                            @change="onToggleBillingPeriod()"
                        />
                        <label class="bg-slate-400" for="billingToggle">
                            <span class="bg-white shadow-sm" aria-hidden="true"></span>
                            <span class="sr-only">Toggle</span>
                        </label>
                    </div>
                    <div v-if="billingToggle.interval === 'month'" class="ml-2 text-sm italic">Billed monthly</div>
                    <div v-if="billingToggle.interval === 'year'" class="ml-2 text-sm italic">Billed annually</div>
                </div>
                <PlanProductsTable
                    :is-subscriptions-loading="state.isProductsLoading"
                    :product-prices="getAvailableProducts"
                    :active-subscription="activeSubscription"
                    :has-scheduled-changes="subscriptionsWithScheduledChanges.length > 0"
                    @purchase="onPurchase"
                    @switch-plan="onShowConfirmSwitch"
                />
            </div>

            <div
                v-if="
                    !state.isProductsLoading &&
                    (!subscriptions.subscribed || subscriptions.subscribed.length === 0) &&
                    (!subscriptions || subscriptions.length === 0)
                "
                class="flex"
            >
                <div class="ml-auto mr-auto mt-6">
                    <p>No subscriptions currently available</p>
                </div>
            </div>

            <div v-if="state.isProductsLoading || state.isSubscriptionsLoading" class="flex">
                <div class="ml-auto mr-auto mt-6">
                    <p>Retrieving products & subscriptions...</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import ModalBasic from '@/components/ModalBasic.vue'
import SubscriptionsTable from '../partials/tables/subscriptions/SubscriptionsTable.vue'
import PlanProductsTable from '../partials/tables/subscriptions/PlanProductsTable.vue'
import subscriptionsAPI from '../utils/api/api.subscriptions'

export default {
    name: 'OrganizationSubscriptionsSubpage',

    components: {
        SubscriptionsTable,
        PlanProductsTable,
        ModalBasic,
    },

    props: {
        organization: {
            type: Object,
        },
    },

    data() {
        return {
            subscriptions: [],
            products: [],
            state: {
                isSubscriptionsLoading: true,
                isProductsLoading: true,
                modals: {
                    confirm: {
                        isOpen: false,
                        isUpdating: false,
                        changes: {},
                    },
                },
            },
            billingToggle: {
                interval: 'year',
                value: true,
            },
            activeSubscription: {
                hasSubscription: false,
                subscriptionBillingInterval: '',
                isCustom: false,
                rank: 0,
                hasEndingSubscription: false,
                prices: [],
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'selfActiveOrganization']),
        getAvailableProducts() {
            const availableProducts = []

            _.forEach(this.products, (product) => {
                _.forEach(product.prices, (productPrice) => {
                    if (
                        productPrice.billingInterval === this.billingToggle.interval &&
                        (!this.activeSubscription.hasSubscription ||
                            !this.activeSubscription.prices.includes(productPrice.id))
                        /* ||
                        (product.productType === 'trial' &&
                            this.activeSubscription.hasSubscription &&
                            !this.state.isSubscriptionsLoading) */
                    ) {
                        availableProducts.push({
                            product,
                            price: productPrice,
                        })
                    }
                })
            })

            return _.orderBy(availableProducts, ['price.amount'], ['asc'])
        },
        subscriptionsWithScheduledChanges() {
            return _.filter(this.subscriptions, (subscription) => {
                return subscription.schedule
            })
        },
    },

    created() {
        this.reloadSubscriptions()
    },

    methods: {
        async reloadSubscriptions() {
            this.state.isSubscriptionsLoading = true
            this.state.isProductsLoading = true
            this.getOrganizationSubscriptions()
        },

        async getOrganizationSubscriptions() {
            const availableResult = await subscriptionsAPI.getOrganizationAvailable(this.$axios)
            const subscribedResult = await subscriptionsAPI.getOrganizationSubscriptions(this.$axios)

            this.subscriptions = []
            this.products = []

            if (availableResult.success) {
                this.products = availableResult.value
            }

            if (subscribedResult.success) {
                this.subscriptions = subscribedResult.value.subscriptions
            }

            this.activeSubscription.hasSubscription = false
            this.activeSubscription.isCustom = false
            this.activeSubscription.hasEndingSubscription = false
            this.activeSubscription.subscriptionBillingInterval = ''
            this.activeSubscription.subscriptions = []
            this.activeSubscription.rank = 0
            this.activeSubscription.prices = []

            if (this.subscriptions.length === 0) {
                this.subscriptions.push({
                    price: null,
                    products: [
                        {
                            product: {
                                productType: 'trial',
                            },
                        },
                    ],
                    status: 'active',
                })
            } else {
                this.activeSubscription.hasSubscription = true

                let currentBillingCycle = null

                _.forEach(this.subscriptions, (subscription) => {
                    this.activeSubscription.subscriptions.push(subscription)

                    if (subscription.status === 'ending') {
                        this.activeSubscription.hasEndingSubscription = true
                    }

                    _.forEach(subscription.products, (product) => {
                        if (product.price) {
                            if (product.price.billingInterval === 'month') {
                                currentBillingCycle = 'month'
                            } else if (product.price.billingInterval === 'year') {
                                currentBillingCycle = 'year'
                            }

                            if (product.price.selfManaged !== true) {
                                this.activeSubscription.isCustom = true
                            }

                            this.activeSubscription.prices.push(product.price.id)

                            const rank = this.getFromDictionary(`products.${product.product.productType}`, 'rank')
                            if (rank && rank > this.activeSubscription.rank) {
                                this.activeSubscription.rank = rank
                            }
                        }
                    })
                })

                if (currentBillingCycle === 'month') {
                    this.billingToggle.value = false
                    this.activeSubscription.interval = 'month'
                } else {
                    this.billingToggle.value = true
                    this.activeSubscription.interval = 'year'
                }

                this.onToggleBillingPeriod()
            }

            this.state.isSubscriptionsLoading = false
            this.state.isProductsLoading = false

            this.resetModals()
        },

        async onManageSubscription(subscription) {
            const manageUrl = await subscriptionsAPI.getManageUrl(this.$axios, subscription.product.provider)
            if (manageUrl.success && manageUrl.value) {
                window.location.href = manageUrl.value
            }
        },

        onShowConfirmSwitch(productPrice, additionalProducts) {
            this.state.modals.confirm.isOpen = true

            // Find plan
            const activeWithPlan = []
            const cancelledWithPlan = []

            _.forEach(this.subscriptions, (subscription) => {
                let hasValidPlan = false
                _.forEach(subscription.products, (subscriptionProduct) => {
                    if (
                        ['trial', 'starter', 'growth', 'scale', 'enterprise'].includes(
                            subscriptionProduct.product.productType,
                        )
                    ) {
                        hasValidPlan = true
                    }
                })

                if (hasValidPlan && subscription.status === 'active') {
                    activeWithPlan.push(subscription)
                } else if (hasValidPlan) {
                    cancelledWithPlan.push(subscription)
                }
            })

            let subscriptionToAssociateWith = null

            if (activeWithPlan.length > 0) {
                subscriptionToAssociateWith = activeWithPlan[0]
            } else if (cancelledWithPlan.length > 0) {
                subscriptionToAssociateWith = cancelledWithPlan[0]
            }

            if (subscriptionToAssociateWith) {
                let subscriptionProducts = []
                subscriptionProducts.push({
                    priceId: productPrice.price.id,
                    quantity: 1,
                })
                subscriptionProducts = subscriptionProducts.concat(additionalProducts)

                this.state.modals.confirm.changes = {
                    subscriptionId: subscriptionToAssociateWith.id,
                    products: subscriptionProducts,
                }
                this.createChangeSummary()
            } else {
                // Handle
                this.state.modals.confirm.isOpen = false
            }
        },

        createChangeSummary() {
            this.state.modals.confirm.changes.summary = []
            _.forEach(this.state.modals.confirm.changes.products, (changeProduct) => {
                let priceProduct = null

                _.forEach(this.products, (product) => {
                    _.forEach(product.prices, (productPrice) => {
                        if (productPrice.id === changeProduct.priceId) {
                            priceProduct = {
                                product,
                                price: productPrice,
                            }
                        }
                    })
                })

                _.forEach(this.subscriptions, (subscription) => {
                    _.forEach(subscription.product, (product) => {
                        if (product.price.id === changeProduct.priceId) {
                            priceProduct = {
                                product,
                                price: product.price,
                            }
                        }
                    })
                })

                if (priceProduct) {
                    this.state.modals.confirm.changes.summary.push({
                        billingInterval: '',
                        quantity: changeProduct.quantity,
                        price: priceProduct.price,
                        product: priceProduct.product,
                    })
                }
            })
        },

        async onCancelSwitchPlan() {
            this.state.modals.confirm.changes = {}
            this.state.modals.confirm.isOpen = false
        },

        async onChangeSeats(subscription, relatedPriceId, relatedcount) {
            this.state.modals.confirm.isOpen = true
            const subscriptionProducts = []

            subscriptionProducts.push({
                priceId: subscription.price.id,
                quantity: 1,
            })

            if (relatedPriceId) {
                subscriptionProducts.push({
                    priceId: relatedPriceId,
                    quantity: relatedcount,
                })
            }

            this.state.modals.confirm.changes = {
                subscriptionId: subscription.subscription.id,
                products: subscriptionProducts,
            }
            this.createChangeSummary()
        },

        async onConfirmChangeSubscription() {
            await this.updateSubscription(
                this.state.modals.confirm.changes.subscriptionId,
                this.state.modals.confirm.changes.products,
            )
        },

        async updateSubscription(subscriptionId, subscriptionProducts) {
            this.state.modals.confirm.isUpdating = true
            await subscriptionsAPI.changeSubscription(this.$axios, subscriptionId, subscriptionProducts)
            setTimeout(() => {
                this.reloadSubscriptions()
            }, 3000)
        },

        async onPurchase(productPrice, additionalProducts) {
            const purchaseUrl = await subscriptionsAPI.getPurchaseUrl(
                this.$axios,
                productPrice.price.id,
                additionalProducts,
            )
            if (purchaseUrl.success && purchaseUrl.value) {
                window.location.href = purchaseUrl.value
            }
        },

        async onCancelChanges(subscriptionId) {
            this.state.modals.confirm.isUpdating = true
            await subscriptionsAPI.cancelSubscriptionChanges(this.$axios, subscriptionId)
            this.reloadSubscriptions()
        },

        onToggleBillingPeriod() {
            if (this.billingToggle.value) {
                this.billingToggle.interval = 'year'
            } else {
                this.billingToggle.interval = 'month'
            }
        },

        resetModals() {
            this.state.modals.confirm.isOpen = false
            this.state.modals.confirm.isUpdating = false
            this.state.modals.confirm.changes = {}
        },
    },
}
</script>
