<template>
    <div class="flex flex-col bg-white h-full w-full overflow-auto">
        <div class="flex w-full items-center justify-between px-4 py-6 sm:px-6 lg:px-8">
            <router-link :to="{ path: '/' }" class="mx-auto">
                <Logo variant="dark" class="!w-52" :has-text="true" />
            </router-link>
        </div>

        <div class="flex grow mx-auto">
            <div class="flex w-auto h-auto xl:py-24 xl:mx-auto">
                <div class="mx-auto w-full md:w-auto flex rounded-lg md:border border-solid border-slate-200">

                    <div class="flex flex-col xl:flex-row">

                        <!-- Left Side -->
                        <div class="flex h-full w-full flex-col order-2 xl:order-1 mx-auto">
                            <div class="flex xl:h-full flex-col justify-center xl:px-20 px-6 box-content xl:w-[392px] xl:my-auto">
                                <template v-if="state.isCodeLoading === true">
                                    <div class="flex w-full">
                                        <font-awesome-icon
                                            :icon="['fas', 'spinner-third']"
                                            class="m-auto h-16 w-16 animate-spin"
                                        />
                                    </div>
                                </template>

                                <template v-else>
                                    <template v-if="state.isCodeValid === true">
                                        <div
                                            v-if="state.errors?.length > 0"
                                            class="mb-5 w-full rounded border border-red-200 bg-red-100 px-4 py-3"
                                        >
                                            <div class="mb-2 text-sm font-medium text-slate-900">{{ state.error_message }}</div>

                                            <ul class="list-disc px-5 text-slate-700">
                                                <li v-for="error in state.errors" :key="error" class="text-xs">
                                                    {{ error }}
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="mb-3">
                                            <label class="mb-1 block text-xs font-medium" for="email">Email Address</label>

                                            <input
                                                id="email"
                                                v-model="form_data.email"
                                                class="form-input w-full shadow-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                                                type="email"
                                                autoComplete="off"
                                                disabled
                                            />
                                        </div>

                                        <div class="mb-3">
                                            <label class="mb-1 block text-xs font-medium" for="email">Password</label>

                                            <input
                                                id="password"
                                                v-model="form_data.password"
                                                class="form-input w-full shadow-none"
                                                type="password"
                                                autoComplete="off"
                                                @keyup.enter="validatePassword"
                                            />
                                        </div>

                                        <div class="mb-3">
                                            <label class="mb-1 block text-xs font-medium" for="email">Confirm Password</label>

                                            <input
                                                id="confirm_password"
                                                v-model="form_data.confirm_password"
                                                class="form-input w-full shadow-none"
                                                type="password"
                                                autoComplete="off"
                                            />
                                        </div>

                                        <div class="mt-3 w-full">
                                            <button
                                                class="btn w-full bg-indigo-500 text-white shadow-none hover:bg-indigo-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                                                title="Please enter a valid business email address"
                                                :disabled="
                                                    state.isRegistrationLoading ||
                                                    form_data.password === '' ||
                                                    form_data.confirm_password === ''
                                                "
                                                @click="completeRegistration"
                                            >
                                                <font-awesome-icon
                                                    v-if="state.isRegistrationLoading"
                                                    :icon="['fas', 'spinner-third']"
                                                    class="mr-2 h-4 w-4 animate-spin"
                                                />
                                                <span>Continue</span>
                                            </button>
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div class="my-auto">Your verification email code is invalid or expired.</div>
                                    </template>
                                </template>
                            </div>
                        </div>

                        <!-- Right Side -->
                        <div class="flex flex-col w-full order-1 xl:order-2 mx-auto xl:mb-0 py-6">
                            <div
                                class="flex box-content xl:w-[392px] flex-col items-start justify-start gap-4 xl:px-20 my-auto px-6"
                            >
                                <div class="font-inter text-[24px] font-semibold leading-7 tracking-tight text-gray-900">
                                    Welcome to Streamforge.
                                </div>
                                <p class="font-inter text-sm font-medium leading-5 tracking-tight text-gray-500">
                                    Thanks for confirming your email address!<br /><br />Next, set a password and continue onboarding to complete your profile and organization setup.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Logo from '@/components/Logo.vue'

export default {
    name: 'RegisterVerifyPage',

    metaInfo: {
        title: 'Email Verification',
    },

    components: {
        Logo,
    },

    data() {
        return {
            form_data: {
                email: '',
                password: '',
                confirm_password: '',
            },

            state: {
                isRegistrationLoading: false,
                isCodeLoading: true,
                isCodeValid: false,
                error_message: null,
                errors: [],
            },
        }
    },

    async created() {
        this.state.isCodeLoading = true

        const { code } = this.$route.query

        if (code) {
            try {
                const { data } = await this.$axios.post(`/operators/register/check`, {
                    code,
                })

                this.form_data.email = data.payload?.operator?.email
                this.state.isCodeValid = true
            } catch (error) {
                console.error(`Error checking registration code`)
                this.state.isCodeValid = false
            } finally {
                this.state.isCodeLoading = false
            }
        }
    },

    methods: {
        async completeRegistration() {
            const { code, type } = this.$route.query
            this.state.isRegistrationLoading = true

            this.state.error_message = null
            this.state.errors = []

            if (this.form_data.password !== this.form_data.confirm_password) {
                this.state.error_message = 'Provided passwords do not match.'
                this.state.errors = ['Provided passwords do not match.']
                this.state.isRegistrationLoading = false
                return
            }

            try {
                const { data } = await this.$axios.post(`/operators/register/password`, {
                    code,
                    password: this.form_data.password,
                })

                this.$store.commit('authentication:login', decodeURIComponent(data.payload.jwt))

                this.$nextTick(() => {
                    this.$router.push({
                        name: 'onboarding',
                        query: {
                            type,
                            code,
                        },
                    })
                })
            } catch (error) {
                console.error(`Error checking registration code`, error.response.data)
                this.state.error_message = error.response.data.message

                if (error.response.data.errors) {
                    this.state.errors = error.response.data.errors
                }
            } finally {
                this.state.isRegistrationLoading = false
            }
        },
    },
}
</script>