import _ from 'lodash'
import codeTools from '../codes'

const targetGroups = {
    createTwitchOptions() {
        return {
            options: {
                industry: null,
                industry_category: null,
                day_range: 365,
                content_count: {
                    min: 0,
                },
                languages: [],
                countries: [],
                tags: {
                    values: [],
                },
                avg_viewers: {
                    min: 0,
                    max: 1_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                affinity: {
                    game: {
                        min: 0,
                        max: 1,
                    },
                },
                games: {
                    min: 0,
                    // max: 10000,
                    values: [],
                },
                matched_games: {
                    min: 1,
                },
                not_games: {
                    values: [],
                },
                metadata: {
                    email: {},
                    location: {},
                    type: [],
                },
                demographics: {
                    os: {
                        values: [],
                    },
                    device: {
                        values: [],
                    },
                    language: {
                        values: [],
                    },
                    country: {
                        values: [],
                    },
                },
                categories: {
                    values: [],
                },
                categories_affinity: {
                    values: [],
                },
                not_categories: {
                    values: [],
                },

                interests: {
                    values: [],
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
            default: {
                industry: null,
                industry_category: null,
                content_type: 'stream',
                content_count: {
                    min: 0,
                },
                day_range: 365,
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_viewers: {
                    min: 0,
                    max: 1_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                affinity: {
                    game: {
                        min: 0,
                        max: 1,
                    },
                },
                games: {
                    min: 0,
                    // max: 10000,
                    values: [],
                    conjunction: '$or',
                },
                matched_games: {
                    min: 1,
                },
                not_games: {
                    values: [],
                    conjunction: '$notall',
                },
                metadata: {
                    email: false,
                    location: false,
                    type: [
                        // {
                        //     id: '64666b70cac68ee201c026c4',
                        //     name: 'individual',
                        //     label: 'Individual',
                        //     value: 'individual',
                        // },
                    ],
                },
                demographics: {
                    os: {
                        values: [],
                        conjunction: '$or',
                    },
                    device: {
                        values: [],
                        conjunction: '$or',
                    },
                    language: {
                        values: [],
                        conjunction: '$or',
                    },
                    country: {
                        values: [],
                        conjunction: '$or',
                    },
                },
                categories: {
                    values: [],
                    conjunction: '$or',
                },
                categories_affinity: {
                    values: [],
                    conjunction: '$or',
                },
                not_categories: {
                    values: [],
                    conjunction: '$notall',
                },

                interests: {
                    values: [],
                    conjunction: '$or',
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
        }
    },

    createYoutubeOptions() {
        return {
            options: {
                industry: null,
                industry_category: null,
                content_type: ['video', 'short', 'stream'],
                content_count: {
                    min: 0,
                },
                day_range: 365,
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_views: {
                    min: 0,
                    max: 10_000_000,
                },
                subscribers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                affinity: {
                    game: {
                        min: 0,
                        max: 1,
                    },
                },
                games: {
                    min: 0,
                    // max: 10000,
                    values: [],
                },
                matched_games: {
                    min: 1,
                },
                not_games: {
                    values: [],
                },
                metadata: {
                    email: {},
                    location: {},
                    type: [],
                },
                categories: {
                    values: [],
                },
                categories_affinity: {
                    values: [],
                },
                not_categories: {
                    values: [],
                },
                interests: {
                    values: [],
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
            default: {
                industry: null,
                industry_category: null,
                content_type: 'video',
                content_count: {
                    min: 0,
                },
                day_range: 365,
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_views: {
                    min: 0,
                    max: 10_000_000,
                },
                subscribers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                affinity: {
                    game: {
                        min: 0,
                        max: 1,
                    },
                },
                games: {
                    min: 0,
                    // max: 10000,
                    values: [],
                    conjunction: '$or',
                },
                matched_games: {
                    min: 1,
                },
                not_games: {
                    values: [],
                    conjunction: '$notall',
                },
                metadata: {
                    email: false,
                    location: false,
                    type: [
                        // {
                        //     id: '64666b70cac68ee201c026c4',
                        //     name: 'individual',
                        //     label: 'Individual',
                        //     value: 'individual',
                        // },
                    ],
                },
                categories: {
                    values: [],
                    conjunction: '$or',
                },
                categories_affinity: {
                    values: [],
                    conjunction: '$or',
                },
                not_categories: {
                    values: [],
                    conjunction: '$notall',
                },

                interests: {
                    values: [],
                    conjunction: '$or',
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
        }
    },

    createTiktokOptions() {
        return {
            options: {
                industry: null,
                industry_category: null,
                day_range: 365,
                content_count: {
                    min: 0,
                },
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_views: {
                    min: 0,
                    max: 10_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                // affinity: {
                //     game: {
                //         min: 0,
                //         max: 1,
                //     },
                // },
                // games: {
                //     // min: 1,
                //     // max: 10000,
                //     values: [],
                // },
                // matched_games: {
                //     min: 1,
                // },
                // not_games: {
                //     values: [],
                // },
                metadata: {
                    email: {},
                    location: {},
                    type: [],
                },

                interests: {
                    values: [],
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
            default: {
                industry: null,
                industry_category: null,
                content_type: 'video',
                content_count: {
                    min: 0,
                },
                day_range: 365,
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_views: {
                    min: 0,
                    max: 10_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                // affinity: {
                //     game: {
                //         min: 0,
                //         max: 1,
                //     },
                // },
                // games: {
                //     // min: 1,
                //     // max: 10000,
                //     values: [],
                //     conjunction: '$or',
                // },
                // matched_games: {
                //     min: 1,
                // },
                // not_games: {
                //     values: [],
                //     conjunction: '$notall',
                // },
                metadata: {
                    email: false,
                    location: false,
                    type: [
                        // {
                        //     id: '64666b70cac68ee201c026c4',
                        //     name: 'individual',
                        //     label: 'Individual',
                        //     value: 'individual',
                        // },
                    ],
                },

                interests: {
                    values: [],
                    conjunction: '$or',
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
        }
    },

    createTwitterOptions() {
        return {
            options: {
                industry: null,
                industry_category: null,
                day_range: 365,
                content_count: {
                    min: 0,
                },
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_views: {
                    min: 0,
                    max: 10_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                metadata: {
                    email: {},
                    location: {},
                    type: [],
                },

                interests: {
                    values: [],
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
            default: {
                industry: null,
                industry_category: null,
                content_type: 'post',
                content_count: {
                    min: 0,
                },
                day_range: 365,
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_views: {
                    min: 0,
                    max: 10_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                metadata: {
                    email: false,
                    location: false,
                    type: [
                        // {
                        //     id: '64666b70cac68ee201c026c4',
                        //     name: 'individual',
                        //     label: 'Individual',
                        //     value: 'individual',
                        // },
                    ],
                },

                interests: {
                    values: [],
                    conjunction: '$or',
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
        }
    },

    createInstagramOptions() {
        return {
            options: {
                industry: null,
                industry_category: null,
                day_range: 365,
                content_count: {
                    min: 0,
                },
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_likes: {
                    min: 0,
                    max: 10_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                metadata: {
                    email: {},
                    location: {},
                    type: [],
                },

                interests: {
                    values: [],
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
            default: {
                industry: null,
                industry_category: null,
                content_type: 'post',
                content_count: {
                    min: 0,
                },
                day_range: 365,
                languages: [],
                countries: [],
                tags: {
                    values: [],
                    conjunction: '$or',
                },
                avg_likes: {
                    min: 0,
                    max: 10_000_000,
                },
                followers: {
                    min: 0,
                    max: 1_000_000_000,
                },
                engagement_rate: {
                    min: 0,
                    max: 0.25,
                },
                metadata: {
                    email: false,
                    location: false,
                    type: [
                        // {
                        //     id: '64666b70cac68ee201c026c4',
                        //     name: 'individual',
                        //     label: 'Individual',
                        //     value: 'individual',
                        // },
                    ],
                },

                interests: {
                    values: [],
                    conjunction: '$or',
                },
                contentRatings: [],
                genders: [],
                ageRanges: [],
            },
        }
    },

    mapTargetGroupFromFilters(targetGroup, codeLookup, defaults) {
        let group = {}

        if (targetGroup.platform === 'twitch') {
            group = _.cloneDeep(defaults.twitch)
        } else if (targetGroup.platform === 'youtube') {
            group = _.cloneDeep(defaults.youtube)
        } else if (targetGroup.platform === 'tiktok') {
            group = _.cloneDeep(defaults.tiktok)
        } else if (targetGroup.platform === 'twitter') {
            group = _.cloneDeep(defaults.twitter)
        } else if (targetGroup.platform === 'instagram') {
            group = _.cloneDeep(defaults.instagram)
        }

        group.title = targetGroup.title
        group.id = targetGroup.id
        group.version = targetGroup.version
        group.platform = targetGroup.platform
        group.entityType = targetGroup.entityType
        group.entityId = targetGroup.entityId

        _.forEach(targetGroup.filters, (filter) => {
            switch (filter.id) {
                case 'industry':
                    group.industry = filter.value
                    break

                case 'day_range':
                    group.day_range = filter.value
                    break

                case 'interests':
                    if (group?.interests) {
                        group.interests.values = codeTools.getCodesFromLookup(
                            filter.value,
                            targetGroup.platform,
                            'interest',
                            codeLookup,
                        )
                    }

                    break
                case 'games':
                    if (group?.games) {
                        group.games.values = codeTools.getCodesFromLookup(
                            filter.value,
                            targetGroup.platform,
                            'game',
                            codeLookup,
                        )
                    }

                    break
                case 'games-conjunction':
                    if (group?.games) {
                        group.games.conjunction = filter.value
                    }

                    break
                case 'min-matched-games':
                    if (group?.matched_games) {
                        group.matched_games.min = filter.value
                    }

                    break
                case 'not-games':
                    if (group?.not_games) {
                        group.not_games.values = codeTools.getCodesFromLookup(
                            filter.value,
                            targetGroup.platform,
                            'game',
                            codeLookup,
                        )
                    }
                    break
                case 'not-games-conjunction':
                    if (group?.not_games) {
                        group.not_games.conjunction = filter.value
                    }
                    break
                case 'min-content':
                    group.content_count.min = filter.value
                    break
                case 'tags':
                    group.tags.values = codeTools.getCodesFromLookup(
                        filter.value,
                        targetGroup.platform,
                        'tag',
                        codeLookup,
                    )
                    break
                case 'tags-conjunction':
                    if (group?.tags) {
                        group.tags.conjunction = filter.value
                    }

                    break
                case 'languages':
                    group.languages = codeTools.getCodesFromLookup(
                        filter.value,
                        targetGroup.platform,
                        'language',
                        codeLookup,
                    )
                    break
                case 'countries':
                    group.countries = codeTools.getCodesFromLookup(
                        filter.value,
                        targetGroup.platform,
                        'country',
                        codeLookup,
                    )
                    break
                case 'min-viewers':
                    group.avg_viewers.min = filter.value
                    break
                case 'max-viewers':
                    group.avg_viewers.max = filter.value
                    break
                case 'min-engagement-rate':
                    group.engagement_rate.min = filter.value
                    break
                case 'max-engagement-rate':
                    group.engagement_rate.max = filter.value
                    break
                case 'min-followers':
                    group.followers.min = filter.value
                    break
                case 'max-followers':
                    group.followers.max = filter.value
                    break
                case 'min-game-affinity':
                    console.log('mapTargetGroupFromFilters', 'min-game-affinity', filter.value)

                    if (group?.affinity?.game && group?.affinity?.game?.min > 0) {
                        group.affinity.game.min = filter.value
                    }
                    break
                case 'max-game-affinity':
                    console.log('mapTargetGroupFromFilters', 'max-game-affinity', filter.value)

                    if (group?.affinity?.game && group?.affinity?.game?.max > 0 && group?.affinity?.game?.max < 1) {
                        group.affinity.game.max = filter.value
                    }
                    break
                case 'min-games':
                    if (group?.games) {
                        group.games.min = filter.value
                    }
                    break
                case 'max-games':
                    if (group?.games) {
                        group.games.max = filter.value
                    }
                    break
                case 'has-email':
                    group.metadata.email = filter.value
                    break
                case 'has-location':
                    if (group?.metadata) {
                        group.metadata.location = filter.value
                    }
                    break
                case 'channel-types':
                    if (group?.metadata) {
                        group.metadata.type = codeTools
                            .getCodesFromLookup(filter.value, targetGroup.platform, 'channelType', codeLookup)
                            .map((code) => ({
                                ...code,
                                label: code.name,
                            }))
                    }
                    break
                case 'min-views':
                    group.avg_views.min = filter.value
                    break
                case 'max-views':
                    group.avg_views.max = filter.value
                    break
                case 'min-likes':
                    group.avg_likes.min = filter.value
                    break
                case 'max-likes':
                    group.avg_likes.max = filter.value
                    break
                case 'min-subscribers':
                    group.subscribers.min = filter.value
                    break
                case 'max-subscribers':
                    group.subscribers.max = filter.value
                    break
                case 'content-type':
                    group.content_type = filter.value
                    break
                case 'genders':
                    group.genders = codeTools.getCodesFromLookup(
                        filter.value,
                        targetGroup.platform,
                        'gender',
                        codeLookup,
                    )
                    break
                case 'age-ranges':
                    group.genders = codeTools.getCodesFromLookup(
                        filter.value,
                        targetGroup.platform,
                        'age-range',
                        codeLookup,
                    )
                    break
                case 'categories':
                    group.categories.values = this.loadCodeWithValue2(
                        filter.value,
                        targetGroup.platform,
                        'category',
                        codeLookup,
                    )
                    break
                case 'categories-conjunction':
                    group.categories.conjunction = filter.value
                    break
                case 'not-categories':
                    group.not_categories.values = this.loadCodeWithValue2(
                        filter.value,
                        targetGroup.platform,
                        'category',
                        codeLookup,
                    )
                    break
                case 'not-categories-conjunction':
                    group.not_categories.conjunction = filter.value
                    break
                case 'demographics-os':
                    group.demographics.os.values = this.loadCodeWithValue2(
                        filter.value,
                        targetGroup.platform,
                        'operating-system',
                        codeLookup,
                    )
                    break
                case 'demographics-os-conjunction':
                    group.demographics.os.conjunction = filter.value
                    break
                case 'demographics-country':
                    group.demographics.country.values = this.loadCodeWithValue2(
                        filter.value,
                        targetGroup.platform,
                        'country',
                        codeLookup,
                    )
                    break
                case 'demographics-country-conjunction':
                    group.demographics.country.conjunction = filter.value
                    break
                case 'demographics-device':
                    group.demographics.device.values = this.loadCodeWithValue2(
                        filter.value,
                        targetGroup.platform,
                        'device-type',
                        codeLookup,
                    )
                    break
                case 'demographics-device-conjunction':
                    group.demographics.device.conjunction = filter.value
                    break
                case 'demographics-language':
                    group.demographics.language.values = this.loadCodeWithValue2(
                        filter.value,
                        targetGroup.platform,
                        'language',
                        codeLookup,
                    )
                    break
                case 'demographics-language-conjunction':
                    group.demographics.language.conjunction = filter.value
                    break
                default:
                    break
            }
        })

        return group
    },

    mapFiltersFromTargetGroup(values, options) {
        const filters = []

        if (options.industry && values?.industry) {
            filters.push({ id: 'industry', value: values.industry })
        }

        if (options.day_range && values?.day_range) {
            filters.push({ id: 'day_range', value: values.day_range })
        }
        if (options.content_count && values?.content_count?.min) {
            filters.push({ id: 'min-content', value: values.content_count.min })
        }
        if (options.languages) {
            filters.push({ id: 'languages', value: _.map(values.languages, 'id') })
        }
        if (options.interests && values?.interests?.values) {
            filters.push({ id: 'interests', value: _.map(values.interests.values, 'id') })
        }
        if (options.interests && values?.interests?.conjunction) {
            filters.push({ id: 'interests-conjunction', value: values.interests.conjunction })
        }
        if (options.games && values?.games?.values) {
            filters.push({ id: 'games', value: _.map(values.games.values, 'id') })
        }
        if (options.games && values?.games?.conjunction) {
            filters.push({ id: 'games-conjunction', value: values.games.conjunction })
        }
        if (options.matched_games && values?.matched_games?.min) {
            filters.push({ id: 'min-matched-games', value: values.matched_games.min })
        }
        if (options.not_games && values?.not_games?.values) {
            filters.push({ id: 'not-games', value: _.map(values.not_games.values, 'id') })
        }
        if (options.not_games && values?.not_games?.conjunction) {
            filters.push({ id: 'not-games-conjunction', value: values.not_games.conjunction })
        }
        if (options.avg_viewers && values?.avg_viewers?.min) {
            filters.push({ id: 'min-viewers', value: values.avg_viewers.min })
        }
        if (options.avg_viewers && values?.avg_viewers?.max) {
            filters.push({ id: 'max-viewers', value: values.avg_viewers.max })
        }

        if (options.followers && values?.followers?.min) {
            filters.push({ id: 'min-followers', value: values.followers.min })
        }
        if (options.followers && values?.followers?.max) {
            filters.push({ id: 'max-followers', value: values.followers.max })
        }

        if (options.engagement_rate && values?.engagement_rate?.min) {
            filters.push({ id: 'min-engagement-rate', value: values.engagement_rate.min })
        }
        if (options.engagement_rate && values?.engagement_rate?.max) {
            filters.push({ id: 'max-engagement-rate', value: values.engagement_rate.max })
        }

        if (
            options?.affinity?.game &&
            typeof values?.affinity?.game?.min === 'number' &&
            values?.affinity?.game?.min > 0
        ) {
            console.log('mapFiltersFromTargetGroup', values.affinity.game.min)

            filters.push({ id: 'min-game-affinity', value: values.affinity.game.min })
        }

        if (
            options?.affinity?.game &&
            typeof values?.affinity?.game?.max === 'number' &&
            values?.affinity?.game?.max < 1
        ) {
            console.log('mapFiltersFromTargetGroup', values.affinity.game.max)
            filters.push({ id: 'max-game-affinity', value: values.affinity.game.max })
        }

        if (options?.games && values?.games?.min) {
            filters.push({ id: 'min-games', value: values.games.min })
        }
        if (options?.games && values?.games?.max) {
            filters.push({ id: 'max-games', value: values.games.max })
        }
        if (options?.metadata && values?.metadata?.email) {
            filters.push({ id: 'has-email', value: values.metadata.email })
        }
        if (options?.metadata && values?.metadata?.location) {
            filters.push({ id: 'has-location', value: values.metadata.location })
        }
        if (options?.tags && values?.tags?.values) {
            filters.push({ id: 'tags', value: _.map(values.tags.values, 'id') })
        }
        if (options?.tags && values?.tags?.conjunction) {
            filters.push({ id: 'tags-conjunction', value: values.tags.conjunction })
        }
        if (options?.countries) {
            filters.push({ id: 'countries', value: _.map(values.countries, 'id') })
        }
        if (options?.avg_views && values?.avg_views?.min) {
            filters.push({ id: 'min-views', value: values.avg_views.min })
        }
        if (options?.avg_views && values?.avg_views?.max) {
            filters.push({ id: 'max-views', value: values.avg_views.max })
        }
        if (options?.avg_likes && values?.avg_likes?.min) {
            filters.push({ id: 'min-likes', value: values.avg_likes.min })
        }
        if (options?.avg_likes && values?.avg_likes?.max) {
            filters.push({ id: 'max-likes', value: values.avg_likes.max })
        }
        if (options?.subscribers && values?.subscribers?.min) {
            filters.push({ id: 'min-subscribers', value: values.subscribers.min })
        }
        if (options?.subscribers && values?.subscribers?.max) {
            filters.push({ id: 'max-subscribers', value: values.subscribers.max })
        }
        if (options?.metadata && values?.metadata?.type) {
            filters.push({ id: 'channel-types', value: _.map(values.metadata.type, 'id') })
        }
        if (typeof values.content_type === 'string') {
            filters.push({ id: 'content-type', value: values.content_type })
        }
        if (options?.genders) {
            filters.push({ id: 'genders', value: _.map(values.genders, 'id') })
        }
        if (options?.age_ranges) {
            filters.push({ id: 'age-ranges', value: _.map(values.ageRanges, 'id') })
        }

        if (options.categories) {
            this.appendCodeFilterWithValue2(filters, values.categories, 'categories', 'categories-conjunction')
        }
        if (options.not_categories) {
            this.appendCodeFilterWithValue2(
                filters,
                values.not_categories,
                'not-categories',
                'not-categories-conjunction',
            )
        }

        if (options.demographics && values.demographics) {
            if (options.demographics.os) {
                this.appendCodeFilterWithValue2(
                    filters,
                    values.demographics.os,
                    'demographics-os',
                    'demographics-os-conjunction',
                )
            }
            if (options.demographics.country) {
                this.appendCodeFilterWithValue2(
                    filters,
                    values.demographics.country,
                    'demographics-country',
                    'demographics-country-conjunction',
                )
            }
            if (options.demographics.device) {
                this.appendCodeFilterWithValue2(
                    filters,
                    values.demographics.device,
                    'demographics-device',
                    'demographics-device-conjunction',
                )
            }
            if (options.demographics.language) {
                this.appendCodeFilterWithValue2(
                    filters,
                    values.demographics.language,
                    'demographics-language',
                    'demographics-language-conjunction',
                )
            }
        }

        const mappedGroup = {
            targetGroup: {
                title: values.title,
                filters,
            },
        }

        if (values.id) {
            mappedGroup.targetGroup.id = values.id
        }

        return mappedGroup
    },

    appendCodeFilterWithValue2(filters, values, filterId, conjunctionFilterId) {
        if (!values) {
            return
        }

        if (filterId && values.values) {
            filters.push({
                id: filterId,
                value: _.map(values.values, (value) => {
                    return {
                        id: value.id,
                        value2: value.value2,
                    }
                }),
            })
        }
        if (conjunctionFilterId && values.conjunction) {
            filters.push({ id: conjunctionFilterId, value: values.conjunction })
        }
    },

    loadCodeWithValue2(value, platform, codeType, codeLookup) {
        const ids = _.map(value, (code) => {
            return code.id
        })

        const codesWithValues = codeTools.getCodesFromLookup(ids, platform, codeType, codeLookup)

        _.forEach(codesWithValues, (code) => {
            const matched = _.find(value, (cat) => {
                return cat.id === code.id
            })
            if (matched) {
                code.value2 = matched.value2
            }
        })

        return codesWithValues
    },
}

export default targetGroups
