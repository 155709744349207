<template>
    <div class="space-y-6">
        <!-- Top banner: Plan + Renewal Info -->
        <div class="flex items-center justify-between rounded-lg border bg-background p-4">
            <div>
                <h2 class="text-xl font-semibold">Billing & Usage</h2>
                <p class="text-sm text-muted-foreground">
                    This workspace is currently on Free plan
                    <a href="/plans" class="ml-1 underline">Compare plans</a>
                </p>
            </div>
            <div class="text-right text-sm text-muted-foreground">
                <p>Renews on {{ formatDate(currentBillingPeriod?.ended_at) }}</p>
            </div>
        </div>

        <!-- Plan Usage Breakdown -->
        <Card class="rounded border">
            <CardHeader>
                <CardTitle>Plan usage breakdown</CardTitle>
            </CardHeader>
            <CardContent>
                <div class="grid grid-cols-2 gap-8">
                    <!-- Each quota row -->
                    <div v-for="quota in quotas" :key="quota.id" class="space-y-1">
                        <div class="flex items-center justify-between">
                            <span class="text-sm font-medium">
                                {{ formatQuotaType(quota.type) }}
                            </span>
                            <span class="text-sm text-muted-foreground">
                                {{ $numeral(quota.used_quota).format('0,0') }} /
                                {{ $numeral(quota.allocated_quota).format('0,0') }} included
                            </span>
                        </div>

                        <ProgressBar :model-value="(quota.used_quota / quota.allocated_quota) * 100" class="h-2" />
                    </div>
                </div>
            </CardContent>
        </Card>

        <!-- Daily Usage Chart -->
        <Card class="rounded">
            <CardHeader>
                <div class="flex items-center justify-between">
                    <CardTitle>Daily Usage</CardTitle>
                    <!-- ShadCN Select Component -->
                    <SelectBox v-model="selectedBillingPeriodId" @update:model-value="onBillingPeriodChange">
                        <SelectTrigger class="w-[280px]">
                            <SelectValue placeholder="Select a billing period" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem v-for="period in billingPeriods" :key="period.id" :value="period.id">
                                {{ formatBillingPeriod(period) }}
                            </SelectItem>
                        </SelectContent>
                    </SelectBox>
                </div>
            </CardHeader>
            <CardContent>
                <div class="h-[350px]">
                    <Bar :data="chartData" :options="chartOptions" />
                </div>
            </CardContent>
        </Card>

        <!-- Usage Logs -->
        <Card class="rounded">
            <CardHeader>
                <CardTitle>Usage Logs</CardTitle>
            </CardHeader>
            <CardContent>
                <ShadCNTable class="text-sm">
                    <TableHeader>
                        <TableRow>
                            <TableHead class="px-4">Date</TableHead>
                            <TableHead class="px-4">Type</TableHead>
                            <TableHead class="px-4">User</TableHead>
                            <TableHead class="px-4 text-right">Usage</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow v-for="log in logs" :key="log.id">
                            <TableCell>{{ formatDate(log.created_at) }}</TableCell>
                            <TableCell>{{ formatQuotaType(log.usage_type) }}</TableCell>
                            <TableCell> {{ log?.operator?.first_name }} {{ log?.operator?.last_name }} </TableCell>
                            <TableCell class="text-right">
                                {{ log.quota_consumed }}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </ShadCNTable>
                <!-- Pagination -->
                <div class="mt-4 flex items-center justify-between">
                    <button
                        :disabled="currentPage === 1"
                        class="rounded border px-2 py-1"
                        @click="fetchLogs(currentPage - 1)"
                    >
                        Previous Page
                    </button>
                    <span>Page {{ currentPage }} of {{ totalPages }}</span>
                    <button
                        :disabled="currentPage >= totalPages"
                        class="rounded border px-2 py-1"
                        @click="fetchLogs(currentPage + 1)"
                    >
                        Next Page
                    </button>
                </div>
            </CardContent>
        </Card>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Progress as ProgressBar } from '@/components/ui/progress'
import { Table as ShadCNTable, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Select as SelectBox, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    name: 'OrganizationUsageSubpage',
    components: {
        Card,
        CardContent,
        CardHeader,
        CardTitle,
        ProgressBar,
        ShadCNTable,
        TableBody,
        TableCell,
        TableHead,
        TableHeader,
        TableRow,
        Bar,
        SelectBox,
        SelectContent,
        SelectItem,
        SelectTrigger,
        SelectValue,
    },
    props: {
        organization: {
            type: Object,
        },
    },
    data() {
        return {
            quotas: [],
            logs: [],
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Daily Usage',
                        data: [],
                        backgroundColor: '#0ea5e9',
                        borderRadius: 5,
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => Math.round(value),
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
            billingPeriods: [],
            currentBillingPeriod: null,
            selectedBillingPeriodId: null,
            currentPage: 1,
            totalPages: 1,
        }
    },
    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'selfActiveOrganization']),
    },
    created() {
        this.getQuotaData(null, 1)
    },
    methods: {
        getQuotaData(billingPeriodId, page) {
            const params = { page: page || 1 }
            if (billingPeriodId) params.billingPeriodId = billingPeriodId
            this.$axios
                .get(`/organizations/${this.selfActiveOrganization.id}/quota`, { params })
                .then(({ data }) => {
                    const payload = data.payload
                    this.quotas = payload.quotas
                    this.billingPeriods = payload.billingPeriods
                    this.currentBillingPeriod = payload.currentBillingPeriod
                    this.selectedBillingPeriodId = payload.currentBillingPeriod.id

                    const operatorsById = _.keyBy(payload.operators, 'operator_id')
                    this.logs = _.map(payload.logs.data, (log) => {
                        log.operator = operatorsById[log.operator_id]?.operator
                        return log
                    })
                    this.currentPage = payload.logs.pagination.page
                    this.totalPages = payload.logs.pagination.totalPages
                    this.chartData = payload.chartData
                })
                .catch((error) => {
                    console.error('Error fetching quota data', error)
                })
        },
        fetchLogs(page) {
            if (page < 1 || page > this.totalPages) return
            this.getQuotaData(this.selectedBillingPeriodId, page)
        },
        onBillingPeriodChange() {
            this.getQuotaData(this.selectedBillingPeriodId, 1)
        },
        formatDate(date) {
            if (!date) return ''
            return new Date(date).toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            })
        },
        formatQuotaType(type) {
            // You can rename them or keep them as-is
            return type
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        },
        formatBillingPeriod(period) {
            if (!period) return ''
            const options = { month: 'short', day: 'numeric', year: 'numeric' }
            const start = new Date(period.started_at).toLocaleDateString('en-US', options)
            const end = new Date(period.ended_at).toLocaleDateString('en-US', options)
            return `${start} - ${end}`
        },
    },
}
</script>
