<template>
    <main class="bg-white">
        <div class="relative flex">
            <!-- Content -->
            <div class="w-full md:w-1/2">
                <div class="flex h-full min-h-screen flex-col after:flex-1">
                    <div class="flex-1">
                        <div class="flex items-center justify-between px-4 sm:px-6 lg:px-8 py-4">
                            <!-- Logo -->
                            <router-link :to="{ path: '/' }">
                                <Logo variant="dark" :height="'40px'" :has-text="true" />
                            </router-link>
                        </div>
                    </div>

                    <div class="mx-auto w-full max-w-sm px-4 py-8 md:w-96">
                        <div class="mb-6">
                            <h1 class="text-3xl font-bold text-slate-800 mb-1">Welcome back! 🚀</h1>
                            <p class="text-sm">
                                Don't have an account?
                                <router-link :to="{ name: 'register' }" class="text-indigo-500 hover:text-indigo-700 hover:underline font-medium">Get started free</router-link>
                            </p>
                        </div>

                        <Banner v-if="errorMessage" class="mb-6" type="error">
                            {{ errorMessage }}
                        </Banner>

                        <!-- Form -->
                        <form>
                            <div class="space-y-4">
                                <div>
                                    <label class="mb-1 block text-sm font-medium" for="email">Email Address</label>
                                    <input
                                        id="email"
                                        v-model="email"
                                        class="form-input w-full"
                                        type="email"
                                        autoComplete="on"
                                        @keyup.enter="login"
                                    />
                                </div>
                                <div>
                                    <label class="mb-1 block text-sm font-medium" for="password">Password</label>
                                    <input
                                        id="password"
                                        v-model="password"
                                        class="form-input w-full"
                                        type="password"
                                        autoComplete="on"
                                        @keyup.enter="login"
                                    />
                                </div>
                            </div>
                            <div class="mt-6 flex items-center justify-between">
                                <div class="mr-1">
                                    <router-link
                                        class="text-sm underline hover:no-underline"
                                        :to="{ name: 'forgot-password' }"
                                        >Forgot Password?</router-link
                                    >
                                </div>

                                <button
                                    class="btn ml-3 bg-indigo-500 text-white disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 hover:bg-indigo-600"
                                    :disabled="!isLoading && email && password ? false : true"
                                    @click.prevent="login"
                                >
                                    <svg
                                        v-if="isLoading"
                                        class="mr-2 h-4 w-4 shrink-0 animate-spin fill-current"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"
                                        ></path>
                                    </svg>
                                    <span>Sign In</span>
                                </button>
                            </div>
                        </form>

                        <!-- Footer -->
                        <!-- <div class="pt-5 mt-6 border-t border-slate-200">
                        <div class="text-sm">
                            Don’t have an account? <router-link class="font-medium text-indigo-500 hover:text-indigo-600" to="/signup">Sign Up</router-link>
                        </div>
                    </div> -->
                    </div>
                </div>
            </div>

            <!-- Image -->
            <div class="absolute bottom-0 right-0 top-0 hidden md:block md:w-1/2" aria-hidden="true">
                <img
                    class="h-full w-full object-cover object-center"
                    src="@/images/auth-image.png"
                    width="760"
                    height="1024"
                    alt="Authentication"
                />
                <img
                    class="absolute left-0 top-1/4 ml-8 hidden -translate-x-1/2 transform lg:block"
                    src="@/images/auth-decoration.png"
                    width="218"
                    height="224"
                    alt="Authentication decoration"
                />
            </div>
        </div>
    </main>
</template>

<script>
import { nextTick } from 'vue'
import { mapGetters } from 'vuex'
import Banner from '@/components/BannerLight.vue'
import Logo from '@/components/Logo.vue'

export default {
    name: 'LoginPage',

    metaInfo: {
        title: 'Login',
        description: `Login page for the Streamforge platform.`,
    },

    components: {
        Banner,
        Logo,
    },

    computed: {
        ...mapGetters(['self']),
    },

    data() {
        return {
            email: '',
            password: '',
            errorMessage: null,

            isLoading: false,
        }
    },

    created() {
        this.$store.commit('authentication:logout')
        this.$store.commit('self:clear')
    },

    methods: {
        login() {
            if (!this.password) return
            this.isLoading = true

            this.$axios
                .post(`/login`, {
                    email: this.email,
                    password: btoa(this.password),
                })
                .then(({ status, data }) => {

                    if (status !== 200) {
                        this.password = ''
                        this.errorMessage = 'Invalid credentials provided.'
                        this.isLoading = false
                        return
                    }

                    this.$store.commit('authentication:login', decodeURIComponent(data.payload.jwt))

                    nextTick(() => {
                        this.redirectAfterLogin(0)
                        this.isLoading = false
                    })
                })
                .catch((error) => {
                    this.isLoading = false

                    if (error.response) {
                        this.errorMessage = error.response.data.message
                    }

                    console.error(error)
                })
        },

        redirectAfterLogin(attempts) {
            const delay = 50
            const maxWait = 500

            if (this.self.operator || maxWait < attempts * delay) {
                this.$router.push({ name: 'landing' })
            } else {
                setTimeout(() => {
                    this.redirectAfterLogin(attempts + 1)
                }, delay)
            }
        },
    },
}
</script>

<style lang="scss">
.logo-dark {
    .cls-1 {
        fill: #ff005c;
    }

    .cls-2 {
        fill: #ff9171;
    }

    .cls-3 {
        fill: #fcd878;
    }

    .cls-4 {
        fill: #090f32;
    }
}
</style>
