<template>
    <UIDialog v-if="modals.export_table.is_open" :open="modals.export_table.is_open" @update:open="closeModal">
        <DialogScrollContent :class="'max-w-4xl border-none p-0'">
            <DialogHeader class="px-6 pt-8">
                <DialogTitle>Export Data</DialogTitle>
                <p class="text-sm text-muted-foreground">
                    Select the columns you want to include in the exported CSV file.
                </p>
            </DialogHeader>

            <div v-if="!isLegacyBilling" class="mx-6 rounded border border-slate-200 bg-slate-100 px-4 py-2 text-sm">
                Please note that you will use
                <span class="font-semibold">{{ modals.export_table.rowCount }} export credits</span> for this export.
            </div>

            <div
                v-if="!isLegacyBilling && $lodash.find(enabledColumns, (item) => item.id === 'email')"
                class="mx-6 rounded border border-slate-200 bg-slate-100 px-4 py-2 text-sm"
            >
                <div v-if="isCheckerLoading === true">
                    <font-awesome-icon :icon="['fas', 'spinner-third']" class="mr-2 h-4 w-4 animate-spin" />
                    <span>Checking...</span>
                </div>
                <div v-else-if="checkerStatus === -1">
                    You currently do not have enough credits to include emails this export.
                </div>
                <div v-else>
                    By including the email column in your export, you will use
                    <span class="font-semibold">{{ checkerStatus }} email reveal credits</span>.
                </div>
            </div>

            <div class="mb-6 px-6">
                <div class="rounded-lg border">
                    <div class="relative">
                        <table class="w-full text-sm">
                            <thead>
                                <tr class="border-b bg-muted/50">
                                    <th class="sticky top-0 w-4 bg-muted/50 px-4 py-3 text-left font-medium">
                                        <input
                                            type="checkbox"
                                            class="h-4 w-4 rounded border-gray-300"
                                            :checked="isAllSelected"
                                            :indeterminate="isIndeterminate"
                                            @change="toggleAll"
                                        />
                                    </th>
                                    <th class="sticky top-0 bg-muted/50 px-4 py-3 text-left font-medium">
                                        Column Name
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div
                            class="max-h-96 overflow-y-auto scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-400 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-500 active:scrollbar-thumb-slate-600"
                        >
                            <table class="w-full text-sm">
                                <tbody>
                                    <tr
                                        v-for="column in allColumns"
                                        :key="column.id"
                                        class="border-b last:border-b-0 hover:bg-muted/50"
                                    >
                                        <td class="w-4 px-4 py-2">
                                            <input
                                                :id="column.id"
                                                v-model="enabledColumns"
                                                :value="column"
                                                type="checkbox"
                                                class="h-4 w-4 rounded border-gray-300"
                                            />
                                        </td>
                                        <td class="px-4 py-2">
                                            <label :for="column.id" class="flex items-center">
                                                {{ column.header.label }}
                                                <Tooltip v-if="column?.header?.tooltip?.content?.length" class="ml-1.5">
                                                    <template #trigger>
                                                        <font-awesome-icon
                                                            :icon="['fas', 'circle-info']"
                                                            class="h-3 w-3 text-slate-400"
                                                        />
                                                    </template>
                                                    <template #default>
                                                        {{ _.map(column.header.tooltip.content, 'text').join('\n') }}
                                                    </template>
                                                </Tooltip>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="mt-6 flex justify-end">
                    <button
                        class="flex rounded-md bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90 disabled:opacity-50"
                        :disabled="!enabledColumns.length || isExporting || checkerStatus === -1"
                        @click="exportData"
                    >
                        <font-awesome-icon
                            v-if="isExporting"
                            :icon="['fas', 'spinner-third']"
                            class="my-auto mr-2 h-4 w-4 animate-spin"
                        />
                        Export CSV
                    </button>
                </div>
            </div>
        </DialogScrollContent>
    </UIDialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import { json2csvAsync } from 'json-2-csv'
import { Dialog as UIDialog, DialogHeader, DialogTitle, DialogScrollContent } from '@/components/ui/dialog'
import Tooltip from '@/components/Tooltip.vue'

const store = useStore()
const allColumns = ref([])
const enabledColumns = ref([])
const isExporting = ref(false)

const modals = computed(() => store.getters.modals)
const isLegacyBilling = computed(() => store.getters.isLegacyBilling)

const isAllSelected = computed(
    () => allColumns.value.length > 0 && enabledColumns.value.length === allColumns.value.length,
)

const isIndeterminate = computed(
    () => enabledColumns.value.length > 0 && enabledColumns.value.length < allColumns.value.length,
)

const checkerStatus = ref(null)
const isCheckerLoading = ref(false)

watch(
    () => modals.value.export_table.is_open,
    async (isOpen) => {
        if (isOpen && modals.value.export_table.columns.all?.length > 0) {
            allColumns.value = modals.value.export_table.columns.all
            enabledColumns.value = modals.value.export_table.columns.enabled

            checkerStatus.value = null
            isCheckerLoading.value = true

            if (modals.value?.export_table?.checkerFn) {
                checkerStatus.value = await modals.value.export_table.checkerFn()
                isCheckerLoading.value = false
            }
        }
    },
    { immediate: true },
)

const closeModal = () => {
    store.commit('modal:close', 'export_table')
}

const toggleAll = (event) => {
    enabledColumns.value = event.target.checked ? [...allColumns.value] : []
}

const exportData = async () => {
    isExporting.value = true

    try {
        let rows = []

        if (modals.value.export_table.providerFn) {
            rows = await modals.value.export_table.providerFn()
        } else {
            rows = modals.value.export_table.rows
        }

        const mappedFields = _.map(rows, (creator) => {
            const result = {}

            _.forEach(enabledColumns.value, (column) => {
                if (!column.is_exportable) return

                if (_.has(column, 'exportTransformer')) {
                    const exportValues = column.exportTransformer(creator)

                    _.forEach(exportValues, (exportValue, key) => {
                        if ([null, undefined].includes(exportValue)) {
                            exportValues[key] = ''
                        }
                    })

                    _.assign(result, exportValues)
                } else {
                    const value = _.get(creator, column.key)
                    result[column.header.label] = ![null, undefined].includes(value) ? value : ''
                }
            })

            return result
        })

        const csvResults = await json2csvAsync(mappedFields)
        const fileName = modals.value.export_table.file_name || 'export'
        const link = document.createElement('a')

        link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvResults)}`)
        link.setAttribute('download', `${fileName}.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        store.commit('modal:close', 'export_table')
    } catch (error) {
        console.error('Error exporting data', error)
    }

    isExporting.value = false
}
</script>
