import _ from 'lodash'
import api from '../utils/api/api'

const state = {
    unlocks: {
        email: {
            // keys in the form "platformId:platformUserId"
        },
    },
    pending: [],
    batchTimer: null,
    revealPending: [],
    revealBatchTimer: null,
    isRevealingBulk: false,
}

const mutations = {
    'unlocks:set': function (state, { platformId, platformUserId, data }) {
        _.set(state.unlocks.email, `${platformId}:${platformUserId}`, data)
    },
    setRevealingBulk: function (state, loading) {
        state.isRevealingBulk = loading
    },
    'unlocks:setRevealLoading': function (state, { platformId, platformUserId, loading }) {
        const key = `${platformId}:${platformUserId}`
        const current = _.get(state.unlocks.email, key, {})
        current.loading = loading
        _.set(state.unlocks.email, key, current)
    },
    addPending(state, key) {
        state.pending.push(key)
    },
    clearPending(state) {
        state.pending.length = 0
    },
    setBatchTimer(state, timer) {
        state.batchTimer = timer
    },
    clearBatchTimer(state) {
        state.batchTimer = null
    },
    addRevealPending(state, key) {
        state.revealPending.push(key)
    },
    clearRevealPending(state) {
        state.revealPending.length = 0
    },
    setRevealBatchTimer(state, timer) {
        state.revealBatchTimer = timer
    },
    clearRevealBatchTimer(state) {
        state.revealBatchTimer = null
    },
}

const actions = {
    fetchEmailStatus({ state, commit }, { platformId, platformUserId }) {
        const key = `${platformId}:${platformUserId}`

        if (_.get(state.unlocks.email, key)) {
            return Promise.resolve(_.get(state.unlocks.email, key))
        }

        commit('addPending', key)

        return new Promise((resolve, reject) => {
            const startBatch = () => {
                const keys = Array.from(state.pending)
                commit('clearPending')
                commit('clearBatchTimer')

                return this.$axios
                    .post(`/email/check/batch`, {
                        items: keys.map((k) => {
                            const [pId, pUserId] = k.split(':')
                            return { platformId: pId, platformUserId: pUserId }
                        }),
                    })
                    .then(({ data }) => {
                        data.payload.forEach((item) => {
                            commit('unlocks:set', {
                                platformId: item.platformId,
                                platformUserId: item.platformUserId,
                                data: { ...item.payload, state: 'checked' },
                            })
                        })
                        return _.get(state.unlocks.email, key)
                    })
            }

            if (state.batchTimer) {
                clearTimeout(state.batchTimer)
            }

            const timer = setTimeout(async () => {
                try {
                    const result = await startBatch()
                    resolve(result)
                } catch (error) {
                    reject(error)
                }
            }, 1000)

            commit('setBatchTimer', timer)
        })
    },

    bulkFetchEmailStatus({ commit }, creators) {
        const items = creators.map((creator) => ({
            platformId: creator.platform_id,
            platformUserId: creator.platform_user_id,
        }))

        return this.$axios.post('/email/check/batch', { items }).then(({ data }) => {
            data.payload.forEach((item) => {
                commit('unlocks:set', {
                    platformId: item.platformId,
                    platformUserId: item.platformUserId,
                    data: { ...item.payload, state: 'checked' },
                })
            })
            return data.payload
        })
    },

    revealEmail({ state, commit }, { platformId, platformUserId }) {
        const key = `${platformId}:${platformUserId}`

        commit('unlocks:setRevealLoading', {
            platformId,
            platformUserId,
            loading: true,
        })

        commit('addRevealPending', key)

        return new Promise((resolve, reject) => {
            const startBatch = () => {
                const keys = Array.from(state.revealPending)
                commit('clearRevealPending')
                commit('clearRevealBatchTimer')

                return this.$axios
                    .post(`/email/reveal/batch`, {
                        items: keys.map((k) => {
                            const [pId, pUserId] = k.split(':')
                            return { platformId: pId, platformUserId: pUserId }
                        }),
                    })
                    .then(({ data }) => {
                        data.payload.forEach((item) => {
                            commit('unlocks:set', {
                                platformId: item.key.split(':')[0],
                                platformUserId: item.key.split(':')[1],
                                data: { ...item.payload, state: 'revealed' },
                            })
                            commit('unlocks:setRevealLoading', {
                                platformId: item.key.split(':')[0],
                                platformUserId: item.key.split(':')[1],
                                loading: false,
                            })
                        })
                        resolve(data.payload)
                    })
                    .catch((error) => {
                        keys.forEach((k) => {
                            const [pId, pUserId] = k.split(':')
                            commit('unlocks:setRevealLoading', {
                                platformId: pId,
                                platformUserId: pUserId,
                                loading: false,
                            })
                        })

                        if (api.isQuotaError(error)) {
                            this.$errorHandler(error)
                        }

                        reject(error)
                    })
            }

            if (state.revealBatchTimer) {
                clearTimeout(state.revealBatchTimer)
            }

            const timer = setTimeout(() => startBatch(), 1000)
            commit('setRevealBatchTimer', timer)
        })
    },

    bulkRevealEmails({ commit }, creators) {
        const items = creators.map((creator) => ({
            platformId: creator.platform_id,
            platformUserId: creator.platform_user_id,
        }))

        commit('setRevealingBulk', true)

        return this.$axios
            .post('/email/reveal/batch', { items })
            .then(({ data }) => {
                data.payload.forEach((item) => {
                    commit('unlocks:set', {
                        platformId: item.key.split(':')[0],
                        platformUserId: item.key.split(':')[1],
                        data: { ...item.payload, state: 'revealed' },
                    })
                })
                return data.payload
            })
            .finally(() => {
                commit('setRevealingBulk', false)
            })
    },

    clearEmailStatus({ state }, { platformId, platformUserId }) {
        const key = `${platformId}:${platformUserId}`

        if (_.get(state.unlocks.email, key)) {
            return Promise.resolve(_.unset(state.unlocks.email, key))
        }

        return
    },
}

const getters = {
    getEmailReveal: (state) => (platformId, platformUserId) => {
        return _.get(state.unlocks.email, `${platformId}:${platformUserId}`)
    },
    unlocks: (state) => state.unlocks,
    isRevealingBulk: (state) => state.isRevealingBulk,
}

export default {
    state,
    mutations,
    actions,
    getters,
}
