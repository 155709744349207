<template>
    <div class="grid xl:grid-cols-2 2xl:grid-cols-3 gap-8 mx-3">
        <div
            v-for="subscriptionProduct in getNonSeatSubscriptionProducts()"
            :key="subscriptionProduct"
            class="rounded border"
        >
            <div class="pl-3 pr-3 pt-3 text-sm font-bold">
                {{
                    getFromDictionary(
                        `products.${subscriptionProduct.product.productType}`,
                        'label',
                    ).toLocaleUpperCase()
                }}
                {{ subscriptionProduct.quantity > 1 ? `(${subscriptionProduct.quantity})` : '' }}
            </div>

            <div class="border-b p-3">
                <div class="pb-3 text-sm">
                    {{ getFromDictionary(`products.${subscriptionProduct.product.productType}`, 'description') }}
                </div>
                <div v-if="subscriptionProduct.price">
                    <span class="font-bold">{{
                        $filters.formatCurrency(
                            calculateMonthlyPrice(subscriptionProduct.price, subscriptionProduct.quantity),
                        )
                    }}</span
                    >/mo
                </div>
            </div>

            <!-- <div class="border-b p-3">
                <div
                    v-if="
                        subscriptionProduct?.price?.relatedPriceId &&
                        subscriptionProduct.subscription.status !== 'ending' &&
                        !hasScheduledChanges
                    "
                    class="pb-3"
                >
                    Additional seat(s)
                    <div class="flex pt-3">
                        <input
                            :id="subscriptionProduct.price.relatedPriceId"
                            v-model="subscriptionProductCounts[subscriptionProduct.price.relatedPriceId]"
                            class="flex h-8 w-24 rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                            type="number"
                            @input="
                                onRelatedChange(subscriptionProduct.price.id, subscriptionProduct.price.relatedPriceId)
                            "
                        />
                        <div class="ml-3 mt-1">
                            <span class="font-bold">
                                {{
                                    $filters.formatCurrency(
                                        calculateMonthlyPrice(
                                            getSeatPrice(subscriptionProduct.price.relatedPriceId),
                                            subscriptionProductCounts[subscriptionProduct.price.relatedPriceId],
                                        ),
                                    )
                                }}</span
                            >/month
                        </div>
                    </div>
                </div>
            </div> -->

            <div >
                <div v-if="subscriptionProduct.subscription.status === 'ending'" class="border-b p-3">
                    <div
                        v-if="subscriptionProduct?.price?.selfManaged"
                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                    >
                        <button @click="onManageSubscription(subscriptionProduct)">Manage</button>
                    </div>
                    <div
                        v-else
                        class="btn-sm border-slate-200 bg-white text-slate-500 hover:border-slate-300 hover:text-slate-600"
                    >
                        <button @click="onContactUs(subscriptionProduct)">Contact us</button>
                    </div>
                    <div class="mt-2 text-sm font-semibold italic">
                        (Canceled, expires {{ $filters.formatDate(subscriptionProduct.subscription.expiresAt) }})
                    </div>
                </div>

                <div v-else-if="subscriptionProduct?.price?.selfManaged && !hasScheduledChanges" class="border-b p-3">
                    <div class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600">
                        <button @click="onManageSubscription(subscriptionProduct)">Manage</button>
                    </div>
                    <div
                        v-if="subscriptionProductChanged[subscriptionProduct?.price?.id] === true"
                        class="btn-sm ml-3 bg-indigo-500 text-white hover:bg-indigo-600"
                    >
                        <button
                            @click="
                                onChangeSubscriptionCounts(
                                    subscriptionProduct,
                                    subscriptionProduct.price.relatedPriceId,
                                    subscriptionProductCounts[subscriptionProduct.price.relatedPriceId],
                                )
                            "
                        >
                            Update seats
                        </button>
                    </div>
                </div>
                <div v-else-if="subscriptionProduct?.price?.selfManaged || hasScheduledChanges" class="border-b p-3">
                    <div
                        class="btn-sm border-slate-200 bg-white text-slate-500 hover:border-slate-300 hover:text-slate-600"
                    >
                        <button @click="onContactUs(subscriptionProduct)">Contact us</button>
                    </div>
                </div>
            </div>

            <div class="pl-3 pr-3 pt-3">
                <div class="pb-3 text-xs font-bold">WHAT'S INCLUDED</div>
                <div class="pb-4">
                    <ul class="space-y-2.5">
                        <li
                            v-for="includedItem in getIncludedItems(subscriptionProduct)"
                            :key="includedItem"
                            class="flex text-sm leading-none"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" class="my-auto h-3 w-3 text-emerald-500" />
                            <span class="pl-2">
                                {{ includedItem }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    name: 'SubscriptionsTable',
    props: {
        subscriptions: {
            type: Object,
        },
        isSubscriptionsLoading: {
            type: Boolean,
        },
        productPrices: {
            type: Object,
        },
        hasScheduledChanges: {
            type: Boolean,
        },
    },

    emits: ['manage-subscription', 'change-subscription'],

    data() {
        return {
            subscriptionProductCounts: {},
            subscriptionProductCountsInitial: {},
            subscriptionProductChanged: {},
        }
    },

    created() {
        this.resetProductCounts()
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
        subscriptionProducts() {
            const subProducts = []
            _.forEach(this.subscriptions, (subscription) => {
                _.forEach(subscription.products, (product) => {
                    subProducts.push({
                        subscription,
                        product: product.product,
                        price: product.price,
                        quantity: product.quantity,
                    })
                })
            })

            _.orderBy(subProducts, ['price.amount'], ['asc'])

            const nonSeatProducts = _.filter(subProducts, (subscriptionProduct) => {
                return subscriptionProduct.product.productType !== 'seat'
            })
            const seatProducts = _.filter(subProducts, (subscriptionProduct) => {
                return subscriptionProduct.product.productType === 'seat'
            })

            return nonSeatProducts.concat(seatProducts) // Seats last
        },
    },

    watch: {
        productPrices() {
            this.resetProductCounts()
        },
        subscriptions() {
            this.resetProductCounts()
        },
    },

    methods: {
        resetProductCounts() {
            this.subscriptionProductCounts = {}
            this.subscriptionProductCountsInitial = {}

            _.forEach(this.productPrices, (productPrice) => {
                this.subscriptionProductCounts[productPrice.price.id] = 0
                this.subscriptionProductCountsInitial[productPrice.price.id] = 0
            })

            _.forEach(this.subscriptionProducts, (subscriptionProduct) => {
                if (subscriptionProduct.price) {
                    this.subscriptionProductCounts[subscriptionProduct.price.id] = subscriptionProduct.quantity
                    this.subscriptionProductCountsInitial[subscriptionProduct.price.id] = subscriptionProduct.quantity
                }
            })
        },
        getNonSeatSubscriptionProducts() {
            return _.filter(this.subscriptionProducts, (subscriptionProduct) => {
                return subscriptionProduct.product.productType !== 'seat'
            })
        },
        getIncludedItems(subscriptionProduct) {
            let includedItems = this.getFromDictionary(
                `products.${subscriptionProduct.product.productType}`,
                'included',
            )

            includedItems = [...includedItems]

            if (
                subscriptionProduct?.price?.relatedPriceId &&
                this.subscriptionProductCountsInitial[subscriptionProduct.price.relatedPriceId] > 0
            ) {
                const seatQuantity = this.subscriptionProductCountsInitial[subscriptionProduct.price.relatedPriceId]
                if (seatQuantity === 1) {
                    includedItems.unshift(`1 additional seat`)
                } else {
                    includedItems.unshift(`${seatQuantity} additional seats`)
                }
            }

            const defaultSeats = this.getFromDictionary(
                `products.${subscriptionProduct.product.productType}`,
                'defaultSeats',
            )

            if (defaultSeats > 1) {
                includedItems.unshift(`${defaultSeats} included seats`)
            } else {
                includedItems.unshift(`1 included seat`)
            }

            return includedItems
        },
        onManageSubscription(subscription) {
            this.$emit('manage-subscription', subscription)
        },
        onChangeSubscriptionCounts(subscription, relatedPriceId, relatedPriceCount) {
            this.$emit('change-subscription', subscription, relatedPriceId, relatedPriceCount)
        },
        calculateMonthlyPrice(price, quantity) {
            if (!price) {
                return 0
            }

            if (price.billingInterval === 'year') {
                return Math.ceil((price.amount / 12) * quantity)
            }
            return price.amount * quantity
        },

        getSeatPrice(id) {
            const seatProductInSubscription = _.find(this.subscriptionProducts, (subscriptionProduct) => {
                return subscriptionProduct?.price?.id === id
            })

            if (seatProductInSubscription) {
                return seatProductInSubscription.price
            }

            const seatPrice = _.find(this.productPrices, (productPrice) => {
                return productPrice.price.id === id
            })

            if (seatPrice) {
                return seatPrice.price
            }

            return null
        },

        onRelatedChange(priceId, relatedPriceId) {
            if (this.subscriptionProductCounts[relatedPriceId] < 0) {
                this.subscriptionProductCounts[relatedPriceId] = 0
            } else if (this.subscriptionProductCounts[relatedPriceId] > 999) {
                this.subscriptionProductCounts[relatedPriceId] = 999
            }

            if (
                this.subscriptionProductCounts[relatedPriceId] !== this.subscriptionProductCountsInitial[relatedPriceId]
            ) {
                this.subscriptionProductChanged[priceId] = true
            } else {
                this.subscriptionProductChanged[priceId] = false
            }
        },
        onContactUs() {
            this.$intercom.show()
        },
    },
}
</script>
