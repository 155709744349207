import _ from 'lodash'
import axios from 'axios'
import { getGradeAndColorsFromPercent } from '@/utils/affinity'
import api from './api'
import targetGroupTools from '../searching/targetgroups'

export const appendValueFilter = (results, filters, path, id) => {
    const filter = _.get(filters, path, null)

    if (filter && filter.values) {
        const osFilter = {
            id,
            value: [],
        }

        osFilter.value = _.map(filter.values, (item) => ({ id: item.id, value: item.value2 }))

        results.push(osFilter)

        if (filter.conjunction) {
            results.push({ id: `${id}-conjunction`, value: filter.conjunction })
        }
    }
}

export const transformFilters = (filters, platformId) => {
    const results = []
    let defaultOptions = null

    // Platform agnostic filters
    if (filters.day_range) {
        results.push({ id: 'day_range', value: filters.day_range })
    }

    if (platformId === 'twitch') {
        defaultOptions = targetGroupTools.createTwitchOptions()

        let hasGames = false
        let gamesConjunction = ''

        if (filters.industry) {
            results.push({ id: 'industry', value: [filters.industry] })
        }

        if (filters.industry_category) {
            results.push({ id: 'industry_category', value: [filters.industry_category] })
        }

        if (filters.games && filters.games.values) {
            hasGames = true
            results.push({ id: 'games', value: _.map(filters.games.values, 'id') })
        }
        if (filters.games && filters.games.conjunction) {
            gamesConjunction = filters.games.conjunction
            results.push({ id: 'games-conjunction', value: filters.games.conjunction })
        }
        if (filters.games && filters.games.conjunction && filters.games.conjunction === '$or') {
            let minFilteredGames = filters.matched_games.min
            if (minFilteredGames > filters.games.values.length) {
                minFilteredGames = filters.games.values.length
            }
            results.push({ id: 'min-matched-games', value: minFilteredGames })
        }
        if (filters.games && filters.not_games.values) {
            results.push({ id: 'not-games', value: _.map(filters.not_games.values, 'id') })
        }
        if (filters.games && filters.not_games.conjunction) {
            results.push({ id: 'not-games-conjunction', value: filters.not_games.conjunction })
        }
        if (filters.content_count && filters.content_count.min) {
            results.push({ id: 'min-content', value: filters.content_count.min })
        }
        if (filters.avg_viewers && filters.avg_viewers.min) {
            results.push({ id: 'min-viewers', value: filters.avg_viewers.min })
        }
        if (
            filters.avg_viewers &&
            filters.avg_viewers.max &&
            filters.avg_viewers.max !== defaultOptions.options.avg_viewers.max
        ) {
            results.push({ id: 'max-viewers', value: filters.avg_viewers.max })
        }

        if (filters.followers && filters.followers.min) {
            results.push({ id: 'min-followers', value: filters.followers.min })
        }

        if (
            filters.followers &&
            filters.followers.max &&
            filters.followers.max !== defaultOptions.options.followers.max
        ) {
            results.push({ id: 'max-followers', value: filters.followers.max })
        }

        if (filters.engagement_rate && filters.engagement_rate.min) {
            results.push({ id: 'min-engagement-rate', value: filters.engagement_rate.min })
        }

        if (
            filters.engagement_rate &&
            filters.engagement_rate.max &&
            filters.engagement_rate.max !== defaultOptions.options.engagement_rate.max
        ) {
            results.push({ id: 'max-engagement-rate', value: filters.engagement_rate.max })
        }

        if (filters.affinity && typeof filters.affinity.game.min === 'number' && filters.affinity.game.min > 0) {
            const grade = getGradeAndColorsFromPercent(filters.affinity.game.min)

            if (grade.min && hasGames && (gamesConjunction !== '$or' || filters.games.values.length === 1)) {
                results.push({ id: 'min-game-affinity', value: Math.max(grade.min, 0) })
            }
        }

        if (filters.affinity && typeof filters.affinity.game.max === 'number' && filters.affinity.game.max < 1) {
            const grade = getGradeAndColorsFromPercent(filters.affinity.game.max)

            if (grade.max && hasGames && (gamesConjunction !== '$or' || filters.games.values.length === 1)) {
                results.push({ id: 'max-game-affinity', value: Math.min(grade.max, 1) })
            }
        }

        if (filters.games && filters.games.min) {
            results.push({ id: 'min-games', value: filters.games.min })
        }
        if (filters.games && filters.games.max) {
            results.push({ id: 'max-games', value: filters.games.max })
        }
        if (filters.countries) {
            results.push({ id: 'countries', value: _.map(filters.countries, 'id') })
        }
        if (filters.languages) {
            results.push({ id: 'languages', value: _.map(filters.languages, 'id') })
        }
        if (filters.metadata && filters.metadata.email) {
            results.push({ id: 'has-email', value: filters.metadata.email })
        }
        if (filters.tags && filters.tags.values) {
            results.push({ id: 'tags', value: _.map(filters.tags.values, 'id') })
        }
        if (filters.tags && filters.tags.conjunction) {
            results.push({ id: 'tags-conjunction', value: filters.tags.conjunction })
        }
        if (filters.name) {
            results.push({ id: 'name', value: filters.name })
        }
        if (filters.list_id) {
            results.push({ id: 'list_id', value: filters.list_id })
        }
        if (filters.campaign_id) {
            results.push({ id: 'campaign_id', value: filters.campaign_id })
        }
        if (filters.metadata && filters.metadata.type) {
            results.push({ id: 'channel-types', value: _.map(filters.metadata.type, 'id') })
        }
        if (filters.metadata && filters.metadata.location) {
            results.push({ id: 'has-location', value: filters.metadata.location })
        }

        if (filters.categories && filters.categories.values) {
            appendValueFilter(results, filters, 'categories', 'categories')
        }

        if (filters.categories_affinity && filters.categories_affinity.values) {
            appendValueFilter(results, filters, 'categories_affinity', 'categories-affinity')
        }

        if (filters.not_categories && filters.not_categories.values) {
            appendValueFilter(results, filters, 'not_categories', 'not-categories')
        }

        if (filters.demographics) {
            appendValueFilter(results, filters, 'demographics.device', 'demographics-device')
            appendValueFilter(results, filters, 'demographics.language', 'demographics-language')
            appendValueFilter(results, filters, 'demographics.os', 'demographics-os')
            appendValueFilter(results, filters, 'demographics.country', 'demographics-country')
        }
    }

    if (platformId === 'youtube') {
        defaultOptions = targetGroupTools.createYoutubeOptions()

        if (filters.industry) {
            results.push({ id: 'industry', value: [filters.industry] })
        }

        if (filters.industry_category) {
            results.push({ id: 'industry_category', value: [filters.industry_category] })
        }

        if (filters.games && filters.games.values) {
            results.push({ id: 'games', value: _.map(filters.games.values, 'id') })
        }
        if (filters.games && filters.games.conjunction) {
            results.push({ id: 'games-conjunction', value: filters.games.conjunction })
        }
        if (filters.games && filters.games.conjunction && filters.games.conjunction === '$or') {
            let minFilteredGames = filters.matched_games.min
            if (minFilteredGames > filters.games.values.length) {
                minFilteredGames = filters.games.values.length
            }
            results.push({ id: 'min-matched-games', value: minFilteredGames })
        }
        if (filters.games && filters.not_games.values) {
            results.push({ id: 'not-games', value: _.map(filters.not_games.values, 'id') })
        }
        if (filters.games && filters.not_games.conjunction) {
            results.push({ id: 'not-games-conjunction', value: filters.not_games.conjunction })
        }
        if (filters.affinity && typeof filters.affinity.game.min === 'number' && filters.affinity.game.min > 0) {
            results.push({ id: 'min-game-affinity', value: filters.affinity.game.min })
        }
        if (filters.affinity && typeof filters.affinity.game.max === 'number' && filters.affinity.game.max < 1) {
            results.push({ id: 'max-game-affinity', value: filters.affinity.game.max })
        }
        if (filters.content_count && filters.content_count.min) {
            results.push({ id: 'min-content', value: filters.content_count.min })
        }
        if (filters.avg_views && filters.avg_views.min) {
            results.push({ id: 'min-views', value: filters.avg_views.min })
        }
        if (
            filters.avg_views &&
            filters.avg_views.max &&
            filters.avg_views.max !== defaultOptions.options.avg_views.max
        ) {
            results.push({ id: 'max-views', value: filters.avg_views.max })
        }
        if (filters.subscribers && filters.subscribers.min) {
            results.push({ id: 'min-subscribers', value: filters.subscribers.min })
        }
        if (
            filters.subscribers &&
            filters.subscribers.max &&
            filters.subscribers.max !== defaultOptions.options.subscribers.max
        ) {
            results.push({ id: 'max-subscribers', value: filters.subscribers.max })
        }

        if (filters.engagement_rate && filters.engagement_rate.min) {
            results.push({ id: 'min-engagement-rate', value: filters.engagement_rate.min })
        }

        if (
            filters.engagement_rate &&
            filters.engagement_rate.max &&
            filters.engagement_rate.max !== defaultOptions.options.engagement_rate.max
        ) {
            results.push({ id: 'max-engagement-rate', value: filters.engagement_rate.max })
        }
        if (filters.games && filters.games.min) {
            results.push({ id: 'min-games', value: filters.games.min })
        }
        if (filters.games && filters.games.max) {
            results.push({ id: 'max-games', value: filters.games.max })
        }
        if (filters.countries) {
            results.push({ id: 'countries', value: _.map(filters.countries, 'id') })
        }
        if (filters.languages) {
            results.push({ id: 'languages', value: _.map(filters.languages, 'id') })
        }
        if (filters.metadata && filters.metadata.email) {
            results.push({ id: 'has-email', value: filters.metadata.email })
        }
        if (filters.tags && filters.tags.values) {
            results.push({ id: 'tags', value: _.map(filters.tags.values, 'id') })
        }
        if (filters.tags && filters.tags.conjunction) {
            results.push({ id: 'tags-conjunction', value: filters.tags.conjunction })
        }
        if (filters.metadata && filters.metadata.location) {
            results.push({ id: 'has-location', value: filters.metadata.location })
        }
        if (filters.metadata && filters.metadata.type) {
            results.push({ id: 'channel-types', value: _.map(filters.metadata.type, 'id') })
        }
        if (filters.name) {
            results.push({ id: 'name', value: filters.name })
        }
        if (filters.list_id) {
            results.push({ id: 'list_id', value: filters.list_id })
        }
        if (filters.campaign_id) {
            results.push({ id: 'campaign_id', value: filters.campaign_id })
        }
        if (filters.content_type) {
            results.push({ id: 'content-type', value: filters.content_type })
        }

        if (filters.categories && filters.categories.values) {
            appendValueFilter(results, filters, 'categories', 'categories')
        }

        if (filters.categories_affinity && filters.categories_affinity.values) {
            appendValueFilter(results, filters, 'categories_affinity', 'categories-affinity')
        }

        if (filters.not_categories && filters.not_categories.values) {
            appendValueFilter(results, filters, 'not_categories', 'not-categories')
        }
    }

    if (platformId === 'tiktok') {
        defaultOptions = targetGroupTools.createTiktokOptions()

        if (filters.industry) {
            results.push({ id: 'industry', value: [filters.industry] })
        }

        if (filters.industry_category) {
            results.push({ id: 'industry_category', value: [filters.industry_category] })
        }

        if (filters.games && filters.games.values) {
            results.push({ id: 'games', value: _.map(filters.games.values, 'id') })
        }
        if (filters.games && filters.games.conjunction) {
            results.push({ id: 'games-conjunction', value: filters.games.conjunction })
        }
        if (filters.games && filters.games.conjunction && filters.games.conjunction === '$or') {
            results.push({ id: 'min-matched-games', value: filters.matched_games.min })
        }
        if (filters.games && filters.not_games.values) {
            results.push({ id: 'not-games', value: _.map(filters.not_games.values, 'id') })
        }
        if (filters.games && filters.not_games.conjunction) {
            results.push({ id: 'not-games-conjunction', value: filters.not_games.conjunction })
        }
        if (filters.affinity && typeof filters.affinity.game.min === 'number') {
            results.push({ id: 'min-game-affinity', value: filters.affinity.game.min })
        }
        if (filters.affinity && typeof filters.affinity.game.max === 'number') {
            results.push({ id: 'max-game-affinity', value: filters.affinity.game.max })
        }
        if (filters.content_count && filters.content_count.min) {
            results.push({ id: 'min-content', value: filters.content_count.min })
        }
        if (filters.avg_views && filters.avg_views.min) {
            results.push({ id: 'min-views', value: filters.avg_views.min })
        }
        if (
            filters.avg_views &&
            filters.avg_views.max &&
            filters.avg_views.max !== defaultOptions.options.avg_views.max
        ) {
            results.push({ id: 'max-views', value: filters.avg_views.max })
        }
        if (filters.followers && filters.followers.min) {
            results.push({ id: 'min-followers', value: filters.followers.min })
        }
        if (
            filters.followers &&
            filters.followers.max &&
            filters.followers.max !== defaultOptions.options.followers.max
        ) {
            results.push({ id: 'max-followers', value: filters.followers.max })
        }

        if (filters.engagement_rate && filters.engagement_rate.min) {
            results.push({ id: 'min-engagement-rate', value: filters.engagement_rate.min })
        }

        if (
            filters.engagement_rate &&
            filters.engagement_rate.max &&
            filters.engagement_rate.max !== defaultOptions.options.engagement_rate.max
        ) {
            results.push({ id: 'max-engagement-rate', value: filters.engagement_rate.max })
        }
        // if (filters.games && filters.games.min) {
        //     results.push({ id: 'min-games', value: filters.games.min })
        // }
        // if (filters.games && filters.games.max) {
        //     results.push({ id: 'max-games', value: filters.games.max })
        // }
        if (filters.countries) {
            results.push({ id: 'countries', value: _.map(filters.countries, 'id') })
        }
        if (filters.languages) {
            results.push({ id: 'languages', value: _.map(filters.languages, 'id') })
        }
        if (filters.metadata && filters.metadata.email) {
            results.push({ id: 'has-email', value: filters.metadata.email })
        }
        if (filters.tags && filters.tags.values) {
            results.push({ id: 'tags', value: _.map(filters.tags.values, 'id') })
        }
        if (filters.tags && filters.tags.conjunction) {
            results.push({ id: 'tags-conjunction', value: filters.tags.conjunction })
        }
        if (filters.metadata && filters.metadata.location) {
            results.push({ id: 'has-location', value: filters.metadata.location })
        }
        if (filters.metadata && filters.metadata.type) {
            results.push({ id: 'channel-types', value: _.map(filters.metadata.type, 'id') })
        }
        if (filters.name) {
            results.push({ id: 'name', value: filters.name })
        }
        if (filters.list_id) {
            results.push({ id: 'list_id', value: filters.list_id })
        }
        if (filters.campaign_id) {
            results.push({ id: 'campaign_id', value: filters.campaign_id })
        }
    }

    if (platformId === 'twitter') {
        defaultOptions = targetGroupTools.createTwitterOptions()

        if (filters.industry) {
            results.push({ id: 'industry', value: [filters.industry] })
        }

        if (filters.industry_category) {
            results.push({ id: 'industry_category', value: [filters.industry_category] })
        }

        if (filters.games && filters.games.values) {
            results.push({ id: 'games', value: _.map(filters.games.values, 'id') })
        }
        if (filters.games && filters.games.conjunction) {
            results.push({ id: 'games-conjunction', value: filters.games.conjunction })
        }
        if (filters.games && filters.games.conjunction && filters.games.conjunction === '$or') {
            results.push({ id: 'min-matched-games', value: filters.matched_games.min })
        }
        if (filters.games && filters.not_games.values) {
            results.push({ id: 'not-games', value: _.map(filters.not_games.values, 'id') })
        }
        if (filters.games && filters.not_games.conjunction) {
            results.push({ id: 'not-games-conjunction', value: filters.not_games.conjunction })
        }
        if (filters.affinity && typeof filters.affinity.game.min === 'number') {
            results.push({ id: 'min-game-affinity', value: filters.affinity.game.min })
        }
        if (filters.affinity && typeof filters.affinity.game.max === 'number') {
            results.push({ id: 'max-game-affinity', value: filters.affinity.game.max })
        }
        if (filters.content_count && filters.content_count.min) {
            results.push({ id: 'min-content', value: filters.content_count.min })
        }
        if (filters.avg_views && filters.avg_views.min) {
            results.push({ id: 'min-views', value: filters.avg_views.min })
        }
        if (
            filters.avg_views &&
            filters.avg_views.max &&
            filters.avg_views.max !== defaultOptions.options.avg_views.max
        ) {
            results.push({ id: 'max-views', value: filters.avg_views.max })
        }
        if (filters.followers && filters.followers.min) {
            results.push({ id: 'min-followers', value: filters.followers.min })
        }
        if (
            filters.followers &&
            filters.followers.max &&
            filters.followers.max !== defaultOptions.options.followers.max
        ) {
            results.push({ id: 'max-followers', value: filters.followers.max })
        }

        if (filters.engagement_rate && filters.engagement_rate.min) {
            results.push({ id: 'min-engagement-rate', value: filters.engagement_rate.min })
        }

        if (
            filters.engagement_rate &&
            filters.engagement_rate.max &&
            filters.engagement_rate.max !== defaultOptions.options.engagement_rate.max
        ) {
            results.push({ id: 'max-engagement-rate', value: filters.engagement_rate.max })
        }
        // if (filters.games && filters.games.min) {
        //     results.push({ id: 'min-games', value: filters.games.min })
        // }
        // if (filters.games && filters.games.max) {
        //     results.push({ id: 'max-games', value: filters.games.max })
        // }
        if (filters.countries) {
            results.push({ id: 'countries', value: _.map(filters.countries, 'id') })
        }
        if (filters.languages) {
            results.push({ id: 'languages', value: _.map(filters.languages, 'id') })
        }
        if (filters.metadata && filters.metadata.email) {
            results.push({ id: 'has-email', value: filters.metadata.email })
        }
        if (filters.tags && filters.tags.values) {
            results.push({ id: 'tags', value: _.map(filters.tags.values, 'id') })
        }
        if (filters.tags && filters.tags.conjunction) {
            results.push({ id: 'tags-conjunction', value: filters.tags.conjunction })
        }
        if (filters.metadata && filters.metadata.location) {
            results.push({ id: 'has-location', value: filters.metadata.location })
        }
        if (filters.metadata && filters.metadata.type) {
            results.push({ id: 'channel-types', value: _.map(filters.metadata.type, 'id') })
        }
        if (filters.name) {
            results.push({ id: 'name', value: filters.name })
        }
        if (filters.list_id) {
            results.push({ id: 'list_id', value: filters.list_id })
        }
        if (filters.campaign_id) {
            results.push({ id: 'campaign_id', value: filters.campaign_id })
        }
    }

    if (platformId === 'instagram') {
        defaultOptions = targetGroupTools.createInstagramOptions()

        if (filters.industry) {
            results.push({ id: 'industry', value: [filters.industry] })
        }

        if (filters.industry_category) {
            results.push({ id: 'industry_category', value: [filters.industry_category] })
        }

        if (filters.games && filters.games.values) {
            results.push({ id: 'games', value: _.map(filters.games.values, 'id') })
        }
        if (filters.games && filters.games.conjunction) {
            results.push({ id: 'games-conjunction', value: filters.games.conjunction })
        }
        if (filters.games && filters.games.conjunction && filters.games.conjunction === '$or') {
            results.push({ id: 'min-matched-games', value: filters.matched_games.min })
        }
        if (filters.games && filters.not_games.values) {
            results.push({ id: 'not-games', value: _.map(filters.not_games.values, 'id') })
        }
        if (filters.games && filters.not_games.conjunction) {
            results.push({ id: 'not-games-conjunction', value: filters.not_games.conjunction })
        }
        if (filters.affinity && typeof filters.affinity.game.min === 'number') {
            results.push({ id: 'min-game-affinity', value: filters.affinity.game.min })
        }
        if (filters.affinity && typeof filters.affinity.game.max === 'number') {
            results.push({ id: 'max-game-affinity', value: filters.affinity.game.max })
        }
        if (filters.content_count && filters.content_count.min) {
            results.push({ id: 'min-content', value: filters.content_count.min })
        }
        if (filters.avg_views && filters.avg_views.min) {
            results.push({ id: 'min-views', value: filters.avg_views.min })
        }
        if (
            filters.avg_views &&
            filters.avg_views.max &&
            filters.avg_views.max !== defaultOptions.options.avg_views.max
        ) {
            results.push({ id: 'max-views', value: filters.avg_views.max })
        }
        if (filters.avg_likes && filters.avg_likes.min) {
            results.push({ id: 'min-likes', value: filters.avg_likes.min })
        }
        if (
            filters.avg_likes &&
            filters.avg_likes.max &&
            filters.avg_likes.max !== defaultOptions.options.avg_likes.max
        ) {
            results.push({ id: 'max-likes', value: filters.avg_likes.max })
        }
        if (filters.followers && filters.followers.min) {
            results.push({ id: 'min-followers', value: filters.followers.min })
        }
        if (
            filters.followers &&
            filters.followers.max &&
            filters.followers.max !== defaultOptions.options.followers.max
        ) {
            results.push({ id: 'max-followers', value: filters.followers.max })
        }

        if (filters.engagement_rate && filters.engagement_rate.min) {
            results.push({ id: 'min-engagement-rate', value: filters.engagement_rate.min })
        }

        if (
            filters.engagement_rate &&
            filters.engagement_rate.max &&
            filters.engagement_rate.max !== defaultOptions.options.engagement_rate.max
        ) {
            results.push({ id: 'max-engagement-rate', value: filters.engagement_rate.max })
        }
        // if (filters.games && filters.games.min) {
        //     results.push({ id: 'min-games', value: filters.games.min })
        // }
        // if (filters.games && filters.games.max) {
        //     results.push({ id: 'max-games', value: filters.games.max })
        // }
        if (filters.countries) {
            results.push({ id: 'countries', value: _.map(filters.countries, 'id') })
        }
        if (filters.languages) {
            results.push({ id: 'languages', value: _.map(filters.languages, 'id') })
        }
        if (filters.metadata && filters.metadata.email) {
            results.push({ id: 'has-email', value: filters.metadata.email })
        }
        if (filters.tags && filters.tags.values) {
            results.push({ id: 'tags', value: _.map(filters.tags.values, 'id') })
        }
        if (filters.tags && filters.tags.conjunction) {
            results.push({ id: 'tags-conjunction', value: filters.tags.conjunction })
        }
        if (filters.metadata && filters.metadata.location) {
            results.push({ id: 'has-location', value: filters.metadata.location })
        }
        if (filters.metadata && filters.metadata.type) {
            results.push({ id: 'channel-types', value: _.map(filters.metadata.type, 'id') })
        }
        if (filters.name) {
            results.push({ id: 'name', value: filters.name })
        }
        if (filters.list_id) {
            results.push({ id: 'list_id', value: filters.list_id })
        }
        if (filters.campaign_id) {
            results.push({ id: 'campaign_id', value: filters.campaign_id })
        }
    }

    if (filters.interests && filters.interests.values) {
        results.push({ id: 'interests', value: _.map(filters.interests.values, 'id') })
    }

    if (filters.interests && filters.interests.conjunction) {
        results.push({ id: 'interests-conjunction', value: filters.interests.conjunction })
    }

    if (filters?.contentRatings?.length) {
        results.push({ id: 'content_ratings', value: _.map(filters.contentRatings, 'id') })
    }

    if (filters?.genders?.length) {
        results.push({ id: 'genders', value: _.map(filters.genders, 'id') })
    }
    if (filters?.ageRanges?.length) {
        results.push({ id: 'age-ranges', value: _.map(filters.ageRanges, 'id') })
    }

    return results
}

export const transformPaging = (paging) => ({
    size: paging.perPage,
    page: paging.currentPage,
    sort: paging.sort,
})

export const apicodes = {
    async findCreators(axiosInstance, platformId, filters, paging, isExport) {
        let endpoint = ''
        let body = {}

        const validPlatformIds = ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram']

        if (!validPlatformIds.includes(platformId)) {
            throw new Error(`Invalid platform ID provided to discovery API request`)
        }
        endpoint = `/discovery/${platformId}`

        body = {
            paging,
        }
        body.paging.filters = transformFilters(filters, platformId)

        if (isExport === true) {
            body.export = true
            body.paging.perPage = 500
        }

        return axiosInstance
            .post(endpoint, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(`Discover request cancelled`, error)
                    return api.createErrorResponse(error)
                }
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicodes
