<template>
    <!-- Responsive sidebar with full-width navigation items -->
    <div class="w-full mb-4 sm:mb-6 md:mb-0 md:w-60 lg:w-64">
        <div class="md:sticky md:top-16 md:h-[calc(100vh-64px)] md:overflow-y-auto md:overflow-x-hidden">
            <div class="py-4 md:py-8">
                <!-- Title with responsive sizing -->
                <header class="mb-6">
                    <h1 class="text-xl sm:text-2xl md:text-3xl font-bold text-slate-800 truncate">{{ organization?.name }}</h1>
                </header>

                <!-- Navigation menu - consistent across all devices -->
                <div class="">
                    <div class="mb-2 text-xs font-semibold uppercase text-slate-400">Menu</div>
                    <ul class="">
                        <!-- Users link -->
                        <router-link v-slot="{ href, navigate, isExactActive }" :to="{ name: 'organization-users' }" custom>
                            <li>
                                <a
                                    class="block w-full rounded px-3 py-2.5 transition-colors hover:bg-slate-100"
                                    :class="isExactActive ? 'bg-white shadow-sm' : ''"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            :icon="['fas', 'users']"
                                            class="mr-3 h-4 w-4"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />
                                        <span class="text-sm font-medium" :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'">
                                            Users
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Permissions link (staff only) -->
                        <router-link v-if="isSfStaff" v-slot="{ href, navigate, isExactActive }" :to="{ name: 'organization-permissions' }" custom>
                            <li>
                                <a
                                    class="block w-full rounded px-3 py-2.5 transition-colors hover:bg-slate-100"
                                    :class="isExactActive ? 'bg-white shadow-sm' : ''"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            :icon="['fas', 'unlock']"
                                            class="mr-3 h-4 w-4"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />
                                        <span class="text-sm font-medium" :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'">
                                            Permissions
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Usage link (admin only) -->
                        <router-link
                            v-if="
                                (
                                    isSfStaff ||
                                    ['admin', 'superadmin'].includes(
                                        selfActiveOrganization?.organization_operator?.role,
                                    )
                                )
                                && !selfActiveOrganization.is_legacy_billing === true
                            "
                            v-slot="{ href, navigate, isExactActive }"
                            :to="{ name: 'organization-usage' }"
                            custom
                        >
                            <li>
                                <a
                                    class="block w-full rounded px-3 py-2.5 transition-colors hover:bg-slate-100"
                                    :class="isExactActive ? 'bg-white shadow-sm' : ''"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            :icon="['far', 'gauge']"
                                            class="mr-3 h-4 w-4"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />
                                        <span class="text-sm font-medium" :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'">
                                            Usage
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Subscriptions link (admin only) -->
                        <router-link
                            v-if="
                                (
                                    isSfStaff ||
                                    ['admin', 'superadmin'].includes(
                                        selfActiveOrganization?.organization_operator?.role,
                                    )
                                )
                                && !selfActiveOrganization.is_legacy_billing === true
                            "
                            v-slot="{ href, navigate, isExactActive }"
                            :to="{ name: 'organization-subscriptions' }"
                            custom
                        >
                            <li>
                                <a
                                    class="block w-full rounded px-3 py-2.5 transition-colors hover:bg-slate-100"
                                    :class="isExactActive ? 'bg-white shadow-sm' : ''"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            :icon="['fas', 'cart-shopping']"
                                            class="mr-3 h-4 w-4"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />
                                        <span class="text-sm font-medium" :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'">
                                            Subscriptions
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </router-link>

                        <!-- Settings link -->
                        <router-link v-slot="{ href, navigate, isExactActive }" :to="{ name: 'organization-settings' }" custom>
                            <li>
                                <a
                                    class="block w-full rounded px-3 py-2.5 transition-colors hover:bg-slate-100"
                                    :class="isExactActive ? 'bg-white shadow-sm' : ''"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <div class="flex items-center">
                                        <font-awesome-icon
                                            :icon="['fas', 'gear']"
                                            class="mr-3 h-4 w-4"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />
                                        <span class="text-sm font-medium" :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'">
                                            Settings
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'OrganizationSidebar',

    props: {
        organization: {
            type: Object,
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'selfActiveOrganization']),
    },
}
</script>

<style scoped>
/* Hide scrollbar but keep functionality */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}
</style>