import api from './api'

const apiOnboard = {
    async sendEmailVerification(axios, email) {
        const body = {
            email,
        }

        return axios
            .post(`/onboarding/email/verification`, body)
            .then(({ data }) => api.createSuccessResponse(data))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async validateEmail(axios, email) {
        const body = {
            email,
        }

        return axios
            .post(`/onboarding/email/validate`, body)
            .then(({ data }) => api.createSuccessResponse(data))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async validateOrganization(axios, organizationName) {
        const body = {
            name: organizationName,
        }

        return axios
            .post(`/onboarding/organization/validate`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async onboardOrganization(axios, formData) {
        return axios
            .post(`/onboarding`, formData)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apiOnboard
