<template>
    <div class="logo-container" :style="{ width, height }">
        <template v-if="hasText">
            <svg
                v-if="variant === 'light'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 4021 663"
                fill="none"
                :class="[colorSet, variant]"
                preserveAspectRatio="xMinYMin meet"
            >
                <!-- SVG content for dark variant stays the same -->
                <g clip-path="url(#clip0_267_6)">
                    <path
                        d="M968.855 312.444C964.885 293.348 947.814 277.667 919.19 277.667C893.662 277.667 873.574 289.259 873.772 307.045C873.574 321.377 883.816 330.865 911.448 336.82L965.203 347.659C1022.81 359.569 1050.84 385.136 1051.04 428.052C1051.04 486.65 995.732 525.875 918.793 525.875C841.853 525.875 791.751 490.898 784.049 434.047L865.078 429.799C870.08 453.977 889.613 466.522 918.991 466.522C948.37 466.522 966.949 453.977 967.307 436.389C966.949 421.502 955.158 412.013 929.234 406.813L877.822 396.57C819.819 384.977 791.79 356.155 791.989 311.69C791.79 254.481 841.853 219.307 917.84 219.307C993.827 219.307 1036.94 253.132 1044.25 307.839L968.855 312.444Z"
                        fill="white"
                    />
                    <path
                        d="M1261.09 285.13H1205.19V428.846C1205.19 451.634 1216.62 457.827 1232.98 457.827C1239.89 457.549 1246.76 456.636 1253.51 455.128L1266.45 516.426C1252 521.11 1236.95 523.691 1221.79 524.127C1162.23 526.668 1122.53 497.846 1122.81 439.684V285.13H1082.28V223.157H1122.85V152.014H1205.19V223.157H1261.09V285.13Z"
                        fill="white"
                    />
                    <path
                        d="M1314.69 223.157H1394.36V274.768H1397.46C1408.3 238.045 1435.53 218.711 1468.92 218.711C1477.66 218.751 1486.35 219.743 1494.85 221.609V294.658C1483.49 291.72 1471.78 290.251 1460.03 290.251C1423.7 290.251 1397.02 315.342 1397.02 351.906V519.88H1314.69V223.157Z"
                        fill="white"
                    />
                    <path
                        d="M1511.4 372.988C1511.4 281.359 1568.02 219.307 1655.4 219.307C1733.89 219.307 1794.98 269.171 1794.98 370.169V392.798H1592.87C1592.87 437.064 1618.95 464.259 1660.12 464.259C1687.59 464.259 1708.83 452.349 1717.13 429.839L1793.32 434.841C1781.72 489.946 1732.22 525.716 1658.77 525.716C1567.26 525.875 1511.4 467.118 1511.4 372.988ZM1717.45 341.664C1717.25 306.291 1692.52 280.763 1656.75 280.763C1622.37 280.088 1593.86 307.283 1592.87 341.664H1717.45Z"
                        fill="white"
                    />
                    <path
                        d="M1835.04 437.142C1835.04 370.842 1887.81 351.111 1946.6 345.831C1998.21 340.789 2018.5 338.287 2018.5 319.152V318C2018.5 293.624 2002.62 279.531 1974.43 279.531C1944.85 279.531 1927.27 294.021 1921.63 313.752L1845.48 307.559C1856.88 253.447 1903.69 219.424 1974.83 219.424C2040.93 219.424 2100.84 249.199 2100.84 319.906V520.196H2022.63V478.907H2020.33C2005.83 506.697 1977.41 525.714 1933.5 525.714C1876.89 525.714 1835.04 495.899 1835.04 437.142ZM2019.09 413.957V382.434C2009.21 389.025 1979.83 393.273 1961.85 395.774C1932.87 399.744 1913.73 411.257 1913.73 434.442C1913.73 457.627 1931.87 468.863 1957.4 468.863C1993.25 468.863 2018.98 445.082 2018.98 413.957H2019.09Z"
                        fill="white"
                    />
                    <path
                        d="M2164.83 223.158H2243.32V275.523H2246.82C2259.16 240.546 2289.53 219.307 2329.55 219.307C2369.57 219.307 2400.66 241.142 2410.15 275.523H2413.24C2425.43 241.499 2459.25 219.307 2502.73 219.307C2558.03 219.307 2596.3 256.03 2596.3 320.384V520.078H2514.16V336.741C2514.16 303.71 2494.31 287.274 2468.15 287.274C2438.17 287.274 2420.51 308.157 2420.51 338.885V520.197H2340.75V334.915C2340.75 305.695 2322.57 287.274 2295.53 287.274C2268.49 287.274 2247.17 309.109 2247.17 341.981V520.197H2164.83V223.158Z"
                        fill="white"
                    />
                    <path
                        d="M2821.13 285.131H2762.17V520.198H2679.83V285.131H2637.87V223.158H2679.83V201.76C2679.83 137.167 2721.4 105.645 2777.26 105.645C2796.91 105.645 2816.48 108.304 2835.42 113.585L2820.93 175.438C2812.35 172.699 2803.42 171.27 2794.45 171.19C2770.31 171.19 2762.17 183.1 2762.17 202.951V222.801H2821.13V285.131Z"
                        fill="white"
                    />
                    <path
                        d="M2849.03 372.788C2849.03 280.762 2905.09 219.306 2995.17 219.306C3085.25 219.306 3141.19 280.762 3141.19 372.788C3141.19 464.814 3085.13 525.874 2995.01 525.874C2904.89 525.874 2849.03 464.219 2849.03 372.788ZM3057.62 372.193C3057.62 320.383 3036.38 282.112 2995.57 282.112C2954.76 282.112 2932.76 320.383 2932.76 372.193C2932.76 424.002 2954 462.075 2995.57 462.075C3037.14 462.075 3057.82 424.081 3057.82 372.193H3057.62Z"
                        fill="white"
                    />
                    <path
                        d="M3195.19 223.156H3275.06V274.767H3278.12C3288.96 238.044 3316.23 218.71 3349.58 218.71C3358.28 218.75 3366.97 219.742 3375.47 221.608V294.657C3364.11 291.719 3352.44 290.25 3340.69 290.25C3304.36 290.25 3277.68 315.341 3277.68 351.905V519.879H3195.19V223.156Z"
                        fill="white"
                    />
                    <path
                        d="M3401.95 555.094L3478.13 544.812C3484.88 560.692 3503.26 576.572 3539.59 576.572C3575.91 576.572 3601.44 560.692 3601.44 521.865V467.515H3597.99C3587.15 492.05 3560.67 515.83 3513.31 515.83C3446.41 515.83 3392.85 469.817 3392.85 371.042C3392.85 272.267 3447.96 219.307 3513.11 219.307C3562.77 219.307 3586.95 248.884 3597.99 273.022H3601.04V223.158H3682.83V522.977C3682.83 597.018 3622.52 635.686 3537.64 635.686C3457.61 635.686 3411.79 599.718 3401.95 555.094ZM3601.72 370.645C3601.72 318.638 3579.29 284.813 3539.27 284.813C3499.25 284.813 3476.82 320.186 3476.82 370.645C3476.82 421.105 3498.86 454.017 3539.27 454.017C3579.01 453.977 3601.72 423.05 3601.72 370.645Z"
                        fill="white"
                    />
                    <path
                        d="M3736.7 372.987C3736.7 281.358 3793.35 219.306 3880.73 219.306C3959.22 219.306 4020.28 269.17 4020.28 370.168V392.797H3818.09C3818.09 437.063 3844.21 464.258 3885.58 464.258C3913.01 464.258 3934.25 452.348 3942.59 429.838L4018.73 434.84C4007.14 489.945 3957.67 525.715 3884.19 525.715C3792.56 525.874 3736.7 467.117 3736.7 372.987ZM3942.79 341.663C3942.59 306.29 3917.85 280.762 3882.08 280.762C3847.66 280.048 3819.08 307.242 3818.09 341.663H3942.79Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M337.455 11.9102C157.655 11.9102 11.9102 157.655 11.9102 337.455V663H337.455C517.242 663 663 517.255 663 337.455C663 157.655 517.242 11.9102 337.455 11.9102Z"
                    fill="#FF4D00"
                />
                <path
                    d="M337.456 122.979C218.999 122.979 122.979 219 122.979 337.456V551.933H337.456C455.904 551.933 551.932 455.912 551.932 337.456C551.932 219 455.904 122.979 337.456 122.979Z"
                    fill="#FF993B"
                />
                <path
                    d="M337.455 250.113C289.216 250.113 250.114 289.216 250.114 337.455V424.796H337.455C385.691 424.796 424.796 385.694 424.796 337.455C424.796 289.216 385.691 250.113 337.455 250.113Z"
                    fill="white"
                />
                <defs>
                    <clipPath id="clip0_267_6">
                        <rect width="4020.28" height="651.09" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <svg
                v-if="variant === 'dark'"
                viewBox="0 0 3975 656"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMinYMin meet"
            >
                <g clip-path="url(#clip0_267_22)">
                    <path
                        d="M957.895 308.91C953.97 290.03 937.092 274.526 908.792 274.526C883.553 274.526 863.692 285.987 863.888 303.572C863.692 317.742 873.819 327.123 901.138 333.011L954.284 343.726C1011.24 355.502 1038.95 380.78 1039.15 423.211C1039.15 481.146 984.469 519.926 908.399 519.926C832.33 519.926 782.794 485.346 775.18 429.138L855.292 424.938C860.238 448.842 879.549 461.245 908.595 461.245C937.642 461.245 956.011 448.842 956.365 431.453C956.011 416.734 944.354 407.353 918.722 402.211L867.892 392.084C810.545 380.623 782.834 352.126 783.03 308.164C782.834 251.603 832.33 216.826 907.457 216.826C982.585 216.826 1025.21 250.268 1032.43 304.357L957.895 308.91Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M1246.82 281.904H1191.56V423.995C1191.56 446.525 1202.86 452.648 1219.03 452.648C1225.86 452.373 1232.65 451.471 1239.33 449.979L1252.12 510.583C1237.84 515.215 1222.96 517.766 1207.96 518.198C1149.09 520.71 1109.84 492.214 1110.11 434.71V281.904H1070.04V220.633H1110.15V150.294H1191.56V220.633H1246.82V281.904Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M1299.81 220.632H1378.59V271.659H1381.65C1392.37 235.352 1419.3 216.236 1452.31 216.236C1460.94 216.276 1469.54 217.257 1477.94 219.102V291.324C1466.71 288.42 1455.13 286.968 1443.51 286.968C1407.6 286.968 1381.22 311.774 1381.22 347.925V513.998H1299.81V220.632Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M1494.31 368.769C1494.31 278.176 1550.28 216.826 1636.67 216.826C1714.27 216.826 1774.68 266.126 1774.68 365.982V388.355H1574.85C1574.85 432.121 1600.64 459.008 1641.34 459.008C1668.5 459.008 1689.5 447.232 1697.71 424.977L1773.03 429.923C1761.57 484.404 1712.62 519.769 1640.01 519.769C1549.53 519.926 1494.31 461.834 1494.31 368.769ZM1698.02 337.799C1697.83 302.826 1673.37 277.587 1638.01 277.587C1604.01 276.92 1575.83 303.807 1574.85 337.799H1698.02Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M1814.28 432.197C1814.28 366.647 1866.45 347.139 1924.58 341.918C1975.61 336.933 1995.67 334.46 1995.67 315.541V314.403C1995.67 290.302 1979.96 276.368 1952.1 276.368C1922.85 276.368 1905.47 290.695 1899.89 310.203L1824.61 304.08C1835.87 250.58 1882.15 216.941 1952.49 216.941C2017.84 216.941 2077.07 246.38 2077.07 316.287V514.311H1999.75V473.489H1997.47C1983.14 500.965 1955.04 519.767 1911.63 519.767C1855.66 519.767 1814.28 490.289 1814.28 432.197ZM1996.25 409.274V378.108C1986.48 384.624 1957.43 388.824 1939.65 391.297C1911 395.222 1892.08 406.605 1892.08 429.528C1892.08 452.45 1910.02 463.559 1935.26 463.559C1970.7 463.559 1996.14 440.047 1996.14 409.274H1996.25Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M2140.35 220.634H2217.95V272.406H2221.4C2233.61 237.826 2263.63 216.826 2303.2 216.826C2342.77 216.826 2373.5 238.414 2382.88 272.406H2385.94C2397.99 238.768 2431.43 216.826 2474.42 216.826C2529.09 216.826 2566.93 253.134 2566.93 316.76V514.195H2485.72V332.932C2485.72 300.275 2466.09 284.025 2440.23 284.025C2410.59 284.025 2393.13 304.671 2393.13 335.052V514.313H2314.27V331.127C2314.27 302.237 2296.29 284.025 2269.56 284.025C2242.83 284.025 2221.75 305.613 2221.75 338.113V514.313H2140.35V220.634Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M2789.21 281.906H2730.92V514.314H2649.52V281.906H2608.03V220.634H2649.52V199.478C2649.52 135.615 2690.61 104.45 2745.84 104.45C2765.27 104.45 2784.62 107.08 2803.34 112.3L2789.02 173.454C2780.54 170.745 2771.71 169.332 2762.84 169.254C2738.97 169.254 2730.92 181.029 2730.92 200.655V220.281H2789.21V281.906Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M2816.81 368.571C2816.81 277.587 2872.23 216.825 2961.29 216.825C3050.35 216.825 3105.66 277.587 3105.66 368.571C3105.66 459.556 3050.23 519.925 2961.13 519.925C2872.03 519.925 2816.81 458.968 2816.81 368.571ZM3023.03 367.983C3023.03 316.759 3002.03 278.921 2961.68 278.921C2921.33 278.921 2899.59 316.759 2899.59 367.983C2899.59 419.206 2920.59 456.848 2961.68 456.848C3002.78 456.848 3023.23 419.284 3023.23 367.983H3023.03Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M3159.04 220.632H3238.02V271.659H3241.04C3251.75 235.351 3278.72 216.236 3311.69 216.236C3320.29 216.275 3328.88 217.256 3337.28 219.101V291.324C3326.06 288.419 3314.52 286.967 3302.9 286.967C3266.98 286.967 3240.61 311.774 3240.61 347.925V513.998H3159.04V220.632Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M3363.46 548.815L3438.79 538.649C3445.46 554.35 3463.63 570.05 3499.55 570.05C3535.46 570.05 3560.7 554.35 3560.7 515.962V462.227H3557.29C3546.57 486.484 3520.39 509.996 3473.56 509.996C3407.42 509.996 3354.47 464.503 3354.47 366.845C3354.47 269.188 3408.96 216.826 3473.37 216.826C3522.47 216.826 3546.38 246.069 3557.29 269.933H3560.31V220.634H3641.17V517.061C3641.17 590.265 3581.54 628.496 3497.62 628.496C3418.49 628.496 3373.2 592.934 3363.46 548.815ZM3560.98 366.453C3560.98 315.033 3538.8 281.591 3499.23 281.591C3459.67 281.591 3437.49 316.564 3437.49 366.453C3437.49 416.342 3459.28 448.881 3499.23 448.881C3538.52 448.842 3560.98 418.265 3560.98 366.453Z"
                        fill="#1e293b"
                    />
                    <path
                        d="M3694.43 368.768C3694.43 278.175 3750.44 216.825 3836.83 216.825C3914.43 216.825 3974.8 266.125 3974.8 365.981V388.354H3774.9C3774.9 432.12 3800.72 459.007 3841.62 459.007C3868.75 459.007 3889.75 447.231 3897.99 424.976L3973.27 429.922C3961.81 484.403 3912.9 519.768 3840.25 519.768C3749.66 519.925 3694.43 461.833 3694.43 368.768ZM3898.18 337.798C3897.99 302.825 3873.53 277.587 3838.17 277.587C3804.14 276.88 3775.88 303.767 3774.9 337.798H3898.18Z"
                        fill="#1e293b"
                    />
                </g>
                <path
                    d="M333.638 11.7754C155.872 11.7754 11.7754 155.872 11.7754 333.638V655.5H333.638C511.391 655.5 655.5 511.403 655.5 333.638C655.5 155.872 511.391 11.7754 333.638 11.7754Z"
                    fill="#FF4D00"
                />
                <path
                    d="M333.638 121.588C216.522 121.588 121.588 216.522 121.588 333.638V545.689H333.638C450.746 545.689 545.689 450.755 545.689 333.638C545.689 216.522 450.746 121.588 333.638 121.588Z"
                    fill="#FF993B"
                />
                <path
                    d="M333.637 247.284C285.944 247.284 247.284 285.944 247.284 333.637V419.991H333.637C381.327 419.991 419.991 381.331 419.991 333.637C419.991 285.944 381.327 247.284 333.637 247.284Z"
                    fill="white"
                />
                <defs>
                    <clipPath id="clip0_267_22">
                        <rect width="3974.8" height="643.725" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </template>

        <template v-else>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 658 658"
                fill="none"
                :class="[colorSet, variant]"
                preserveAspectRatio="xMinYMin meet"
            >
                <path
                    d="M329 0C147.292 0 0 147.292 0 329V658H329C510.695 658 658 510.708 658 329C658 147.292 510.695 0 329 0Z"
                    fill="#FF4D00"
                />
                <path
                    d="M329 112.248C209.287 112.248 112.247 209.287 112.247 329V545.753H329C448.705 545.753 545.753 448.714 545.753 329C545.753 209.287 448.705 112.248 329 112.248Z"
                    fill="#FF993B"
                />
                <path
                    d="M329 240.732C280.249 240.732 240.732 280.249 240.732 329V417.269H329C377.748 417.269 417.268 377.751 417.268 329C417.268 280.249 377.748 240.732 329 240.732Z"
                    fill="white"
                />
            </svg>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ResponsiveLogo',
    props: {
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: 'auto',
        },
        hasText: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'dark',
        },
        colorSet: {
            type: String,
            default: 'brand',
        },
    },
}
</script>

<style lang="scss">
.logo-container {
    display: inline-block; // Added to properly contain the SVG
    max-width: 100%; // Ensures it doesn't overflow its parent
    overflow: hidden; // Prevents any clipping outside container

    svg {
        width: 100%;
        height: 100%;
        display: block; // Removes any extra space below SVG
    }
}
</style>
