<template>
    <div>
        <div class="flex-col divide-y rounded-sm border border-slate-100 border-slate-200 bg-white px-6 py-6">
            <div class="flex justify-between pb-12 pt-6">
                <div>
                    <h2 class="text-1xl font-bold text-slate-800 md:text-2xl">Settings Management</h2>
                    <div class="text-sm">
                        <p>Manage settings for your organization.</p>
                    </div>
                </div>
            </div>

            <div class="flex flex-col divide-y">
                <div class="grid gap-4 py-8 md:grid-cols-12">
                    <div class="col-span-12 md:col-span-4 md:pr-10">
                        <h2 class="font-semibold text-slate-800">Email Settings</h2>
                        <div class="space-y-2 text-sm">
                            <p>These are default email settings for your organization</p>
                        </div>
                    </div>

                    <div class="col-span-12 md:col-span-5">
                        <div class="grid gap-4 md:grid-cols-12">
                            <div class="col-span-12 md:col-span-12">
                                <label class="mb-2 block text-sm font-medium">Default sender name</label>
                                <input
                                    v-model="editedSettings.sender_name"
                                    class="form-input w-full"
                                    type="text"
                                    maxlength="200"
                                    @input="onInputChange('organization-settings')"
                                />
                                <div class="mt-1 text-xs">
                                    This will auto populate the sender name field when sending outreach.
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-12">
                                <label class="mb-2 block text-sm font-medium">Default sender email address</label>
                                <Tooltip placement="bottom" max-width="400" class="cursor-pointer">
                                    <template #trigger>
                                        <div
                                            class="form-input mt-1 bg-slate-100 hover:cursor-default hover:border-slate-200"
                                        >
                                            campaigns@streamforge.gg
                                        </div>
                                    </template>
                                    <template #default>
                                        <p class="text-sm">Customizing this is currently not available.</p>
                                    </template>
                                </Tooltip>
                                <div class="mt-1 text-xs"></div>
                            </div>

                            <div class="col-span-12 md:col-span-12">
                                <label class="mb-2 block text-sm font-medium">Default reply-to name</label>
                                <input
                                    v-model="editedSettings.reply_name"
                                    class="form-input w-full"
                                    type="text"
                                    maxlength="200"
                                    @input="onInputChange('organization-settings')"
                                />
                                <div class="mt-1 text-xs">
                                    This will auto populate the reply-to name field when sending outreach. Often you
                                    will just set this to the sender name.
                                </div>
                            </div>

                            <div class="col-span-12 md:col-span-12">
                                <label class="mb-2 block text-sm font-medium">Default reply-to email address</label>
                                <input
                                    v-model="editedSettings.reply_address"
                                    class="form-input w-full"
                                    type="text"
                                    maxlength="200"
                                    @input="onInputChange('organization-settings')"
                                />
                                <div class="mt-1 text-xs">
                                    This will auto populate the reply-to email address field when sending outreach. This
                                    can be your own individual email, or a group email setup by your company.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pt-4">
                <div class="flex justify-end space-x-2 px-4">
                    <button
                        :class="
                            !state.settings.settingsChanged
                                ? 'btn cursor-not-allowed border-slate-200 bg-white text-slate-700 opacity-50 '
                                : 'btn border-slate-200 bg-white text-slate-700 hover:border-slate-300'
                        "
                        :disabled="!state.settings.settingsChanged"
                        @click="cancelChanges()"
                    >
                        Cancel
                    </button>
                    <button
                        class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="saveOrganizationSettings()"
                    >
                        Save Settings
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Tooltip from '@/components/Tooltip.vue'
import organizationAPI from '@/utils/api/api.organization'
import verificationAPI from '@/utils/api/api.verification'

export default {
    name: 'OrganizationSettingsSubpage',

    components: {
        Tooltip,
    },

    props: {
        organization: {
            type: Object,
        },
    },

    data() {
        return {
            settings: {},
            editedSettings: {},

            state: {
                settings: {
                    isLoaded: false,
                    settingsChanged: false,
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'selfActiveOrganizationId']),
    },

    watch: {
        isSfStaff(value) {
            if (value === false) {
                this.$router.push({ name: 'landing' })
            }
        },
    },

    created() {
        this.state.settings.isLoaded = false
        this.getOrganizationSettings()
    },

    methods: {
        async getOrganizationSettings() {
            const result = await organizationAPI.getOrganizationSettings(this.$axios, this.selfActiveOrganizationId)
            if (result.success && result.value.settings) {
                this.editedSettings = result.value.settings
                this.settingsUnedited = _.cloneDeep(this.editedSettings)
            } else {
                this.editedSettings = {}
                this.settingsUnedited = {}
            }
        },
        onInputChange() {
            this.state.settings.settingsChanged = true
        },
        cancelChanges() {
            this.editedSettings = _.cloneDeep(this.settingsUnedited)
            this.state.settings.settingsChanged = false
        },
        async validateChanges() {
            const newReplyToEmail = _.get(this.editedSettings, `reply_address`)
            const oldReplyToEmail = _.get(this.settingsUnedited, `reply_address`)

            if (newReplyToEmail && (!oldReplyToEmail || oldReplyToEmail !== newReplyToEmail)) {
                const result = await verificationAPI.validateEmail(this.$axios, newReplyToEmail)
                const isValid = result.success && result.value.is_success
                return {
                    isValid,
                    reason: isValid ? `` : `Please enter a valid reply email address`,
                }
            }

            return {
                isValid: true,
            }
        },
        async saveOrganizationSettings() {
            const valididated = await this.validateChanges()
            if (valididated.isValid) {
                const result = await organizationAPI.saveOrganizationSettings(
                    this.$axios,
                    this.selfActiveOrganizationId,
                    this.editedSettings,
                )
                if (result && result.success) {
                    this.state.settings.settingsChanged = false
                    this.$successHandler(`Successfully saved organization settings`)
                } else {
                    const message = `Unable to save settings, please try again later`
                    this.$errorHandler(message, { title: 'Error saving settings', message })
                }
            } else {
                this.$errorHandler(valididated.reason, { title: 'Error saving settings', message: valididated.reason })
            }
        },
    },
}
</script>
